@use "Variables" as *;
@use "sass:map";

$breakpoints: (
  mobile: 200px,
  tablet: 740px,
  desktop: 980px,
  wide: 1300px,
  full: 1920px,
);

$show-breakpoints: (mobile, tablet, desktop, wide, full);
$show-breakpoints: ();

@forward "./vendor/mq" with (
  $breakpoints: $breakpoints,
  $show-breakpoints: $show-breakpoints
);

@use "./vendor/mq";

@mixin font-extra-small($font-weight: var(--fw-normal)) {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: $font-weight;
}

@mixin font-small($font-weight: var(--fw-normal)) {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: $font-weight;
}

@mixin font-copy($font-weight: var(--fw-normal)) {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: $font-weight;
}

@mixin font-lead($font-weight: var(--fw-normal)) {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: $font-weight;
}

@mixin font-h3($font-weight: var(--fw-bold)) {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: $font-weight;
}

@mixin font-h2($font-weight: var(--fw-bold)) {
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: $font-weight;
}

@mixin font-h1($font-weight: var(--fw-bold)) {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: $font-weight;
}

@mixin font-event-title($font-weight: var(--fw-bold)) {
  font-size: var(--fs-5);
  line-height: var(--flh-5);
  letter-spacing: var(--fls-5);
  font-weight: $font-weight;
}

@mixin grid($pageType: "default") {
  display: grid;
  column-gap: var(--grid-col-gap);
  padding: var(--s-100) var(--grid-padding-inline);

  @if ($pageType == filterPage) {
    gap: var(--gap);
    grid-template-columns: var(--grid-filter-page);
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
  } @else if ($pageType == heroPage) {
    --grid-row-gap: 2rem;
    grid-template-columns: var(--grid-hero-page);
    padding-bottom: 6rem;

    & > * {
      grid-column: content;
    }
  } @else {
    grid-template-columns: var(--grid-default-page);
  }

  @include mq.mq(desktop) {
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
    padding-bottom: 6rem;
  }
}

@mixin pseudo(
  $pseudo: before,
  $content: "",
  $position: absolute,
  $top: 0,
  $right: 0,
  $bottom: 0,
  $left: 0
) {
  & {
    position: relative;
  }
  &::#{$pseudo} {
    content: #{$content};
    position: absolute;
    top: #{$top};
    right: #{$right};
    bottom: #{$bottom};
    left: #{$left};
    @content;
  }
}

@mixin button-reset {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}

@mixin button-box {
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $col-White;
    text-decoration: none;
    text-shadow: $effect-drop-shadow;
    border-radius: var(--border-radius);
    padding: var(--s-100) var(--s-200);
    background-color: var(--c-blurry-dark);
    backdrop-filter: $effect-button-bg-blur;
  }
  svg {
    --icon-size: 1.1rem;
    display: block;
  }
}

@mixin call-to-action {
  padding: 0.6rem 1.6rem;
  border-radius: 100vh;
  @include font-copy;
  @include mq.mq(desktop) {
    padding: 0.6rem 2rem;
  }
  a {
    text-decoration: none;
  }
}

@mixin hide-scrollbars {
  & {
    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@mixin tile-overlay($coverage: 20%, $z-index: 1) {
  &::before {
    content: "";
    z-index: $z-index;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0 0 0 / 0.5) 0%,
      rgba(0 0 0 / 0.3) $coverage * 0.2,
      rgba(0 0 0 / 0) $coverage
    );
  }
}

@mixin filtertag-staggered-entry($parent: nil) {
  --duration: 333ms;
  --separation: 66ms;
  --delay: var(--promoted-index);
  opacity: 0;
  transform: translateY(3em);
  animation-name: enter-filtertag;
  animation-duration: var(--duration);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.53, -0.46, 0.71, 1.63);
  animation-delay: calc(var(--delay) * var(--separation));
}

@mixin info-box-paddings($override-left: false) {
  padding: var(--s-200) var(--s-250);

  @media screen and (max-width: map.get($breakpoints, wide)) {
    padding: var(--s-150) var(--s-200);
  }

  @media screen and (max-width: map.get($breakpoints, tablet)) {
    padding: var(--s-100) 1.25rem;
  }
}

@mixin line-clamp($lines: 1, $revert: false) {
  @if not $revert {
    display: -webkit-box;
    line-clamp: $lines;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    display: initial;
    line-clamp: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
}

@mixin gradient-outline(
  $bg: var(--c-dark-black),
  $fgL: var(--c-light-lightgrey),
  $fgR: var(--c-light-lightgrey),
  $t: 2px
) {
  // https://codyhouse.co/nuggets/css-gradient-borders
  border-radius: 50em;
  border: $t solid transparent;
  // background: linear-gradient($bg, $bg) padding-box,
  //   linear-gradient(to right, $fgL 0%, $fgR 100%) border-box;
  // // TODO: review this
  background: linear-gradient($bg, $bg) padding-box,
    linear-gradient(
        45deg,
        $fgL 0%,
        $fgL 33%,
        rgba(151, 71, 255, 1) 88%,
        rgba(79, 54, 111, 1) 100%
      )
      border-box;
  &.capsule {
    padding: calc(var(--s-075) - #{$t}) calc(var(--s-150) - #{$t});
  }
}
