@use "../Variables" as *;
@use "../Mixins" as *;

.block {
  --block-margin-1: 1rem;
  --block-margin-2: 1.5rem;
  --block-margin-3: 2rem;
  margin-bottom: var(--block-margin-1);
  grid-column: content;

  .ArticlePage & {
    p {
      color: var(--c-secondary-text);
      strong {
        color: var(--c-text);
        font-weight: normal;
      }
    }
    h2 p,
    h3 p {
      color: var(--c-text);
    }
  }

  .DefaultPage & {
    p {
      color: var(--c-secondary-text);
      strong {
        color: var(--c-text);
        font-weight: normal;
      }
    }
    h2 p,
    h3 p {
      color: var(--c-text);
    }
  }

  &:first-child {
    margin-block-start: 0;
  }
  &:last-child {
    margin-block-end: 0;
  }

  &-type-text {
    @include font-copy();
    p:not(:last-child) {
      margin-bottom: var(--block-margin-1);
    }
  }

  &-type-list {
    color: var(--c-secondary-text);
    ul {
      padding-left: 1rem;
    }
    ol {
      padding-left: 1.3rem;
    }
    li {
      padding-left: 0.5em;
    }
  }

  &-type-gallery {
    margin-block: var(--block-margin-3);
    .splide__slide {
      aspect-ratio: 3/2;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .splide__arrow {
      height: 100%;
      width: 50%;
      svg {
        display: none;
      }
    }
    .splide__arrow--prev {
      left: 0;
    }
    .splide__arrow--next {
      right: 0;
    }
    @include mq($until: tablet) {
      .splide__arrow {
        display: none;
      }
    }
  }

  &.Lead {
    @include font-lead();
    grid-column: wide;
    margin-bottom: var(--block-margin-3);
  }
  &-type-video {
    border-radius: var(--border-radius-1);
    overflow: hidden;
    margin-block: var(--block-margin-3);
  }

  &-type-heading {
    text-align: left;
    width: 100%;

    h2 {
      margin-top: var(--block-margin-3);
      @include font-h3($font-weight-bold);
    }
    h3 {
      margin-top: var(--block-margin-2);
      font-weight: 600;
    }
  }

  &-type-image {
    grid-column: wide;
    margin-block: var(--block-margin-3);

    img {
      width: 100%;
    }

    &:first-child {
      margin-block-start: 0;
    }
  }

  &-type-cta {
    text-align: center;
    a {
      display: inline-block;
      text-decoration: none;
      margin: var(--block-margin-3) 0;
      color: var(--c-text-inverted);
      background-color: var(--c-background-inverted);
      transition: scale 555ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      @include call-to-action;
      &:hover {
        transition: scale 222ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        scale: 1.1;
      }
    }
  }

  &-type-links {
    margin-block: var(--block-margin-3);
    > p {
      margin-bottom: var(--block-margin-1);
    }
    ul {
      list-style-type: none;
    }
    li {
      padding-block: 0.25rem;
      border-top: 1px solid var(--c-light-30);

      &:hover a {
        background: var(--c-blurry-dark-55);
        transition: background-color 150ms ease-in-out;
      }
    }
    a {
      padding: 1rem;
      text-decoration: none;
      color: inherit;
      display: grid;
      grid-template-columns: min-content auto min-content;
      align-items: center;
      border-radius: var(--border-radius-1);
      transition: background-color 150ms ease-in-out;
      .block-link-title {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .block-link-info {
        @include font-extra-small();
        color: var(--c-secondary-text);
        margin-left: 1rem;
        white-space: nowrap;
        margin-top: 0.2rem;
      }
    }
    .link-type-url,
    .link-type-page {
      &::before {
        content: url("/assets/icons/control-link-url.png");
        margin-right: 1rem;
      }
    }
    .link-type-file {
      &::before {
        content: url("/assets/icons/control-link-document.png");
        margin-right: 1rem;
      }
    }
  }

  &-type-two-column {
    grid-column: wide;
    display: grid;
    grid-gap: 2rem;
    margin-block: var(--block-margin-3);

    @include mq($from: tablet) {
      grid-template-columns: 1fr 1fr;
    }

    img {
      width: 100%;
    }

    & .block__column {
      display: grid;
      grid-gap: 1rem;
      height: min-content;
    }
  }
  &-type-accordion {
    grid-column: wide;
    margin-top: var(--block-margin-3);

    + .block-type-accordion {
      margin-top: 0;
    }
    &:has(+ :not(.block-type-accordion)) {
      margin-bottom: var(--s-300);
    }
    .Accordion {
      background-color: var(--c-blurry-dark);
      padding: 2.5rem;
      border-radius: var(--border-radius-1);
      background-image: none;
      appearance: none;
      -webkit-appearance: none;

      @include mq($until: tablet) {
        padding: 1rem;
      }

      summary {
        background-image: none;
        appearance: none;
        -webkit-appearance: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        list-style: none;

        &::marker {
          content: "";
        }
        &::-webkit-details-marker {
          display: none;
        }
        h2 {
          display: flex;
          gap: var(--s-075);
          flex-direction: column;
          // line-height: 0.7;
          @include font-h3($font-weight-bold);

          .Accordion__Byline {
            @include font-small();

            color: var(--c-text-muted);
          }
        }
      }

      &__content {
        margin-top: var(--block-margin-3);
      }

      &__Icon {
        svg {
          // transform: rotate(90deg);
          transition: transform 300ms ease-in-out;
        }
      }

      &[open] {
        svg {
          transform: rotate(180deg);
          transition: transform 300ms ease-in-out;
        }
      }
    }
  }
}
