@use "../Mixins" as *;

.NewsletterPage {
  --border-radius: 1rem;
  #mc_embed_shell {
    grid-column: content;
    h2 {
      display: none;
    }
  }
  .indicates-required {
    @include font-small();
    text-align: left;
    color: var(--c-secondary-text);
  }
  & .mc-field-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem 0;
    label {
      opacity: 0;
      @include font-extra-small();
    }
    label:has(+ input:focus-visible) {
      opacity: 1;
    }
    input[type="email"],
    input[type="text"] {
      width: 100%;
      border: none;
      border: 2px solid transparent;
      font-size: 16px;
      @include button-box();
      &:focus-visible {
        outline: 2px solid var(--c-light-30);
        &::placeholder {
          opacity: 0;
        }
      }
      &.mce_inline_error {
        border: 2px solid var(--c-color-red);
      }
    }
    div.mce_inline_error {
      background: transparent !important;
      color: var(--c-color-red) !important;
      display: none !important;
    }
  }
  .mergeRow {
    margin-top: 2rem;
    p {
      color: var(--c-text-muted) !important;
    }
    label {
      opacity: 1;
      margin-bottom: 1rem;
      @include font-copy();
    }
    .mc_fieldset {
      border: none;
    }
    label.checkbox {
      display: flex;
      align-items: start;
    }
    input[type="checkbox"] {
      margin-right: 1rem;
      padding-top: 0.75rem;
      width: 1.35rem;
      height: 1.35rem;
      border: none;
      border-radius: 0.25rem;
      font-size: 16px;
      &.mce_inline_error {
        outline: 2px solid var(--c-color-red);
      }
    }
  }

  input[type="text"],
  select {
    width: 100%;
    font-size: 16px;
  }

  input[type="submit"] {
    width: 100%;
    background: var(--c-color-lime) !important;
    color: var(--c-text-inverted) !important;
    border: none;
    margin-top: 2rem;
    width: max-content;
    font-size: 16px;
    @include call-to-action;
  }

  input[type="submit"]:hover {
    filter: brightness(0.9);
  }
  #mce-responses {
    margin-top: 2rem;
  }
}
