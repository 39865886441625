@use "Variables" as *;
@use "Mixins" as *;

html {
  hanging-punctuation: first last; /* Not supported in Chrome */
  font-family: "kiffKunath", "Kunath", Avenir, Corbel, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  @include font-copy();
}

h1,
.H1 {
  @include font-h1($font-weight-bold);
}

h2,
.H2 {
  @include font-h2($font-weight-bold);
}

strong {
  font-weight: $font-weight-bold;
}

small {
  @include font-small();
}

p small {
  text-transform: uppercase;
  @include font-small();
}

.extra-small {
  @include font-extra-small();
}

.ops * {
  background-color: rgb(179, 54, 54);
}

.page__title {
  text-align: center;
  margin: 1rem auto 2rem;

  @include font-h1($font-weight-bold);
  @include mq(desktop) {
    margin: 2rem auto 4rem;
  }
}
.FigCaption {
  @include font-small();
  opacity: 0.5;
  margin-top: 0.25rem;
}
