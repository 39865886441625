@use "../_Variables.scss" as *;
@use "../Mixins.scss" as *;

.Notification {
  color: $col-White;
  position: fixed;
  top: 0;
  left: 0;
  width: 21em;
  z-index: 1000;
  background-color: var(--c-light-20);
  display: grid;
  grid-template-areas: "image text time";
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.9rem;
  margin: var(--s-150) var(--s-150);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  backdrop-filter: blur(100px);

  &:not(.closed) {
    transform: translateY(-200%);
    animation: fadeIn 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 1s forwards;
  }

  @include mq($until: tablet) {
    margin: var(--s-150) var(--s-100);
  }

  &.closed {
    transform: translateY(-200%);
    transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  @keyframes fadeIn {
    from {
      transform: translateY(-200%);
    }
    to {
      transform: translateY(0);
    }
  }

  &__Link {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }

  &__Image {
    $size: 2.2rem;
    grid-area: image;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    width: $size;
    border-radius: 0.5rem;
    background-color: var(--c-light-10);
    font-size: 1.375rem;
  }
  &__Text {
    grid-area: text;
    font-size: 0.75rem;
    color: var(--c-secondary-text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    h3 {
      font-weight: bold;
      letter-spacing: 0.03em;
      color: var(--c-text);
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
  &__Time {
    grid-area: time;
    font-size: 0.625rem;
    margin-top: 0.2rem;
    color: var(--c-secondary-text);
  }

  &__CloseButton {
    @include button-reset;
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(-60%, 60%);
    border-radius: 50%;
    border: 1px solid var(--c-light-10);
    height: 1.1rem;
    width: 1.1rem;
    background-color: rgb(70, 70, 70);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--c-text);

    background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' height='200px' width='200px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512' xml:space='preserve' stroke='%23ffffff'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg%3E%3Cg%3E%3Cpolygon points='512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 '%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
