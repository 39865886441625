@use "Variables" as *;
@use "Mixins" as *;
@use "abstracts" as *;

:root {
  @each $key, $value in $font-sizes {
    --fs-#{$key}: #{$value};
  }

  @each $key, $value in $font-weights {
    --fw-#{$key}: #{$value};
  }

  @each $key, $value in $font-line-heights {
    --flh-#{$key}: #{$value};
  }

  @each $key, $value in $font-letter-spacing {
    --fls-#{$key}: #{$value};
  }

  @each $color-key, $color-value in $colors {
    --c-#{$color-key}: #{$color-value};
  }

  @each $k, $v in $spacing {
    --s-#{$k}: #{$v};
  }

  --effect-drop-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
  --effect-button-bg-blur: blur(20px);
  --effect-bg-blur-2: blur(40px);
  --effect-event-bg-blur: blur(120px);

  --spacing-padding-small: var(--s-050);
  --spacing-padding-medium: var(--s-100);
  --spacing-padding-large: var(--s-150);
  --spacing-padding-extra-large: var(--s-300);

  --header-height: 7rem;
  --icon-color: #{$col-Black};
  --icon-size: 2rem;
  --page-padding-bottom: #{$page-padding-bottom};

  --gap: 1.5rem;
  --grid-row-gap: 1.5rem;
  --grid-col-gap: 1.5rem;
  --grid-padding-inline: 1rem;
  --grid-padding-block: 1.5rem;
  --grid-default-page: [full-start wide-start content-start] 1fr [content-end
    wide-end full-end];
  --grid-filter-page: 1fr;
  --grid-hero-page: var(--grid-default-page);
  --border-radius-1: 10px;
  --border-radius-2: 20px;

  @include mq($from: tablet) {
    --grid-padding-inline: 1.5rem;
    --grid-padding-block: 2.5rem;
    --grid-filter-page: 1fr 1fr;
    --grid-default-page: [full-start wide-start] 1fr [content-start] 6fr
      [content-end] 1fr [ wide-end full-end];
    --spacing-padding-small: 0.5rem;
    --spacing-padding-medium: 1.5rem;
    --spacing-padding-large: 2.5rem;
    --spacing-padding-extra-large: 6rem;
  }

  @include mq($from: desktop) {
    --grid-row-gap: 1.5rem;
    --grid-col-gap: 1.5rem;
    --grid-filter-page: 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 10fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }
  @include mq($from: wide) {
    --grid-filter-page: 1fr 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 8fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }

  @include mq($from: full) {
    --grid-filter-page: 1fr 1fr 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 4fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }

  & {
    view-transition-name: none;
  }
  &::view-transition-old(filter-toggle),
  &::view-transition-new(filter-toggle) {
    animation-duration: 666ms;
  }
}
