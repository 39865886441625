@use "../Variables" as *;
@use "../Mixins" as *;

.DashboardPage {
  --icon-color: #{$col-White};
  position: relative;

  .splide__pagination__page {
    border-radius: 50%;
  }

  .Icon-type-cta-plus {
    --icon-size: 6rem;
    width: unset;
    height: unset;

    @include mq($from: tablet, $until: wide) {
      --icon-size: 4rem;
    }
  }

  .Card {
    &__Byline {
      @include font-small;
    }
  }

  .Card-Impression {
    .Card__Title {
      margin-bottom: var(--s-050);
    }
  }
}

@keyframes dashboard-fade-in {
  0% {
    opacity: 0;
    filter: blur(100px);
    // transform: scale(0.99);
  }
  100% {
    opacity: 1;
    filter: blur(0);

    // transform: scale(1);
  }
}

.Dashboard {
  --gap-top: var(--header-height);

  --gap: 1rem;

  --border-radius: 1rem;
  --teaser-ratio: 6 / 7;
  --grid-column-height: calc((100vw - var(--gap)) / 5.5);
  --fixed-row-count: 21;
  --teaser-content-gap: var(--spacing-padding-small);

  @include mq($from: desktop) {
    --gap: 1.5rem;
  }

  & {
    // display: grid;
    gap: var(--gap);

    padding: var(--grid-padding-inline);
    padding-top: 0;
    display: grid;
    grid-auto-columns: 1fr;
    gap: var(--gap);
    grid-auto-flow: row;
    // grid-auto-rows: var(--grid-column-height);
    grid-template-rows:
      repeat(var(--fixed-row-count), var(--grid-column-height))
      /* Fixed height rows*/
      auto auto auto; /* Last three rows are flexible and adjust their height based on content*/
    grid-template-areas:
      "T1 T1"
      "T1 T1"
      "T1 T1"
      "T1 T1"
      "T1 T1"
      "F F"
      "W1 E"
      "W1 E"
      "W1 E"
      "K T2"
      "K T2"
      "K T2"
      "I I"
      "I I"
      "I I"
      "W2 A"
      "W2 A"
      "W2 A"
      "W3 W3"
      "W3 W3"
      "W3 W3"
      "N N"
      "C C"
      "B B";
  }

  @include mq($from: tablet) {
    --grid-column-height: calc((100vw - 60px) / 3);
    --fixed-row-count: 10;
    grid-template-areas:
      "T1 T1 T1 T1"
      "T1 T1 T1 T1"
      "W1 W1 E E"
      "W1 W1 F F"
      "K K W3 W3"
      "A A W3 W3"
      "T2 T2 W2 W2"
      "T2 T2 W2 W2"
      "I I I I"
      "I I I I"
      // "I I I I"
      // "I I I I"
      // "I I I I"
      "N N N N"
      "C C C C"
      ". B B .";
  }

  @include mq($from: desktop) {
    --grid-column-height: calc((100vw - 60px) / 6);
    --fixed-row-count: 7;
    grid-template-areas:
      "W1 T1 T1 E"
      "W1 T1 T1 F"
      "W2 T2 K W3"
      "W2 T2 A W3"
      "I I I I"
      "I I I I"
      "I I I I"
      ". N N ."
      "C C C C"
      ". B B .";
  }

  & > * {
    min-width: 0; /* Prevents content from affecting column width */
    max-width: 100%; /* Forces content to fit inside columns */
    // overflow: hidden; /* Prevents content from overflowing columns */
  }

  &__Item {
    border-radius: var(--border-radius);
    position: relative;

    &.short {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      background-color: var(--bg, #{$col-Off-Black});

      &.cta {
        background-color: transparent;
        text-align: center;
        h2 {
          color: $col-White;
        }
      }
    }
  }

  &__Background {
    $blur-size: 60px;
    --Dashboard-bg-blur: #{$blur-size};
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .image {
      position: fixed;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      height: 100lvh;
      background-size: cover;
      background-position: center;
      filter: brightness(60%);
    }
    .blur-overlay {
      position: fixed;
      inset: 0;
      backdrop-filter: blur(var(--Dashboard-bg-blur));
      -webkit-backdrop-filter: blur(var(--Dashboard-bg-blur));
    }
  }

  .TeaserMain {
    display: flex;
    flex-direction: column;

    &__Tickets {
      text-align: center;
      margin: var(--s-100) 0 var(--s-075);

      @include mq($from: desktop) {
        // margin: var(--s-150) 0;
      }
      @include mq($from: full) {
        margin: var(--s-250) 0;
      }
    }
  }

  .Link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: var(--fg, #{$col-White});

    &__Title {
      text-align: center;
    }
  }

  .LinkSection {
    margin-top: 5rem;

    @include mq($until: tablet) {
      margin-top: 0;
    }

    &__Row {
      display: flex;
      flex-wrap: wrap;
      gap: var(--gap);
      &:not(:last-child) {
        margin-bottom: var(--gap);
      }
    }
    &__Link {
      flex: unset;
      width: 100%;

      @include mq($from: tablet) {
        flex: 1;
      }

      &.smol {
        --n: 3;
        display: flex;
        justify-content: center;
        flex-grow: 0;
        padding-inline: 2rem;
        width: calc((100% / var(--n)) - var(--gap) * (var(--n) - 1) / var(--n));
        @include mq($from: tablet) {
          width: unset;
        }

        &::after {
          content: none;
        }
        .Icon {
          --icon-size: 1.5rem;
          display: block;
          margin-block: -0.2rem;
          svg {
            display: block;
          }
        }
      }
    }
  }

  &__FooterLink.button-box {
    margin-bottom: var(--gap);
    width: fit-content;
    align-self: center;
    padding: var(--s-065) var(--s-100);
    border-radius: 100vw;
    gap: 0.5em;

    @include font-extra-small();
    svg {
      --icon-size: 1em !important;
    }
    .Dashboard__Item:has(&) {
      // place-self: center center;
      align-self: center;
      justify-self: center;
    }
  }

  .Wildcard.default .Wildcard__Title H2,
  .Link__Title,
  .Upcoming .Teaser__Main-Acts {
    text-align: center;
    @include font-lead($font-weight-bold);
    @include mq($from: tablet) {
      @include font-h2($font-weight-bold);
    }
  }
}
