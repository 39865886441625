@use "../Variables" as *;

.BannerLogo {
  contain: paint;
  isolation: isolate;

  &__ClipPath {
    width: 0;
    height: 0;
  }

  &__Content {
    background-color: $col-Red;
    color: $col-Black;
    clip-path: url(#BannerLogo__ClipPath);
    height: 52px;
    width: 82px;
    font-feature-settings: "ss02";
    font-size: 3rem;
    font-weight: 700;
    display: grid;
    place-content: center;
  }

  &__Foreground {
    mix-blend-mode: darken;
    transform: scale(0.65);
    filter: contrast(110%);
  }

  &__Foreground,
  &__Background,
  &__Hover {
    grid-area: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__Hover {
    transform: translate(0, 100%);
  }
  &:hover {
    .BannerLogo__Foreground {
      transform: translate(0, -100%);
    }
    .BannerLogo__Hover {
      transform: translate(0, 0);
    }
  }
}
