@use "../Mixins" as *;
@use "../Variables" as *;

.VibeCheck {
  --icon-size: 1.3rem;
  --play-button-icon-size: 1.5rem;
  --social-icon-gap: 1rem;
  --main-link-gap: 1rem;
  --social-image-size: 4rem;

  display: grid;
  grid-template-columns: min-content auto max-content;
  gap: 1rem;
  background-color: var(--c-blurry-dark-55);
  align-items: center;
  border-radius: var(--border-radius-1);

  // @include info-box-paddings;
  padding: 0.5rem;

  padding: var(--s-050) var(--s-250) var(--s-050) var(--s-050);

  @include mq($until: wide) {
    padding: var(--s-050) var(--s-200) var(--s-050) var(--s-050);
  }
  @include mq($until: tablet) {
    padding: var(--s-050) 1.25rem var(--s-050) 0.5rem;
  }

  &__PlayButton {
    --icon-size: 1.5rem;
    color: var(--c-text);
    display: grid;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    gap: var(--main-link-gap);
    border-radius: 3px;
    overflow: hidden;
    .Icon {
      --icon-size: var(--play-button-icon-size);

      grid-area: 1/-1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0 0 0 /0.25);
      transition: background-color 222ms ease-out;
      &:hover {
        background-color: rgba(0 0 0 /0);
      }
    }
  }

  &__Image {
    width: var(--social-image-size);
    height: var(--social-image-size);
    grid-area: 1/-1;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__Title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__SocialLinks {
    --icon-size: 1.1rem;
    display: flex;
    gap: var(--social-icon-gap);
    align-items: center;
    justify-content: end;
  }

  &__SocialLinks a {
    display: grid;
    align-items: center;
    justify-items: center;

    &:hover {
      svg {
        color: var(--c-primary-text);
        transform: scale(0.95);
        transition: all 300ms ease;
      }
    }
  }
  &__SocialLinks svg {
    color: var(--c-secondary-text);
    transition: all 300ms ease;
  }
}
