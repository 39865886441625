@use "../../Variables.scss" as *;
@use "../../Mixins.scss" as *;

.Upcoming {
  display: grid;
  grid-template-rows: auto 2rem;
  height: 100%;
  overflow: hidden;
  scroll-margin-top: 100px;

  .splide__pagination > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    button {
      margin: 0;
    }
  }

  .Card__Title {
    @include font-h3;
  }
  .Card__Byline {
    @include font-small;
  }
}
