.ShareEvent {
  &__Modal {
  }

  &__Modal[open] {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(100px);
    border: none;

    .button-box {
      border-radius: var(--s-100);
    }
  }

  &__ModalInner {
    gap: var(--s-050);
  }

  &__Modal[open]::backdrop {
    // background-color: var(--c-blurry-dark);
    // filter: blur(100px);
    // opacity: 0.5;
  }

  &__OpenButton,
  &__CloseButton {
    appearance: none;
    border: none;
  }
}
