@use "../Variables" as *;
@use "../Mixins" as *;

.EventPage {
  & .HeroPage__Main {
    grid-row-gap: 1.5rem;
    @include grid("heroPage");
  }

  &__Meta {
    display: flex;
    gap: var(--s-050);

    .spacer {
      flex: 1;
      @include mq("mobile") {
        display: none;
      }
    }
  }
  .ops {
    white-space: nowrap;
  }

  &__SupportActs,
  &__Subtitle {
    .Act__name {
      @include font-h2();
    }
    .Act__country-code {
      margin-left: var(--s-030);
      color: var(--c-secondary-text);
      @include font-small();
    }
    dt {
      color: var(--c-secondary-text);
    }
  }
  &__InfoBox {
    border-radius: var(--s-050);
    background: var(--c-blurry-dark-55);
    margin-bottom: var(--s-200);
    @include font-copy();
    @include info-box-paddings;
    @mixin item {
      padding: var(--s-050) 0;
      margin-right: 0;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--c-light-30);
      }
      @include mq($until: "tablet") {
        padding: var(--s-050) 0;
      }
    }

    dl {
      display: grid;
      grid-template-columns: max-content auto;
    }

    dt {
      grid-column-start: 1;
      @include item;
    }

    dd {
      grid-column-start: 2;
      text-align: right;
      @include item;
    }
  }

  &__Tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: var(--s-050);
  }

  &__Tickets {
    margin-block: var(--block-margin-3);
    justify-self: center;
    z-index: 1;
    a.capsule {
      pointer-events: all;
      transition: background-color, scale;
      margin-top: 0;
      transition-duration: 444ms;
      transition-timing-function: ease-out;
      @include call-to-action;
      &:hover {
        scale: 1.1;
        transition-duration: 222ms;
        background-color: var(--c-light-100);
      }
    }

    &:not(.footer) {
      opacity: max(0, 50 - var(--scroll-y-vh, 0));

      a.capsule {
        margin-top: var(--s-100);
      }
    }

    &.footer {
      position: fixed;
      bottom: 2rem;
      width: 100%;
      margin: 0;
      text-align: center;
      transform: translateY(6rem);
      transition: transform 333ms ease-out;

      &.visible {
        transform: translateY(0);
      }
      &.scroll-end {
        position: relative;
      }
    }
  }
}
