@use "sass:math";
@use "../Variables" as *;
@use "../Mixins" as *;

body[data-dash-filter-overlay-state="open"] {
  overflow: hidden;
}

.DashboardPage {
  &__FilterTags {
    height: 100%;
  }
}
.Dashboard {
  &__FilterOverlay {
    &__Content {
      height: 100%;
      .FilterList {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-padding-small);
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: var(--spacing-padding-extra-large);
      }
    }

    body[data-dash-filter-overlay-state="open"] & .Filtertag {
      @include filtertag-staggered-entry;
      --delay: var(--index);
    }
  }

  .FilterOverlayToggle {
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: grid;
    view-transition-name: filter-toggle;

    &__Data {
      display: none;
    }

    &__Icon {
      grid-area: 1/-1;
      // place-self: start end;
      align-self: start;
      justify-self: end;
      margin: $spacing-padding-small;
      @include mq($until: tablet) {
        align-self: center;
        margin-block: 0;
        margin-right: 1rem;
        svg {
          display: block;
        }
      }
    }
    &__Title {
      @include font-copy();
      grid-area: 1/-1;
      // place-self: center center;
      align-self: center;
      justify-self: center;
      color: $col-White;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: $spacing-padding-small;
    }
    .conjunction {
      color: $col-Darkgrey;
    }
  }

  &__FilterOverlay {
    display: none;
    overflow: auto;
    @include hide-scrollbars;
  }
  body[data-dash-filter-overlay-state="open"] &__FilterOverlay {
    cursor: pointer;
    display: block;
    position: fixed;
    inset: 0;
    z-index: 10000;
    color: white;
  }
}

@keyframes enter-filtertag {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
