@use "../Variables" as *;
@use "../Mixins" as *;

.Radio {
  height: 100%;
  width: 100%;
  padding: var(--spacing-padding-small);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .Dashboard__Item:has(&) {
    --bg: #{$col-DarkBlurry};
  }

  .Dashboard__Item & {
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  &__Visual {
    display: flex;
    align-items: end;
    justify-content: center;
    overflow: hidden;
  }

  &__Logo {
    --icon-size: 6rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-size, 6rem);
    height: var(--icon-size, 6rem);
    background: white;
    border-radius: 50%;
    overflow: hidden;

    @include mq($from: tablet, $until: wide) {
      --icon-size: 4rem;
    }

    img {
      object-fit: contain;
      scale: 0.33;
      rotate: calc(var(--angle) * 1deg);
      transition: scale 0.1s;
    }
  }

  &__Info {
    @include font-small;
    min-width: 100%;
    color: $col-White;
    text-align: center;
    letter-spacing: 0.03em;
    line-height: var(--flh-1);

    .talent {
      font-weight: var(--fw-bold);
    }
    .title {
      color: var(--c-light-60);
    }

    .talent,
    .title {
      min-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__Controls {
    display: flex;
    gap: 1rem;
  }

  &__Control {
    @include button-reset;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;

    &.OutboundLink {
      justify-content: end;
    }

    .Icon {
      --icon-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.Play .Icon,
    &.Pause .Icon {
      --icon-size: 1.6rem;
      @include mq($from: wide) {
        --icon-size: 2.4rem;
      }
    }

    &.OutboundLink .Icon {
      --icon-size: 1rem;
      --icon-color: var(--c-text-muted);
    }

    .Icon svg {
      fill: white;
    }

    .is-playing &.Play {
      display: none;
    }
    .is-paused &.Pause {
      display: none;
    }
  }
  &__Visualizer {
    display: flex;
    justify-content: start;
    align-items: center;
    .inner {
      --size: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: var(--size);
      width: var(--size);
    }
    .bar {
      --bar-height: 0;
      width: 2px;
      border-radius: 100vw;
      background-color: var(--c-text-muted);
      .Radio.is-paused & {
        transition: height 0.5s ease;
        height: 2px;
      }
      .Radio.is-playing & {
        transition-duration: 0;
        height: calc(var(--bar-height) * 200%);
        &:nth-child(1),
        &:nth-child(4) {
          scale: 1 0.5;
        }
      }
    }
  }
}

/*        FALLBACK IOS    */

.css-fallback-animation.Radio.is-playing {
  .Radio__Visualizer .bar {
    transition: none;
    scale: unset !important;
    animation: pulse 1.2s infinite ease-in-out;
  }

  .Radio__Visualizer .bar:nth-child(1) {
    animation-delay: 0s;
    animation-duration: 0.9s;
  }
  .Radio__Visualizer .bar:nth-child(2) {
    animation-delay: 0.1s;
    animation-duration: 0.6s;
  }
  .Radio__Visualizer .bar:nth-child(3) {
    animation-delay: 0.2s;
    animation-duration: 0.7s;
  }
  .Radio__Visualizer .bar:nth-child(4) {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
  }
}

@keyframes bounce {
  0%,
  100% {
    height: 20%;
  }
  50% {
    height: 100%;
  }
}

@keyframes pulse {
  0% {
    height: 20%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 20%;
  }
}
