@use "../Variables" as *;
@use "../Mixins" as *;

.FooterDisclamer {
  --color: var(--c-light-30);
  display: flex;
  justify-content: space-between;
  color: var(--color);
  margin-inline: var(--spacing-padding-large);
  margin-top: var(--page-padding-bottom);
  padding-bottom: 0.5rem;

  @include mq($until: tablet) {
    flex-direction: column-reverse;
    gap: var(--s-050);
    align-items: center;
    padding-bottom: var(--s-100);
  }

  &__Item {
    display: flex;
    gap: var(--gap);
    @include font-small();
    a {
      text-decoration: none;
    }
  }

  &__Link {
    color: var(--color);
  }
}
