@use "../Variables" as *;
@use "../Mixins" as *;

.HeroPage {
  --Hero__Main-Overlap: var(--s-400);

  --main-background-color: rgba(118, 118, 118, 0.3);
  --icon-color: #{$col-White};
  background-color: var(--c-background);
  color: var(--c-primary-text);

  @include mq($from: desktop) {
    --Hero__Main-Overlap: var(--s-500);
  }

  &:has(.overlay.open) {
    overflow: hidden;
  }

  .MainMenu {
    --main-menu-color-fg: #{$col-White};
  }

  &__Main {
    isolation: isolate;
    contain: paint;
    position: relative;
    padding: 6rem;
    z-index: 3;
    margin-top: calc(100svh - var(--Hero__Main-Overlap));

    background-color: var(--main-background-color);
    backdrop-filter: var(--effect-event-bg-blur);
    -webkit-backdrop-filter: var(--effect-event-bg-blur);
    border-radius: 1.5rem;
    position: relative;
    @include grid("heroPage"); // Frage: Was macht das?
    @include pseudo(before) {
      z-index: -1;
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(0 0 0 / 0.2),
        rgba(0 0 0 / 0.2),
        transparent
      );
    }
  }

  &__Visual {
    --interval-duration: 5000; /* must be in milliseconds for JS */
    --transition-duration: 2s;
    display: grid;
    height: 100lvh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: max(0.5, calc(1 - var(--scroll-y-vh) * 0.01));

    .ArticlePage & {
      opacity: 1;
    }

    &:not(.transition) picture:first-of-type {
      opacity: 1;
    }

    picture {
      grid-column: 1/2;
      grid-row: 1/2;
      opacity: 0;

      &.previous {
        opacity: 1;
        z-index: 10;
      }
      &.current {
        opacity: 1;
        z-index: 100;
        transition: opacity var(--transition-duration) ease-in-out;
      }
    }

    img {
      width: 100vw;
      height: 100lvh;
      object-fit: cover;
      object-position: var(--focus, center);
      filter: brightness(0.8);
    }
  }

  &__Text {
    height: calc(100svh - var(--Hero__Main-Overlap));
    display: grid;
    position: fixed;
    inset: 0;
    z-index: 2;

    // .capsule {
    //   opacity: calc(max(0, 10 - var(--scroll-y-vh, 0)));
    // }
    .PrevNext {
      opacity: calc(max(0, 20 - var(--scroll-y-vh, 0)));
      @include mq($from: tablet) {
        opacity: calc(max(0, 30 - var(--scroll-y-vh, 0)));
      }
    }
  }

  &__Title {
    grid-area: 1/-1;
    align-self: center;
    text-align: center;
    color: $col-White;
    text-shadow: 0 0 10rem $col-DarkBlurry;
    margin-inline: 6vw;
    pointer-events: none;
    opacity: max(
      0,
      50 - var(--scroll-y-vh, 0)
    ); // Hides title when it is behind contentbox

    h1.is-event-title {
      @include font-event-title($font-weight-bold);
      margin-block: 1rem;
      .Act__country-code {
        @include font-copy();
      }
    }
  }

  &__Footer {
    height: $page-padding-bottom;
    position: relative;
    z-index: 3;
    .PrevNext {
      height: auto !important;
    }
  }

  .PrevNext {
    grid-area: 1/-1;
    height: min-content;
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline: var(--spacing-padding-medium);
    padding-block: var(--spacing-padding-large);
    z-index: -1;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // &:active {
    //   background-color: transparent;
    // }

    @include mq($from: mobile, $until: tablet) {
      align-self: end;
    }
  }

  .PrevNext__Button {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.overview {
      text-decoration: none;
    }
    &[data-swup-animation="prev"] {
      justify-content: flex-start;
    }
    &[data-swup-animation="next"] {
      justify-content: flex-end;
    }
  }

  .HeroPage__Header .PrevNext__Button {
    &[data-swup-animation="prev"] {
      @include mq($until: tablet) {
        align-items: end;
      }
    }
    &[data-swup-animation="next"] {
      @include mq($until: tablet) {
        align-items: end;
      }
    }
  }

  .PrevNext__ButtonInner {
    color: $col-White;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-background-color);
    backdrop-filter: var(--effect-event-bg-blur);
    text-decoration: none;
    border-radius: 5px;
    height: 3rem;
    width: 3rem;
    pointer-events: none;

    &.overview {
      display: flex;
      align-items: center;

      min-width: 10rem;
      width: auto;
    }

    .Icon {
      display: flex;
    }
  }

  .PrevNext__Button[href=""] {
    pointer-events: none;
    color: $col-Darkgrey;
    svg * {
      fill: $col-Darkgrey;
    }
  }
}
