@use "../Variables" as *;
@use "../Mixins" as *;

.ArticlePage {
  --main-background-color: #{$col-BG};
  --main-foreground-color: #{$col-Black};
  --main-backdrop-filter: none;
  .Article__Body {
    grid-column: full;
  }
}

.Article {
  &__Meta {
    grid-column: content;
    margin-bottom: var(--s-200);
  }

  &__Tag {
    text-decoration: none;
  }

  &__Lead {
    grid-column: content;
    margin-top: var(--s-150);
    margin-bottom: var(--s-300);
    @include font-lead();
  }

  &__Byline {
    grid-column: content;
    color: var(--c-secondary-text);
    display: flex;
    gap: $spacing-padding-large;
    flex-wrap: wrap;
    padding-bottom: var(--s-050);
    margin-bottom: var(--s-300);
    @include font-small();
  }

  &__Photographer {
    color: var(--c-light-40);

    &::before {
      content: "Titelbild: ";
    }
  }

  &__Body {
    display: grid;
    column-gap: var(--s-100);
    grid-template-columns: subgrid;
    > * {
      grid-column: content;
    }
  }
}
