@use "../Variables" as *;
@use "../Mixins" as *;

.ArticlesPage {
  .FilterPage {
    &__FilterResults {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--s-300);

      .Card {
        width: clamp(360px, 35vw, 600px);

        &.highlight {
          @include mq($from: desktop) {
            width: 85vw;
            --teaser-ratio: 2/1 !important;
          }
        }

        @include mq($until: desktop) {
          width: 100%;
        }
      }
    }
  }
}
