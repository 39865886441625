@use "../Variables" as *;
@use "../Mixins" as *;

@use "sass:math";

.MainMenu {
  &__NavList {
    padding-block: 1rem;
    padding-top: 4rem;

    @include mq($until: tablet) {
      padding-block: 1rem;
      padding-top: 6rem;
    }
  }
  .SubMenu__NavContent {
    display: none;

    &.submenu-open {
      margin-bottom: 1rem;
    }
  }
  a {
    text-decoration: none;
  }
  &__Toggle {
    z-index: 100000;
  }
  &__NavContent {
    border-radius: var(--border-radius-1);
    min-width: 300px;
    right: 0.5rem;
    top: 1.5rem;
    height: 0;
    overflow: hidden;
    position: fixed;
    background: var(--c-dark-off-black);
    color: white;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

    &.main-nav-open {
      transform: scaleY(100%);
      height: min-content;
      overflow-y: auto;
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      @include mq($until: tablet) {
        height: 100svh;
        border-radius: 0;
      }
      .NavItem__Main {
        opacity: 0;
        animation: nav-item-fade-in 0.3s ease-in-out forwards;
        animation-delay: calc(50ms * var(--index-main));
      }
      @keyframes nav-item-fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    @include mq($until: tablet) {
      width: 100%;
      inset: 0;
    }
  }

  .NavItem {
    @include button-reset();
    @include font-copy();
    color: var(--c-secondary-text);
    overflow: hidden;
    width: calc(100% - 2 * 10px);
    display: block;
    // padding: 0;
    // padding: 0 10px;
    margin-inline: 10px;
    border-radius: 11px;

    @include mq($until: tablet) {
      @include font-lead();
    }

    &__Arrow {
      --icon-size: 20px;
      position: absolute;
      transform: rotate(90deg);
      right: 1rem;
    }

    &__Inner {
      width: 100%;
      display: block;
      padding: 0.4rem 0.6rem 0.4rem;
      margin: 0.3rem 0 calc(0.3rem - 0.5px);
      display: inline-flex;
      align-items: baseline;
      position: relative;

      &:hover {
        background: var(--c-light-10);
        border-radius: 4px;
      }

      &::after {
        content: "";
        position: absolute;
        top: -0.3rem;
        left: 0.6rem;
        right: 0.6rem;
        height: 1px;
        background-color: var(--c-light-30);
      }
    }

    &__SubMenu {
      .NavItem__Inner {
        margin: 0.1rem 0;
        &::after {
          content: none;
        }
      }
    }

    &__Icon {
      --size: 0.95rem;
      transform: translateY(-0.01em);
      svg {
        margin-right: var(--s-065);
        display: block;
        width: var(--size);
        height: var(--size);
      }
      svg * {
        fill: var(--c-light-off-white);
      }
    }

    &__Main {
      @include font-lead();
      // width: 100%;
      color: var(--c-primary-text);
      @include mq($until: tablet) {
        @include font-h2($font-weight-normal);
      }
    }
  }
}
