@use "../Mixins" as *;

.ImpressionsPage {
  // --teaser-content-gap: var(--spacing-padding-small);

  .DefaultPage__Header {
  }

  .DefaultPage__Main {
    row-gap: var(--gap);
  }

  .Card {
    @include mq($until: tablet) {
      aspect-ratio: 3/2;
    }
  }
}
