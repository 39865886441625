@use "../Mixins" as *;
@use "../Variables" as *;
@use "sass:map";

.Card {
  --card-background: var(--c-blurry-dark);
  --card-list-gap: var(--s-075);
  --card-border-radius: 20px;
  --card-tag-margin: var(--s-050);
  --card-tag-margin: 0.66rem;

  aspect-ratio: var(--teaser-ratio, 6/7);
  height: 100%;
  overflow: hidden;
  border-radius: var(--card-border-radius);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas:
    "date meta ticket"
    "content content content";

  &__Meta {
    padding: var(--card-tag-margin);
    padding-left: var(--card-tag-margin);
    grid-area: meta;
    z-index: 30;
    overflow: auto;
    display: flex;
    gap: var(--s-033);
    @include hide-scrollbars;
  }

  &__Date {
    padding: var(--card-tag-margin);
    padding-right: 0;
    grid-area: date;
    z-index: 30;
    & + .Card__Meta {
      padding-left: var(--s-033);
    }
  }
  &__Time {
    display: none;
    z-index: 30;
    & + .Card__Meta {
      padding-left: var(--s-033);
    }
  }
  &__Ticket {
    grid-area: ticket;
    z-index: 30;
    padding: var(--spacing-padding-small);
    .DashboardPage & {
      padding: 0;
    }

    .TicketLink {
      &__Text {
        display: none;
      }
      &__Icon {
        display: block;
        opacity: 0;
        transition: opacity 333ms ease-out,
          scale 333ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
          background-color 666ms ease-out;
        --icon-color: var(--c-ticket);
        &:hover {
          --icon-color: var(--c-ticket-muted);
          scale: 1.1;
        }
      }
    }
  }
  &:hover .TicketLink__Icon {
    opacity: 1;
  }

  &__Text {
    padding: var(--spacing-padding-large) var(--spacing-padding-medium);
    grid-area: content;
    text-align: center;
    align-self: end;
    z-index: 10;

    @include font-copy;
    @include mq($until: tablet) {
      padding: var(--spacing-padding-medium);
    }
  }
  &__Title {
    @include font-h2;
    @include mq($until: tablet) {
      @include font-h1;
    }
    .Act__country-code {
      @include font-copy;
      color: var(--c-light-60);
    }
  }
  &__Byline {
    @include font-copy;
    margin-top: var(--s-075);
    color: var(--c-light-60);
    overflow: hidden;
    @include line-clamp(2);
  }
  &__Link {
    grid-row: 1/-1;
    grid-column: 1/-1;
    display: block;
    z-index: 20;

    // &:not(:focus-visible) {
    //   outline: none;
    // }

    // &:focus-visible {
    //   border-radius: var(--card-border-radius);
    //   outline: 4px solid Highlight;
    //   outline-offset: -4px;
    // }
  }
  &__Image {
    grid-row: 1/-1;
    grid-column: 1/-1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: var(--focus);
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      // transform: translateY(1px);
      background: rgba(0 0 0 / 0.125);
      background: linear-gradient(
        to top,
        var(--c-dark-off-black) 0%,
        rgba(0, 0, 0, 0.125) 50%,
        rgba(0, 0, 0, 0.125) 100%
      );
      mix-blend-mode: multiply;
    }
  }

  &__Backdrop {
    display: none;
  }

  /******** VARIANTS ********/

  /******** MODIFIERS ********/

  &.text-small {
    .Card__Text {
      padding: var(--s-100);
    }

    .Card__Title {
      margin-bottom: 0;
      overflow: hidden;
      @include line-clamp(1);
      @include font-copy;
    }
    .Card__Byline {
      overflow: hidden;
      @include line-clamp(2);
      @include font-small;
    }
  }

  &.align-left {
    .Card__Title,
    .Card__Byline {
      text-align: left;
    }
    .Card__Title {
      font-weight: bold;
    }
  }

  &.vertically-centered {
    .Card__Text {
      display: flex;
      align-self: center;
      justify-self: center;
      padding: 0 var(--s-200);
    }
    .Card__Title {
      margin-block: 0;
      @include font-h2;
      // font-weight: normal;
    }
  }

  &.overlay-solid {
    .Card__Image::before {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &.text-inverted {
    .Card__Text {
      color: var(--c-dark-off-black);
    }
  }
}

/******** EXEPTIONS ********/

.DashboardPage {
  .Card__Title {
    @include font-h2();
    margin-bottom: var(--spacing-padding-small);
  }
  .Card__Text {
    padding-bottom: var(--spacing-padding-medium);
  }
}

.EventsPage:not(.list-view-is-active),
.ArchivePage:not(.list-view-is-active) {
  .Card-Event {
    .Card__Text {
      transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover .Card__Text {
      transform: translateY(-0.5rem);
    }
  }
}

.EventsSlider {
  .Card__Title {
    @include font-h3;
    @include mq($until: tablet) {
      margin-bottom: var(--spacing-padding-small);
    }
    .Act__country-code {
      @include font-extra-small;
    }
  }
  .Card__Byline {
    @include font-small;
  }
}

/*********************************/
/******** LIST VIEW ITEM ********/
/*******************************/

body.EventsPage.list-view-is-active,
body.ArchivePage.list-view-is-active {
  .Card {
    --card-border-radius: var(--s-100);
    --card-background-transition: background-color 666ms ease;

    aspect-ratio: unset;
    grid-template-rows: auto;
    grid-template-columns: 12vw 8vw 8vw 10fr 5fr 8vw;
    grid-template-areas: "date time image content meta ticket";
    isolation: isolate;

    &:hover {
      --card-background-transition: background-color 0.2s ease;
      --card-background: var(--c-light-darkgrey);
    }

    &__Date {
      grid-area: date;
      align-content: center;
      margin-right: var(--card-list-gap);
      padding: var(--card-tag-margin);
      background: var(--card-background);
      border-radius: var(--card-border-radius);
      transition: var(--card-background-transition);
    }

    &__Time {
      display: block;
      grid-area: time;
      align-content: center;
      border-radius: var(--card-border-radius);
      margin-right: var(--card-list-gap);
      background: var(--card-background);
      transition: var(--card-background-transition);
    }
    &__Image {
      aspect-ratio: 3/2;
      grid-area: image;
      align-self: stretch;
      width: 100%;
      height: 100%;
      background: none;
      border-top-left-radius: var(--card-border-radius);
      border-bottom-left-radius: var(--card-border-radius);
      overflow: hidden;

      picture,
      picture img {
        object-fit: cover;
        object-position: var(--focus);
      }

      &::before {
        content: none;
      }
    }
    &__Link {
      background: none;
    }
    &__Meta {
      align-self: stretch;
      align-content: center;
      align-items: center;
      background: var(--card-background);
      transition: var(--card-background-transition);
    }
    &__Ticket {
      z-index: 100;
      background: var(--card-background);
      transition: var(--card-background-transition);
      padding-inline: var(--spacing-padding-medium);

      .TicketLink {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: end;
        text-decoration: none;
        &__Icon {
          align-self: center;
          justify-self: center;
          display: none;

          @include mq($until: tablet) {
            display: inline-block;
            opacity: 1;
          }
        }
        &__Text {
          display: inline-block;
          transition: all 333ms ease-out;
          &:hover {
            opacity: 1;
            transition: all 111ms ease-out;
            background-color: var(--c-ticket-muted);
            scale: 1.1;
          }
          @include mq($until: tablet) {
            display: none;
          }
        }
      }
    }
    &__Title {
      @include font-h3;
    }
    &__Byline {
      @include font-small;
    }
    &__Text {
      text-align: left;
      align-content: start;
      padding: var(--s-100) var(--s-150);
      background: var(--card-background);
      transition: var(--card-background-transition);
      justify-content: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      @include mq($until: desktop) {
        padding: var(--s-100);
      }
    }

    /******** VARIANTS ********/

    &.date-hidden .Card__Date {
      visibility: hidden;
    }

    /******** OVERRIDES ********/

    &__Time,
    &__Date {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__Date {
      .capsule {
        background: none;
      }
    }
    &__Time {
      @include mq($from: desktop) {
        .capsule {
          background: none;
        }
      }
    }
  }

  @include mq($until: desktop) {
    .Card {
      --card-border-radius: 0.5rem;
      grid-template-columns: 20vw min-content 4fr 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas: "date content content ticket" "date time meta ticket";

      &__Image {
        display: none;
      }

      &__Meta,
      &__Ticket,
      &__Time,
      &__Text {
        background-color: unset;
      }

      &__Backdrop {
        display: block;
        grid-row: 1/-1;
        grid-column: 2/-1;
        background: var(--card-background);
        z-index: -1;
        border-radius: var(--card-border-radius);
      }

      &__Time {
        margin: var(--s-100) 0 var(--s-100) var(--s-100);
        margin-top: 0;
      }
    }
  }
  @include mq($until: tablet) {
    .Card {
      --card-border-radius: 0.5rem;
      grid-template-columns: 25vw min-content 4fr 1fr;
    }
  }
}
