@use "../../Variables" as *;

.ImpressionsGallery {
  display: grid;
  height: 100%;
  width: 100%;

  &__Track {
    grid-area: 1 / -1;
  }

  &__Meta {
    grid-area: 1 / -1;
    z-index: 1;
    padding: $spacing-padding-small;
    pointer-events: none;
  }

  .Teaser__Image {
    position: absolute;
    inset: 0;
  }
}
