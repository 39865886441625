@use "../Variables" as *;
@use "../Mixins" as *;
@forward "ResponsiveOverlay.scss";
@forward "FocusStyles";

@each $fs-key, $fs-value in $font-sizes {
  .fs-#{$fs-key} {
    font-size: $fs-value;
    line-height: map-get($font-line-heights, $fs-key);
    letter-spacing: map-get($font-letter-spacing, $fs-key);
  }
}

.capsule {
  --Capsule-Color: var(--c-text-inverted);
  --Capsule-Background: var(--c-background-inverted);
  // box-shadow: $effect-drop-shadow-capsule;
  white-space: nowrap;
  @include font-small();
  padding: 0.2rem 0.6rem;
  border-radius: 2.2rem;
  display: inline-block;
  color: var(--Capsule-Color);
  background: var(--Capsule-Background);
  text-decoration: none;
  height: max-content;

  @include mq($from: desktop) {
    padding: 0.4rem 0.8rem;
  }

  a {
    color: var(--Capsule-Color);
    text-decoration: none;
  }

  &-important {
    padding: 0.6rem 1.6rem;

    @include mq($from: desktop) {
      padding: 0.8rem 2rem;
    }
  }

  &-date {
    --Capsule-Color: var(--c-light-white);
    --Capsule-Background: var(--c-blurry-dark);
    backdrop-filter: $effect-button-bg-blur;
    width: max-content;
  }

  &-green {
    color: black;
    background: var(--c-color-lime);
  }

  &-gray {
    --Capsule-Color: var(--c-primary-text-inverted);
    --Capsule-Background: var(--c-light-lightgrey);
    border: none;
  }
  &-blurry {
    --Capsule-Color: var(--c-light-white);
    --Capsule-Background: var(--c-blurry-dark);
    backdrop-filter: $effect-button-bg-blur;
    border: none;
  }

  &-white {
    --Capsule-Color: var(--c-dark-black);
    --Capsule-Background: var(--c-light-white);
  }

  &-outline {
    --Capsule-Color: var(--c-light-white);
    --Capsule-Background: transparent;
    border: 1px solid var(--c-light-60);
  }
  &-ausverkauft {
    --Capsule-Background: var(--c-state-disabled);
    --Capsule-Color: var(--c-text-inverted);
  }
  &-abgesagt {
    --Capsule-Background: var(--c-state-error);
    --Capsule-Color: var(--c-text-inverted);
  }
  &-verschoben {
    --Capsule-Background: var(--c-state-warning);
    --Capsule-Color: var(--c-text-inverted);
  }
  &-letzte-tickets {
    --Capsule-Background: var(--c-state-info);
    --Capsule-Color: var(--c-text-inverted);
  }
}

.Filtertag .capsule {
  @include font-lead();
  padding: var(--s-075) var(--s-150);
}

small.capsule {
  font-size: 0.25rem; // QUESTION: Need?
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.capitalize {
  text-transform: capitalize;
}

.button-box {
  @include button-box;
}

body[data-dash-filter-overlay-state="open"] .overlay,
body[data-responsive-overlay-state="open"] .overlay {
  background-color: var(--main-background-color, $col-DarkBlurry);
  backdrop-filter: var(--effect-event-bg-blur, blur(120px));
  -webkit-backdrop-filter: var(--effect-event-bg-blur, blur(120px));
}

.dropshadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.FadeOverflow {
  @include hide-scrollbars;
  & {
    display: flex;
    gap: 0.5rem;
    overflow: auto;
    flex: 1;
    cursor: grab;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  &.aic {
    align-items: center;
  }

  &.jcc {
    justify-content: center;
  }
}
