@charset "UTF-8";
*,
*::before,
*::after {
  font: inherit;
  box-sizing: border-box;
} /* Switch to border-box for box-sizing. */
* {
  margin: 0;
  padding: 0;
  font: inherit; /* Get rid of all font sizes and heights */
  hanging-punctuation: none;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  margin: 0; /* Remove the tiny space around the edge of the page */
}

img,
video,
canvas,
audio,
iframe,
embed,
object {
  max-width: 100%;
  height: auto;
  display: block; /* Switch display mode to block, since that's what we usually want for images. */
  vertical-align: middle; /* If you override, and make an image inline, it's likely you'll want middle vertical alignment. */
}

ul[class],
ol[class] {
  list-style: none;
}

:root {
  --fs-extra-small: clamp(0.63rem, 0.26vi + 0.56rem, 0.88rem);
  --fs-small: clamp(0.75rem, 0.26vi + 0.69rem, 1rem);
  --fs-1: clamp(0.9rem, 0.26vi + 0.85rem, 1.24rem);
  --fs-2: clamp(1.13rem, 0.45vi + 1.02rem, 1.56rem);
  --fs-3: clamp(1.56rem, 0.78vi + 1.38rem, 2.31rem);
  --fs-4: clamp(2.19rem, 4.85vi + 1.05rem, 6.88rem);
  --fs-5: clamp(2.5rem, 7.77vi + 0.68rem, 10rem);
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-bold: 600;
  --flh-extra-small: 1.2;
  --flh-small: 1.36;
  --flh-1: 1.36;
  --flh-2: 1.26;
  --flh-3: 1.1;
  --flh-4: 1;
  --flh-5: 0.97;
  --fls-extra-small: 0.05rem;
  --fls-small: 0.03rem;
  --fls-1: 0.02rem;
  --fls-2: 0.01rem;
  --fls-3: 0.01rem;
  --fls-4: -0.01em;
  --fls-5: -0.02em;
  --c-dark-black: #000000;
  --c-dark-background: #040404;
  --c-dark-off-black: #262626;
  --c-dark-text: var(--c-dark-off-black);
  --c-light-white: #ffffff;
  --c-light-off-white: rgba(255, 255, 255, 0.9);
  --c-light-lightgrey: rgba(255, 255, 255, 0.7);
  --c-light-darkgrey: rgba(255, 255, 255, 0.3);
  --c-light-100: white;
  --c-light-80: rgba(255, 255, 255, 0.8);
  --c-light-60: rgba(255, 255, 255, 0.6);
  --c-light-50: rgba(255, 255, 255, 0.5);
  --c-light-40: rgba(255, 255, 255, 0.4);
  --c-light-30: rgba(255, 255, 255, 0.3);
  --c-light-20: rgba(255, 255, 255, 0.2);
  --c-light-10: rgba(255, 255, 255, 0.1);
  --c-blurry-grey: rgba(38, 38, 38, 0.25);
  --c-blurry-white: rgba(255, 255, 255, 0.1);
  --c-blurry-light-10: rgba(255, 255, 255, 0.1);
  --c-blurry-light-30: rgba(255, 255, 255, 0.3);
  --c-blurry-dark: rgba(38, 38, 38, 0.7);
  --c-blurry-dark-25: rgba(38, 38, 38, 0.25);
  --c-blurry-dark-55: rgba(38, 38, 38, 0.55);
  --c-color-red: #f00d0d;
  --c-color-lime: #bdff00;
  --c-color-lime-muted: #15ff00;
  --c-color-purple: #9747ff;
  --c-color-orange: #ffba00;
  --c-color-blue: #54c7ec;
  --c-state-error: #fa383e;
  --c-state-warning: var(--c-color-orange);
  --c-state-info: var(--c-color-blue);
  --c-state-disabled: var(--c-light-30);
  --c-state-attention: var(--c-light-100);
  --c-event-background: rgba(118, 118, 118, 0.3);
  --c-brand-primary: var(--c-color-red);
  --c-brand-secondary: var(--c-color-lime);
  --c-text: var(--c-light-white);
  --c-text-inverted: var(--c-dark-black);
  --c-text-muted: var(--c-light-60);
  --c-text-muted-inverted: var(--c-dark-off-black);
  --c-background: var(--c-dark-background);
  --c-background-inverted: var(--c-light-white);
  --c-primary-text: var(--c-light-white);
  --c-primary-text-inverted: var(--c-dark-black);
  --c-ticket: var(--c-color-lime);
  --c-ticket-muted: var(--c-color-lime);
  --c-secondary-text: var(--c-light-lightgrey);
  --c-tertiary-text: var(--c-light-darkgrey);
  --s-033: 0.333rem;
  --s-050: 0.5rem;
  --s-065: 0.65rem;
  --s-075: 0.75rem;
  --s-100: 1rem;
  --s-150: 1.5rem;
  --s-175: 1.75rem;
  --s-200: 2rem;
  --s-250: 2.5rem;
  --s-300: 3rem;
  --s-400: 4rem;
  --s-500: 5rem;
  --s-600: 6rem;
  --s-650: 6.5rem;
  --s-700: 7rem;
  --s-800: 8rem;
  --effect-drop-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
  --effect-button-bg-blur: blur(20px);
  --effect-bg-blur-2: blur(40px);
  --effect-event-bg-blur: blur(120px);
  --spacing-padding-small: var(--s-050);
  --spacing-padding-medium: var(--s-100);
  --spacing-padding-large: var(--s-150);
  --spacing-padding-extra-large: var(--s-300);
  --header-height: 7rem;
  --icon-color: #ffffff;
  --icon-size: 2rem;
  --page-padding-bottom: 10rem;
  --gap: 1.5rem;
  --grid-row-gap: 1.5rem;
  --grid-col-gap: 1.5rem;
  --grid-padding-inline: 1rem;
  --grid-padding-block: 1.5rem;
  --grid-default-page: [full-start wide-start content-start] 1fr [content-end
    wide-end full-end];
  --grid-filter-page: 1fr;
  --grid-hero-page: var(--grid-default-page);
  --border-radius-1: 10px;
  --border-radius-2: 20px;
}
@media (min-width: 46.25em) {
  :root {
    --grid-padding-inline: 1.5rem;
    --grid-padding-block: 2.5rem;
    --grid-filter-page: 1fr 1fr;
    --grid-default-page: [full-start wide-start] 1fr [content-start] 6fr
      [content-end] 1fr [ wide-end full-end];
    --spacing-padding-small: 0.5rem;
    --spacing-padding-medium: 1.5rem;
    --spacing-padding-large: 2.5rem;
    --spacing-padding-extra-large: 6rem;
  }
}
@media (min-width: 61.25em) {
  :root {
    --grid-row-gap: 1.5rem;
    --grid-col-gap: 1.5rem;
    --grid-filter-page: 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 10fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }
}
@media (min-width: 81.25em) {
  :root {
    --grid-filter-page: 1fr 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 8fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }
}
@media (min-width: 120em) {
  :root {
    --grid-filter-page: 1fr 1fr 1fr 1fr;
    --grid-default-page: [full-start] 2fr [wide-start] 1fr [content-start] 4fr
      [content-end] 1fr [wide-end] 2fr [full-end];
  }
}
:root {
  view-transition-name: none;
}
:root::view-transition-old(filter-toggle), :root::view-transition-new(filter-toggle) {
  animation-duration: 666ms;
}

html {
  hanging-punctuation: first last; /* Not supported in Chrome */
  font-family: "kiffKunath", "Kunath", Avenir, Corbel, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}

h1,
.H1 {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: 600;
}

h2,
.H2 {
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: 600;
}

strong {
  font-weight: 600;
}

small {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}

p small {
  text-transform: uppercase;
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}

.extra-small {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
}

.ops * {
  background-color: rgb(179, 54, 54);
}

.page__title {
  text-align: center;
  margin: 1rem auto 2rem;
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: 600;
}
@media (min-width: 61.25em) {
  .page__title {
    margin: 2rem auto 4rem;
  }
}

.FigCaption {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  opacity: 0.5;
  margin-top: 0.25rem;
}

html {
  background: var(--c-background);
  color: var(--c-text);
}
html {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
html::-webkit-scrollbar {
  display: none;
}

body {
  display: grid;
  grid-template-areas: "header" "aside" "main" "footer";
  grid-template-rows: auto auto 1fr auto;
  min-height: 100svh;
}
body header {
  grid-area: header;
}
body aside {
  grid-area: aside;
}
body main {
  grid-area: main;
}
body footer {
  grid-area: footer;
}

main.DefaultPage__Main {
  display: grid;
  column-gap: var(--grid-col-gap);
  padding: var(--s-100) var(--grid-padding-inline);
  grid-template-columns: var(--grid-default-page);
}
@media (min-width: 61.25em) {
  main.DefaultPage__Main {
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
    padding-bottom: 6rem;
  }
}

a:not([class]) {
  color: var(--c-text);
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.DefaultPage__Header,
.FilterPage__Header {
  margin-top: var(--header-height);
}
@media (min-width: 61.25em) {
  .DefaultPage__Header,
  .FilterPage__Header {
    margin-top: calc(var(--header-height) * 1.5);
  }
}

.DashboardPage__Header {
  margin-top: var(--header-height);
}

button[type=button] {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}

.splide__pagination {
  gap: 0.23rem;
}

button.splide__pagination__page {
  background: var(--c-light-darkgrey);
  opacity: 1;
  height: 6px;
  width: 6px;
  transition: width 250ms ease-in-out, height 250ms ease-in-out;
  border-radius: 50%;
}
button.splide__pagination__page.is-active {
  transform: scale(1);
  background: var(--c-light-white);
  height: 8px;
  width: 8px;
}

dl {
  display: inline; /* Make the list inline */
}

dt {
  margin-right: 5px; /* Add some space after the term */
}

dd {
  margin-right: 10px; /* Add space between the definition and next term */
}

u {
  text-decoration: none;
}

html.is-rendering aside:has(+ aside) {
  display: none;
}

header.is-previous-container {
  z-index: 0;
}

.transition-slide {
  scale: 1.05;
}
html.is-changing .transition-slide {
  transition: transform 0.666s 0.333s, scale 0.666s ease-out, opacity 0.666s ease-out;
}
@media (max-width: 46.24em) {
  html.is-changing .transition-slide {
    transition-duration: 0.4995s, 0.333s, 0.666s;
    transition-delay: 0.1665s, 0, 0;
  }
}
html.to-next .is-previous-container .transition-slide {
  scale: 1;
  opacity: 0.5;
}
html.to-next .is-next-container .transition-slide {
  transform: translate3d(100%, 0, 0);
}
html.to-prev .is-previous-container .transition-slide {
  scale: 1;
  opacity: 0.5;
}
html.to-prev .is-next-container .transition-slide {
  transform: translate3d(-100%, 0, 0);
}

html.is-changing .transition-fade {
  transition: opacity 0.5625s cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: 0.375s;
}
@media (max-width: 46.24em) {
  html.is-changing .transition-fade {
    transition-duration: 0.375s;
    transition-delay: 0.1875s;
  }
}
.is-previous-container .transition-fade {
  opacity: 0;
}
.is-next-container .transition-fade {
  opacity: 0;
}

html.is-changing .transition-filtertag {
  transition-duration: var(--filtertag-transition-time);
  transition-duration: 0.75s;
  transition-timing-function: var(--filtertag-easing);
}

/* * The animation when filtering cards */
#results-list.is-changing {
  transition: opacity 250ms;
}
#results-list.is-changing .Card {
  transition: transform 150ms;
}
#results-list.is-animating {
  opacity: 0;
}
#results-list.is-animating .Card {
  transform: scale(0.975);
}

.Alert {
  --alert-transition-duration: 0.3s;
  --alert-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  --alert-side-size: var(--s-300);
  --alert-gap: var(--s-050);
  color: var(--c-dark-background);
  padding: 0 var(--s-100);
  border-radius: var(--s-050);
  background: var(--c-state-disabled);
  display: grid;
  column-gap: var(--alert-gap);
  grid-template-columns: var(--alert-side-size) 1fr var(--alert-side-size);
  grid-template-rows: auto 0fr;
  grid-template-areas: "Icon Info Toggle" ". Disclosable .";
  transition: grid-template-rows 0.3s ease;
  transition-delay: 0.3s;
  padding: var(--s-200) var(--s-250);
}
@media screen and (max-width: 1300px) {
  .Alert {
    padding: var(--s-150) var(--s-200);
  }
}
@media screen and (max-width: 740px) {
  .Alert {
    padding: var(--s-100) 1.25rem;
  }
}
@media (max-width: 46.24em) {
  .Alert {
    --alert-side-size: var(--s-200);
  }
  .Alert .Alert__Title {
    margin: 0;
  }
  .Alert svg {
    display: block;
  }
  .Alert .Icon {
    padding: 0.1rem 0;
    transform: scale(0.75);
  }
}
@media (min-width: 46.25em) {
  .Alert {
    align-items: center;
  }
}
.Alert:has(.Alert__Toggle > input:checked) {
  grid-template-rows: auto 1fr;
  transition-delay: 0s;
}
.Alert:has(.Alert__Toggle > input:checked) .Alert__Byline {
  display: initial;
  line-clamp: initial;
  -webkit-line-clamp: initial;
  -webkit-box-orient: initial;
}
.Alert:has(.Alert__Toggle > input:checked) .Alert__Disclosable {
  transition-delay: 0.3s;
  opacity: 1;
}
.Alert__Icon {
  grid-area: Icon;
}
.Alert__Title {
  margin-bottom: var(--s-033);
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: bold;
}
.Alert__Text {
  grid-area: Info;
  color: var(--c-dark-background);
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.Alert__Text a {
  color: var(--c-dark-text);
  text-decoration: underline;
}
.Alert__Byline {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Alert__Toggle {
  grid-area: Toggle;
  justify-self: end;
}
.Alert__Toggle input {
  display: none;
}
.Alert__Toggle .Icon-type-accordion-open svg {
  transition: transform var(--alert-transition-duration) var(--alert-transition-timing-function);
}
.Alert__Toggle:has(> input:checked) .Icon-type-accordion-open svg {
  transform: rotate(180deg);
}
.Alert__Disclosable {
  transition: opacity 300ms;
  transition-delay: 0ms;
  grid-area: Disclosable;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (max-width: 46.24em) {
  .Alert__Disclosable {
    grid-column: 1/-1;
  }
}
.Alert__Disclosable > *:first-child::before {
  content: "";
  display: block;
  height: var(--s-100);
}
.Alert[data-alert-type=soldout] {
  background: var(--c-state-disabled);
}
.Alert[data-alert-type=canceled] {
  background: var(--c-state-error);
}
.Alert[data-alert-type=postponed] {
  background: var(--c-state-warning);
}
.Alert[data-alert-type=info] {
  background: var(--c-state-info);
}

.Banner {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  gap: 1rem;
  padding: 0 var(--grid-padding-inline);
  width: 100%;
  height: var(--header-height, auto);
}
.Banner.visible-banner {
  transform: translateY(0);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.Banner.hidden-banner {
  transform: translateY(-100%);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.HeroPage .Banner {
  z-index: 100;
}
.Banner__left {
  place-self: center self-start;
  display: flex;
  pointer-events: all;
}
.Banner__center {
  place-self: center center;
  display: flex;
  flex-direction: row;
  pointer-events: all;
}
.Banner__right {
  place-self: center self-end;
  display: flex;
  gap: 0.5rem;
  pointer-events: all;
}
.Banner__right .MainMenu__Toggle,
.Banner__right #ToggleSearch,
.Banner__right .Banner__Link {
  z-index: 100000;
  width: 2rem;
  height: 2rem;
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  position: relative;
  transition: all 0.3s ease-out;
}
.Banner__right .MainMenu__Toggle::after,
.Banner__right #ToggleSearch::after,
.Banner__right .Banner__Link::after {
  content: "";
  position: absolute;
  inset: -5px;
  border-radius: 100vh;
  clip-path: circle(0% at 50% 50%);
  background-color: rgba(118, 118, 118, 0.3);
  backdrop-filter: blur(150px);
  transition: all 0.3s ease-out;
  z-index: -1;
}
.Banner__right .MainMenu__Toggle:hover, .Banner__right .MainMenu__Toggle:active, .Banner__right .MainMenu__Toggle:focus,
.Banner__right #ToggleSearch:hover,
.Banner__right #ToggleSearch:active,
.Banner__right #ToggleSearch:focus,
.Banner__right .Banner__Link:hover,
.Banner__right .Banner__Link:active,
.Banner__right .Banner__Link:focus {
  transform: scale(0.9);
  transition: all 0.3s ease-out;
}
.Banner__right .MainMenu__Toggle:hover::after, .Banner__right .MainMenu__Toggle:active::after, .Banner__right .MainMenu__Toggle:focus::after,
.Banner__right #ToggleSearch:hover::after,
.Banner__right #ToggleSearch:active::after,
.Banner__right #ToggleSearch:focus::after,
.Banner__right .Banner__Link:hover::after,
.Banner__right .Banner__Link:active::after,
.Banner__right .Banner__Link:focus::after {
  content: "";
  clip-path: circle(100% at 50% 50%);
}
.Banner .Banner__Item,
.Banner .Banner__Link {
  text-decoration: none;
  margin-bottom: 0;
}
.Banner .Banner__Link.back .Icon,
.Banner .Banner__Link.back svg {
  display: block;
  margin-bottom: 0;
}
.Banner .Banner__Link:has(.KTV) {
  border-radius: 100vw;
}

.KTV {
  --ktv-visual-size: 1.9rem;
  --ktv-visual-padding: 0.125rem;
  --ktv-gap: 0.8rem;
  display: flex;
  align-items: center;
  background-color: var(--c-light-white);
  padding: var(--ktv-visual-padding);
  border-radius: 100vw;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
}
.KTV__Visual {
  --icon-color: var(--c-color-lime);
  --icon-size: 1.5rem;
  display: grid;
  width: var(--ktv-visual-size);
  height: var(--ktv-visual-size);
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 46.24em) {
  .KTV__Visual {
    --ktv-visual-size: 1.5rem;
    --icon-size: 1rem;
  }
}
.KTV__Visual img,
.KTV__Visual video,
.KTV__Visual .Icon {
  display: grid;
  grid-area: 1/-1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  place-content: center center;
}
.KTV__Visual svg {
  display: block;
}
.KTV__Text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  font-feature-settings: "ss02";
  color: var(--c-text-inverted);
  margin-inline: var(--ktv-gap);
}
@media (max-width: 46.24em) {
  .KTV__Text {
    --ktv-gap: 0.5rem;
  }
}

.BannerLogo {
  contain: paint;
  isolation: isolate;
}
.BannerLogo__ClipPath {
  width: 0;
  height: 0;
}
.BannerLogo__Content {
  background-color: #ff1c1c;
  color: #ffffff;
  clip-path: url(#BannerLogo__ClipPath);
  height: 52px;
  width: 82px;
  font-feature-settings: "ss02";
  font-size: 3rem;
  font-weight: 700;
  display: grid;
  place-content: center;
}
.BannerLogo__Foreground {
  mix-blend-mode: darken;
  transform: scale(0.65);
  filter: contrast(110%);
}
.BannerLogo__Foreground, .BannerLogo__Background, .BannerLogo__Hover {
  grid-area: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BannerLogo__Hover {
  transform: translate(0, 100%);
}
.BannerLogo:hover .BannerLogo__Foreground {
  transform: translate(0, -100%);
}
.BannerLogo:hover .BannerLogo__Hover {
  transform: translate(0, 0);
}

.block {
  --block-margin-1: 1rem;
  --block-margin-2: 1.5rem;
  --block-margin-3: 2rem;
  margin-bottom: var(--block-margin-1);
  grid-column: content;
}
.ArticlePage .block p {
  color: var(--c-secondary-text);
}
.ArticlePage .block p strong {
  color: var(--c-text);
  font-weight: normal;
}
.ArticlePage .block h2 p,
.ArticlePage .block h3 p {
  color: var(--c-text);
}
.DefaultPage .block p {
  color: var(--c-secondary-text);
}
.DefaultPage .block p strong {
  color: var(--c-text);
  font-weight: normal;
}
.DefaultPage .block h2 p,
.DefaultPage .block h3 p {
  color: var(--c-text);
}
.block:first-child {
  margin-block-start: 0;
}
.block:last-child {
  margin-block-end: 0;
}
.block-type-text {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.block-type-text p:not(:last-child) {
  margin-bottom: var(--block-margin-1);
}
.block-type-list {
  color: var(--c-secondary-text);
}
.block-type-list ul {
  padding-left: 1rem;
}
.block-type-list ol {
  padding-left: 1.3rem;
}
.block-type-list li {
  padding-left: 0.5em;
}
.block-type-gallery {
  margin-block: var(--block-margin-3);
}
.block-type-gallery .splide__slide {
  aspect-ratio: 3/2;
  overflow: hidden;
}
.block-type-gallery .splide__slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.block-type-gallery .splide__arrow {
  height: 100%;
  width: 50%;
}
.block-type-gallery .splide__arrow svg {
  display: none;
}
.block-type-gallery .splide__arrow--prev {
  left: 0;
}
.block-type-gallery .splide__arrow--next {
  right: 0;
}
@media (max-width: 46.24em) {
  .block-type-gallery .splide__arrow {
    display: none;
  }
}
.block.Lead {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-normal);
  grid-column: wide;
  margin-bottom: var(--block-margin-3);
}
.block-type-video {
  border-radius: var(--border-radius-1);
  overflow: hidden;
  margin-block: var(--block-margin-3);
}
.block-type-heading {
  text-align: left;
  width: 100%;
}
.block-type-heading h2 {
  margin-top: var(--block-margin-3);
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: 600;
}
.block-type-heading h3 {
  margin-top: var(--block-margin-2);
  font-weight: 600;
}
.block-type-image {
  grid-column: wide;
  margin-block: var(--block-margin-3);
}
.block-type-image img {
  width: 100%;
}
.block-type-image:first-child {
  margin-block-start: 0;
}
.block-type-cta {
  text-align: center;
}
.block-type-cta a {
  display: inline-block;
  text-decoration: none;
  margin: var(--block-margin-3) 0;
  color: var(--c-text-inverted);
  background-color: var(--c-background-inverted);
  transition: scale 555ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding: 0.6rem 1.6rem;
  border-radius: 100vh;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (min-width: 61.25em) {
  .block-type-cta a {
    padding: 0.6rem 2rem;
  }
}
.block-type-cta a a {
  text-decoration: none;
}
.block-type-cta a:hover {
  transition: scale 222ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  scale: 1.1;
}
.block-type-links {
  margin-block: var(--block-margin-3);
}
.block-type-links > p {
  margin-bottom: var(--block-margin-1);
}
.block-type-links ul {
  list-style-type: none;
}
.block-type-links li {
  padding-block: 0.25rem;
  border-top: 1px solid var(--c-light-30);
}
.block-type-links li:hover a {
  background: var(--c-blurry-dark-55);
  transition: background-color 150ms ease-in-out;
}
.block-type-links a {
  padding: 1rem;
  text-decoration: none;
  color: inherit;
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  border-radius: var(--border-radius-1);
  transition: background-color 150ms ease-in-out;
}
.block-type-links a .block-link-title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.block-type-links a .block-link-info {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
  color: var(--c-secondary-text);
  margin-left: 1rem;
  white-space: nowrap;
  margin-top: 0.2rem;
}
.block-type-links .link-type-url::before,
.block-type-links .link-type-page::before {
  content: url("/assets/icons/control-link-url.png");
  margin-right: 1rem;
}
.block-type-links .link-type-file::before {
  content: url("/assets/icons/control-link-document.png");
  margin-right: 1rem;
}
.block-type-two-column {
  grid-column: wide;
  display: grid;
  grid-gap: 2rem;
  margin-block: var(--block-margin-3);
}
@media (min-width: 46.25em) {
  .block-type-two-column {
    grid-template-columns: 1fr 1fr;
  }
}
.block-type-two-column img {
  width: 100%;
}
.block-type-two-column .block__column {
  display: grid;
  grid-gap: 1rem;
  height: min-content;
}
.block-type-accordion {
  grid-column: wide;
  margin-top: var(--block-margin-3);
}
.block-type-accordion + .block-type-accordion {
  margin-top: 0;
}
.block-type-accordion:has(+ :not(.block-type-accordion)) {
  margin-bottom: var(--s-300);
}
.block-type-accordion .Accordion {
  background-color: var(--c-blurry-dark);
  padding: 2.5rem;
  border-radius: var(--border-radius-1);
  background-image: none;
  appearance: none;
  -webkit-appearance: none;
}
@media (max-width: 46.24em) {
  .block-type-accordion .Accordion {
    padding: 1rem;
  }
}
.block-type-accordion .Accordion summary {
  background-image: none;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  list-style: none;
}
.block-type-accordion .Accordion summary::marker {
  content: "";
}
.block-type-accordion .Accordion summary::-webkit-details-marker {
  display: none;
}
.block-type-accordion .Accordion summary h2 {
  display: flex;
  gap: var(--s-075);
  flex-direction: column;
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: 600;
}
.block-type-accordion .Accordion summary h2 .Accordion__Byline {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: var(--c-text-muted);
}
.block-type-accordion .Accordion__content {
  margin-top: var(--block-margin-3);
}
.block-type-accordion .Accordion__Icon svg {
  transition: transform 300ms ease-in-out;
}
.block-type-accordion .Accordion[open] svg {
  transform: rotate(180deg);
  transition: transform 300ms ease-in-out;
}

.Card {
  --card-background: var(--c-blurry-dark);
  --card-list-gap: var(--s-075);
  --card-border-radius: 20px;
  --card-tag-margin: var(--s-050);
  --card-tag-margin: 0.66rem;
  aspect-ratio: var(--teaser-ratio, 6/7);
  height: 100%;
  overflow: hidden;
  border-radius: var(--card-border-radius);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "date meta ticket" "content content content";
  /******** VARIANTS ********/
  /******** MODIFIERS ********/
}
.Card__Meta {
  padding: var(--card-tag-margin);
  padding-left: var(--card-tag-margin);
  grid-area: meta;
  z-index: 30;
  overflow: auto;
  display: flex;
  gap: var(--s-033);
}
.Card__Meta {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.Card__Meta::-webkit-scrollbar {
  display: none;
}
.Card__Date {
  padding: var(--card-tag-margin);
  padding-right: 0;
  grid-area: date;
  z-index: 30;
}
.Card__Date + .Card__Meta {
  padding-left: var(--s-033);
}
.Card__Time {
  display: none;
  z-index: 30;
}
.Card__Time + .Card__Meta {
  padding-left: var(--s-033);
}
.Card__Ticket {
  grid-area: ticket;
  z-index: 30;
  padding: var(--spacing-padding-small);
}
.DashboardPage .Card__Ticket {
  padding: 0;
}
.Card__Ticket .TicketLink__Text {
  display: none;
}
.Card__Ticket .TicketLink__Icon {
  display: block;
  opacity: 0;
  transition: opacity 333ms ease-out, scale 333ms cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color 666ms ease-out;
  --icon-color: var(--c-ticket);
}
.Card__Ticket .TicketLink__Icon:hover {
  --icon-color: var(--c-ticket-muted);
  scale: 1.1;
}
.Card:hover .TicketLink__Icon {
  opacity: 1;
}
.Card__Text {
  padding: var(--spacing-padding-large) var(--spacing-padding-medium);
  grid-area: content;
  text-align: center;
  align-self: end;
  z-index: 10;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (max-width: 46.24em) {
  .Card__Text {
    padding: var(--spacing-padding-medium);
  }
}
.Card__Title {
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: var(--fw-bold);
}
@media (max-width: 46.24em) {
  .Card__Title {
    font-size: var(--fs-4);
    line-height: var(--flh-4);
    letter-spacing: var(--fls-4);
    font-weight: var(--fw-bold);
  }
}
.Card__Title .Act__country-code {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: var(--c-light-60);
}
.Card__Byline {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  margin-top: var(--s-075);
  color: var(--c-light-60);
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Card__Link {
  grid-row: 1/-1;
  grid-column: 1/-1;
  display: block;
  z-index: 20;
}
.Card__Image {
  grid-row: 1/-1;
  grid-column: 1/-1;
}
.Card__Image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--focus);
}
.Card__Image::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.125);
  background: linear-gradient(to top, var(--c-dark-off-black) 0%, rgba(0, 0, 0, 0.125) 50%, rgba(0, 0, 0, 0.125) 100%);
  mix-blend-mode: multiply;
}
.Card__Backdrop {
  display: none;
}
.Card.text-small .Card__Text {
  padding: var(--s-100);
}
.Card.text-small .Card__Title {
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.Card.text-small .Card__Byline {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.Card.align-left .Card__Title,
.Card.align-left .Card__Byline {
  text-align: left;
}
.Card.align-left .Card__Title {
  font-weight: bold;
}
.Card.vertically-centered .Card__Text {
  display: flex;
  align-self: center;
  justify-self: center;
  padding: 0 var(--s-200);
}
.Card.vertically-centered .Card__Title {
  margin-block: 0;
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: var(--fw-bold);
}
.Card.overlay-solid .Card__Image::before {
  background: rgba(0, 0, 0, 0.2);
}
.Card.text-inverted .Card__Text {
  color: var(--c-dark-off-black);
}

/******** EXEPTIONS ********/
.DashboardPage .Card__Title {
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: var(--fw-bold);
  margin-bottom: var(--spacing-padding-small);
}
.DashboardPage .Card__Text {
  padding-bottom: var(--spacing-padding-medium);
}

.EventsPage:not(.list-view-is-active) .Card-Event .Card__Text,
.ArchivePage:not(.list-view-is-active) .Card-Event .Card__Text {
  transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.EventsPage:not(.list-view-is-active) .Card-Event:hover .Card__Text,
.ArchivePage:not(.list-view-is-active) .Card-Event:hover .Card__Text {
  transform: translateY(-0.5rem);
}

.EventsSlider .Card__Title {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-bold);
}
@media (max-width: 46.24em) {
  .EventsSlider .Card__Title {
    margin-bottom: var(--spacing-padding-small);
  }
}
.EventsSlider .Card__Title .Act__country-code {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
}
.EventsSlider .Card__Byline {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}

/*********************************/
/******** LIST VIEW ITEM ********/
/*******************************/
body.EventsPage.list-view-is-active .Card,
body.ArchivePage.list-view-is-active .Card {
  --card-border-radius: var(--s-100);
  --card-background-transition: background-color 666ms ease;
  aspect-ratio: unset;
  grid-template-rows: auto;
  grid-template-columns: 12vw 8vw 8vw 10fr 5fr 8vw;
  grid-template-areas: "date time image content meta ticket";
  isolation: isolate;
  /******** VARIANTS ********/
  /******** OVERRIDES ********/
}
body.EventsPage.list-view-is-active .Card:hover,
body.ArchivePage.list-view-is-active .Card:hover {
  --card-background-transition: background-color 0.2s ease;
  --card-background: var(--c-light-darkgrey);
}
body.EventsPage.list-view-is-active .Card__Date,
body.ArchivePage.list-view-is-active .Card__Date {
  grid-area: date;
  align-content: center;
  margin-right: var(--card-list-gap);
  padding: var(--card-tag-margin);
  background: var(--card-background);
  border-radius: var(--card-border-radius);
  transition: var(--card-background-transition);
}
body.EventsPage.list-view-is-active .Card__Time,
body.ArchivePage.list-view-is-active .Card__Time {
  display: block;
  grid-area: time;
  align-content: center;
  border-radius: var(--card-border-radius);
  margin-right: var(--card-list-gap);
  background: var(--card-background);
  transition: var(--card-background-transition);
}
body.EventsPage.list-view-is-active .Card__Image,
body.ArchivePage.list-view-is-active .Card__Image {
  aspect-ratio: 3/2;
  grid-area: image;
  align-self: stretch;
  width: 100%;
  height: 100%;
  background: none;
  border-top-left-radius: var(--card-border-radius);
  border-bottom-left-radius: var(--card-border-radius);
  overflow: hidden;
}
body.EventsPage.list-view-is-active .Card__Image picture,
body.EventsPage.list-view-is-active .Card__Image picture img,
body.ArchivePage.list-view-is-active .Card__Image picture,
body.ArchivePage.list-view-is-active .Card__Image picture img {
  object-fit: cover;
  object-position: var(--focus);
}
body.EventsPage.list-view-is-active .Card__Image::before,
body.ArchivePage.list-view-is-active .Card__Image::before {
  content: none;
}
body.EventsPage.list-view-is-active .Card__Link,
body.ArchivePage.list-view-is-active .Card__Link {
  background: none;
}
body.EventsPage.list-view-is-active .Card__Meta,
body.ArchivePage.list-view-is-active .Card__Meta {
  align-self: stretch;
  align-content: center;
  align-items: center;
  background: var(--card-background);
  transition: var(--card-background-transition);
}
body.EventsPage.list-view-is-active .Card__Ticket,
body.ArchivePage.list-view-is-active .Card__Ticket {
  z-index: 100;
  background: var(--card-background);
  transition: var(--card-background-transition);
  padding-inline: var(--spacing-padding-medium);
}
body.EventsPage.list-view-is-active .Card__Ticket .TicketLink,
body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: end;
  text-decoration: none;
}
body.EventsPage.list-view-is-active .Card__Ticket .TicketLink__Icon,
body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink__Icon {
  align-self: center;
  justify-self: center;
  display: none;
}
@media (max-width: 46.24em) {
  body.EventsPage.list-view-is-active .Card__Ticket .TicketLink__Icon,
  body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink__Icon {
    display: inline-block;
    opacity: 1;
  }
}
body.EventsPage.list-view-is-active .Card__Ticket .TicketLink__Text,
body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink__Text {
  display: inline-block;
  transition: all 333ms ease-out;
}
body.EventsPage.list-view-is-active .Card__Ticket .TicketLink__Text:hover,
body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink__Text:hover {
  opacity: 1;
  transition: all 111ms ease-out;
  background-color: var(--c-ticket-muted);
  scale: 1.1;
}
@media (max-width: 46.24em) {
  body.EventsPage.list-view-is-active .Card__Ticket .TicketLink__Text,
  body.ArchivePage.list-view-is-active .Card__Ticket .TicketLink__Text {
    display: none;
  }
}
body.EventsPage.list-view-is-active .Card__Title,
body.ArchivePage.list-view-is-active .Card__Title {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-bold);
}
body.EventsPage.list-view-is-active .Card__Byline,
body.ArchivePage.list-view-is-active .Card__Byline {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
body.EventsPage.list-view-is-active .Card__Text,
body.ArchivePage.list-view-is-active .Card__Text {
  text-align: left;
  align-content: start;
  padding: var(--s-100) var(--s-150);
  background: var(--card-background);
  transition: var(--card-background-transition);
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 61.24em) {
  body.EventsPage.list-view-is-active .Card__Text,
  body.ArchivePage.list-view-is-active .Card__Text {
    padding: var(--s-100);
  }
}
body.EventsPage.list-view-is-active .Card.date-hidden .Card__Date,
body.ArchivePage.list-view-is-active .Card.date-hidden .Card__Date {
  visibility: hidden;
}
body.EventsPage.list-view-is-active .Card__Time, body.EventsPage.list-view-is-active .Card__Date,
body.ArchivePage.list-view-is-active .Card__Time,
body.ArchivePage.list-view-is-active .Card__Date {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.EventsPage.list-view-is-active .Card__Date .capsule,
body.ArchivePage.list-view-is-active .Card__Date .capsule {
  background: none;
}
@media (min-width: 61.25em) {
  body.EventsPage.list-view-is-active .Card__Time .capsule,
  body.ArchivePage.list-view-is-active .Card__Time .capsule {
    background: none;
  }
}
@media (max-width: 61.24em) {
  body.EventsPage.list-view-is-active .Card,
  body.ArchivePage.list-view-is-active .Card {
    --card-border-radius: 0.5rem;
    grid-template-columns: 20vw min-content 4fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "date content content ticket" "date time meta ticket";
  }
  body.EventsPage.list-view-is-active .Card__Image,
  body.ArchivePage.list-view-is-active .Card__Image {
    display: none;
  }
  body.EventsPage.list-view-is-active .Card__Meta, body.EventsPage.list-view-is-active .Card__Ticket, body.EventsPage.list-view-is-active .Card__Time, body.EventsPage.list-view-is-active .Card__Text,
  body.ArchivePage.list-view-is-active .Card__Meta,
  body.ArchivePage.list-view-is-active .Card__Ticket,
  body.ArchivePage.list-view-is-active .Card__Time,
  body.ArchivePage.list-view-is-active .Card__Text {
    background-color: unset;
  }
  body.EventsPage.list-view-is-active .Card__Backdrop,
  body.ArchivePage.list-view-is-active .Card__Backdrop {
    display: block;
    grid-row: 1/-1;
    grid-column: 2/-1;
    background: var(--card-background);
    z-index: -1;
    border-radius: var(--card-border-radius);
  }
  body.EventsPage.list-view-is-active .Card__Time,
  body.ArchivePage.list-view-is-active .Card__Time {
    margin: var(--s-100) 0 var(--s-100) var(--s-100);
    margin-top: 0;
  }
}
@media (max-width: 46.24em) {
  body.EventsPage.list-view-is-active .Card,
  body.ArchivePage.list-view-is-active .Card {
    --card-border-radius: 0.5rem;
    grid-template-columns: 25vw min-content 4fr 1fr;
  }
}

.ClaimWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  contain: paint;
  height: 80svh;
}

.Claim {
  font-size: var(--fs-5);
  line-height: var(--flh-5);
  letter-spacing: var(--fls-5);
  font-weight: 600;
  perspective: 1200px;
  line-height: 1;
  color: var(--c-primary-text);
  margin-top: 1em;
  position: relative;
  text-align: center;
  transition: filter 1s;
}

.ClaimPart:not(.static) {
  position: absolute;
  left: 0;
  bottom: 100%;
  right: 0;
  margin-bottom: 0;
  margin-top: 50px;
  text-align: center;
  opacity: 0;
}
.ClaimPart:first-of-type {
  opacity: 1;
}

body[data-dash-filter-overlay-state=open] {
  overflow: hidden;
}

.DashboardPage__FilterTags {
  height: 100%;
}

.Dashboard__FilterOverlay__Content {
  height: 100%;
}
.Dashboard__FilterOverlay__Content .FilterList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-padding-small);
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: var(--spacing-padding-extra-large);
}
body[data-dash-filter-overlay-state=open] .Dashboard__FilterOverlay .Filtertag {
  --duration: 333ms;
  --separation: 66ms;
  --delay: var(--promoted-index);
  opacity: 0;
  transform: translateY(3em);
  animation-name: enter-filtertag;
  animation-duration: var(--duration);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.53, -0.46, 0.71, 1.63);
  animation-delay: calc(var(--delay) * var(--separation));
  --delay: var(--index);
}
.Dashboard .FilterOverlayToggle {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: grid;
  view-transition-name: filter-toggle;
}
.Dashboard .FilterOverlayToggle__Data {
  display: none;
}
.Dashboard .FilterOverlayToggle__Icon {
  grid-area: 1/-1;
  align-self: start;
  justify-self: end;
  margin: 0.5rem;
}
@media (max-width: 46.24em) {
  .Dashboard .FilterOverlayToggle__Icon {
    align-self: center;
    margin-block: 0;
    margin-right: 1rem;
  }
  .Dashboard .FilterOverlayToggle__Icon svg {
    display: block;
  }
}
.Dashboard .FilterOverlayToggle__Title {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  grid-area: 1/-1;
  align-self: center;
  justify-self: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
.Dashboard .FilterOverlayToggle .conjunction {
  color: #888888;
}
.Dashboard__FilterOverlay {
  display: none;
  overflow: auto;
}
.Dashboard__FilterOverlay {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.Dashboard__FilterOverlay::-webkit-scrollbar {
  display: none;
}
body[data-dash-filter-overlay-state=open] .Dashboard__FilterOverlay {
  cursor: pointer;
  display: block;
  position: fixed;
  inset: 0;
  z-index: 10000;
  color: white;
}

@keyframes enter-filtertag {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
body[data-responsive-overlay-state=open] .Filtertag .capsule,
body[data-dash-filter-overlay-state=open] .Filtertag .capsule {
  font-size: var(--fs-4);
  padding: var(--s-400) var(--s-700);
  border-radius: 100vw;
}
@media (max-width: 46.24em) {
  body[data-responsive-overlay-state=open] .Filtertag .capsule,
  body[data-dash-filter-overlay-state=open] .Filtertag .capsule {
    padding: var(--s-150) var(--s-300);
  }
}

.FilterPage__FilterTags,
.DashboardPage__FilterTags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--s-100) auto;
}
@media (min-width: 46.25em) {
  .FilterPage__FilterTags,
  .DashboardPage__FilterTags {
    margin: 4rem auto;
  }
}
.FilterPage__FilterTags.show-all-filters .Filtertag-Link,
.DashboardPage__FilterTags.show-all-filters .Filtertag-Link {
  display: flex;
}
.FilterPage__FilterTags .FilterList,
.DashboardPage__FilterTags .FilterList {
  grid-column: tags;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-inline: var(--s-100);
}
@media (max-width: 46.24em) {
  .FilterPage__FilterTags .FilterList,
  .DashboardPage__FilterTags .FilterList {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }
}
.FilterPage__FilterTags .FilterList .Filtertag,
.DashboardPage__FilterTags .FilterList .Filtertag {
  max-width: 100%;
}
.FilterPage__FilterTags .FilterList .Filtertag-Link,
.DashboardPage__FilterTags .FilterList .Filtertag-Link {
  max-width: 100%;
  flex-direction: row;
}
.FilterPage__FilterTags .FilterList .Filtertag-Link__Text,
.DashboardPage__FilterTags .FilterList .Filtertag-Link__Text {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Filtertag-Link {
  --filtertag-easing: cubic-bezier(0.53, -0.46, 0.71, 1.63);
  --filtertag-transition-time: 0.2s;
  --filtertag-icon-size: 0.75em;
  margin: 0.2rem;
  white-space: nowrap;
  text-decoration: none;
  transition: background-color 0.2s var(--filtertag-easing);
  /************** VARIANTS **************/
}
.Filtertag-Link[data-active=""], .Filtertag-Link[data-promoted=""] {
  display: none;
}
.more .Filtertag-Link, .Filtertag-Link[data-active="1"], .Filtertag-Link[data-promoted="1"] {
  display: inline-flex;
}
.Filtertag-Link:hover {
  background-color: var(--c-brand-primary);
}
.Filtertag-Link__Close {
  --icon-size: var(--filtertag-icon-size);
  opacity: 0;
  display: inline-flex;
  align-items: center;
  width: 0;
  cursor: pointer;
  transition: opacity var(--filtertag-transition-time) var(--filtertag-easing), width var(--filtertag-transition-time) var(--filtertag-easing), margin-left var(--filtertag-transition-time) var(--filtertag-easing);
}
.Filtertag-Link__Close .Icon {
  display: inline-flex;
}
.Filtertag-Link[data-active="1"] {
  background-color: #ff1c1c;
}
.Filtertag-Link.leaving.activating {
  background-color: #ff1c1c;
}
.Filtertag-Link[data-active="1"] .Filtertag-Link__Close {
  opacity: 1;
  margin-left: var(--gap);
  width: var(--filtertag-icon-size);
}
.Filtertag-Link.leaving.activating .Filtertag-Link__Close {
  opacity: 1;
  margin-left: var(--gap);
  width: var(--filtertag-icon-size);
}
.Filtertag-Link.leaving.deactivating .Filtertag-Link__Close {
  opacity: 0;
  margin-left: 0;
  width: 0;
}
.Filtertag-Link.more, .Filtertag-Link.outline {
  color: var(--c-light-white);
  background-color: var(--c-background);
  outline: 2px solid var(--c-light-lightgrey);
}
.Filtertag-Link.projektraum {
  color: var(--c-light-white);
  border-radius: 50em;
  border: 2px solid transparent;
  background: linear-gradient(var(--c-dark-black), var(--c-dark-black)) padding-box, linear-gradient(45deg, var(--c-light-lightgrey) 0%, var(--c-light-lightgrey) 33%, rgb(151, 71, 255) 88%, rgb(79, 54, 111) 100%) border-box;
}
.Filtertag-Link.projektraum.capsule {
  padding: calc(var(--s-075) - 2px) calc(var(--s-150) - 2px);
}
.Filtertag-Link.archiv {
  color: var(--c-light-white);
  background-color: var(--c-blurry-dark);
}

.FooterDisclamer {
  --color: var(--c-light-30);
  display: flex;
  justify-content: space-between;
  color: var(--color);
  margin-inline: var(--spacing-padding-large);
  margin-top: var(--page-padding-bottom);
  padding-bottom: 0.5rem;
}
@media (max-width: 46.24em) {
  .FooterDisclamer {
    flex-direction: column-reverse;
    gap: var(--s-050);
    align-items: center;
    padding-bottom: var(--s-100);
  }
}
.FooterDisclamer__Item {
  display: flex;
  gap: var(--gap);
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.FooterDisclamer__Item a {
  text-decoration: none;
}
.FooterDisclamer__Link {
  color: var(--color);
}

.Icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.Icon svg.outline * {
  stroke: var(--icon-color, #ffffff);
}
.Icon svg .stroke {
  stroke: var(--icon-color, #ffffff);
}
.Icon svg.solid * {
  fill: var(--icon-color, #ffffff);
}
.Icon svg .fill {
  fill: var(--icon-color, #ffffff);
}
.Icon svg .background {
  fill: var(--icon-background-color, #ffffff);
}

svg {
  height: var(--icon-size, initial);
  width: var(--icon-size, initial);
}

.MainMenu__NavList {
  padding-block: 1rem;
  padding-top: 4rem;
}
@media (max-width: 46.24em) {
  .MainMenu__NavList {
    padding-block: 1rem;
    padding-top: 6rem;
  }
}
.MainMenu .SubMenu__NavContent {
  display: none;
}
.MainMenu .SubMenu__NavContent.submenu-open {
  margin-bottom: 1rem;
}
.MainMenu a {
  text-decoration: none;
}
.MainMenu__Toggle {
  z-index: 100000;
}
.MainMenu__NavContent {
  border-radius: var(--border-radius-1);
  min-width: 300px;
  right: 0.5rem;
  top: 1.5rem;
  height: 0;
  overflow: hidden;
  position: fixed;
  background: var(--c-dark-off-black);
  color: white;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
}
.MainMenu__NavContent.main-nav-open {
  transform: scaleY(100%);
  height: min-content;
  overflow-y: auto;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (max-width: 46.24em) {
  .MainMenu__NavContent.main-nav-open {
    height: 100svh;
    border-radius: 0;
  }
}
.MainMenu__NavContent.main-nav-open .NavItem__Main {
  opacity: 0;
  animation: nav-item-fade-in 0.3s ease-in-out forwards;
  animation-delay: calc(50ms * var(--index-main));
}
@keyframes nav-item-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 46.24em) {
  .MainMenu__NavContent {
    width: 100%;
    inset: 0;
  }
}
.MainMenu .NavItem {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: var(--c-secondary-text);
  overflow: hidden;
  width: calc(100% - 20px);
  display: block;
  margin-inline: 10px;
  border-radius: 11px;
}
@media (max-width: 46.24em) {
  .MainMenu .NavItem {
    font-size: var(--fs-2);
    line-height: var(--flh-2);
    letter-spacing: var(--fls-2);
    font-weight: var(--fw-normal);
  }
}
.MainMenu .NavItem__Arrow {
  --icon-size: 20px;
  position: absolute;
  transform: rotate(90deg);
  right: 1rem;
}
.MainMenu .NavItem__Inner {
  width: 100%;
  display: block;
  padding: 0.4rem 0.6rem 0.4rem;
  margin: 0.3rem 0 calc(0.3rem - 0.5px);
  display: inline-flex;
  align-items: baseline;
  position: relative;
}
.MainMenu .NavItem__Inner:hover {
  background: var(--c-light-10);
  border-radius: 4px;
}
.MainMenu .NavItem__Inner::after {
  content: "";
  position: absolute;
  top: -0.3rem;
  left: 0.6rem;
  right: 0.6rem;
  height: 1px;
  background-color: var(--c-light-30);
}
.MainMenu .NavItem__SubMenu .NavItem__Inner {
  margin: 0.1rem 0;
}
.MainMenu .NavItem__SubMenu .NavItem__Inner::after {
  content: none;
}
.MainMenu .NavItem__Icon {
  --size: 0.95rem;
  transform: translateY(-0.01em);
}
.MainMenu .NavItem__Icon svg {
  margin-right: var(--s-065);
  display: block;
  width: var(--size);
  height: var(--size);
}
.MainMenu .NavItem__Icon svg * {
  fill: var(--c-light-off-white);
}
.MainMenu .NavItem__Main {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-normal);
  color: var(--c-primary-text);
}
@media (max-width: 46.24em) {
  .MainMenu .NavItem__Main {
    font-size: var(--fs-3);
    line-height: var(--flh-3);
    letter-spacing: var(--fls-3);
    font-weight: 400;
  }
}

.Notification {
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 21em;
  z-index: 1000;
  background-color: var(--c-light-20);
  display: grid;
  grid-template-areas: "image text time";
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.9rem;
  margin: var(--s-150) var(--s-150);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  backdrop-filter: blur(100px);
}
.Notification:not(.closed) {
  transform: translateY(-200%);
  animation: fadeIn 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 1s forwards;
}
@media (max-width: 46.24em) {
  .Notification {
    margin: var(--s-150) var(--s-100);
  }
}
.Notification.closed {
  transform: translateY(-200%);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
@keyframes fadeIn {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0);
  }
}
.Notification__Link {
  position: absolute;
  inset: 0;
  cursor: pointer;
}
.Notification__Image {
  grid-area: image;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 0.5rem;
  background-color: var(--c-light-10);
  font-size: 1.375rem;
}
.Notification__Text {
  grid-area: text;
  font-size: 0.75rem;
  color: var(--c-secondary-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.Notification__Text h3 {
  font-weight: bold;
  letter-spacing: 0.03em;
  color: var(--c-text);
}
.Notification__Text p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.Notification__Time {
  grid-area: time;
  font-size: 0.625rem;
  margin-top: 0.2rem;
  color: var(--c-secondary-text);
}
.Notification__CloseButton {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-60%, 60%);
  border-radius: 50%;
  border: 1px solid var(--c-light-10);
  height: 1.1rem;
  width: 1.1rem;
  background-color: rgb(70, 70, 70);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-text);
  background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' height='200px' width='200px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512' xml:space='preserve' stroke='%23ffffff'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg%3E%3Cg%3E%3Cpolygon points='512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 '%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.Radio {
  height: 100%;
  width: 100%;
  padding: var(--spacing-padding-small);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.Dashboard__Item:has(.Radio) {
  --bg: rgba(42, 42, 42, 0.6);
}
.Dashboard__Item .Radio {
  border-radius: var(--border-radius);
  overflow: hidden;
}
.Radio__Visual {
  display: flex;
  align-items: end;
  justify-content: center;
  overflow: hidden;
}
.Radio__Logo {
  --icon-size: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--icon-size, 6rem);
  height: var(--icon-size, 6rem);
  background: white;
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 46.25em) and (max-width: 81.24em) {
  .Radio__Logo {
    --icon-size: 4rem;
  }
}
.Radio__Logo img {
  object-fit: contain;
  scale: 0.33;
  rotate: calc(var(--angle) * 1deg);
  transition: scale 0.1s;
}
.Radio__Info {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  min-width: 100%;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.03em;
  line-height: var(--flh-1);
}
.Radio__Info .talent {
  font-weight: var(--fw-bold);
}
.Radio__Info .title {
  color: var(--c-light-60);
}
.Radio__Info .talent,
.Radio__Info .title {
  min-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Radio__Controls {
  display: flex;
  gap: 1rem;
}
.Radio__Control {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Radio__Control.OutboundLink {
  justify-content: end;
}
.Radio__Control .Icon {
  --icon-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Radio__Control.Play .Icon, .Radio__Control.Pause .Icon {
  --icon-size: 1.6rem;
}
@media (min-width: 81.25em) {
  .Radio__Control.Play .Icon, .Radio__Control.Pause .Icon {
    --icon-size: 2.4rem;
  }
}
.Radio__Control.OutboundLink .Icon {
  --icon-size: 1rem;
  --icon-color: var(--c-text-muted);
}
.Radio__Control .Icon svg {
  fill: white;
}
.is-playing .Radio__Control.Play {
  display: none;
}
.is-paused .Radio__Control.Pause {
  display: none;
}
.Radio__Visualizer {
  display: flex;
  justify-content: start;
  align-items: center;
}
.Radio__Visualizer .inner {
  --size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size);
  width: var(--size);
}
.Radio__Visualizer .bar {
  --bar-height: 0;
  width: 2px;
  border-radius: 100vw;
  background-color: var(--c-text-muted);
}
.Radio.is-paused .Radio__Visualizer .bar {
  transition: height 0.5s ease;
  height: 2px;
}
.Radio.is-playing .Radio__Visualizer .bar {
  transition-duration: 0;
  height: calc(var(--bar-height) * 200%);
}
.Radio.is-playing .Radio__Visualizer .bar:nth-child(1), .Radio.is-playing .Radio__Visualizer .bar:nth-child(4) {
  scale: 1 0.5;
}

/*        FALLBACK IOS    */
.css-fallback-animation.Radio.is-playing .Radio__Visualizer .bar {
  transition: none;
  scale: unset !important;
  animation: pulse 1.2s infinite ease-in-out;
}
.css-fallback-animation.Radio.is-playing .Radio__Visualizer .bar:nth-child(1) {
  animation-delay: 0s;
  animation-duration: 0.9s;
}
.css-fallback-animation.Radio.is-playing .Radio__Visualizer .bar:nth-child(2) {
  animation-delay: 0.1s;
  animation-duration: 0.6s;
}
.css-fallback-animation.Radio.is-playing .Radio__Visualizer .bar:nth-child(3) {
  animation-delay: 0.2s;
  animation-duration: 0.7s;
}
.css-fallback-animation.Radio.is-playing .Radio__Visualizer .bar:nth-child(4) {
  animation-delay: 0.3s;
  animation-duration: 0.8s;
}

@keyframes bounce {
  0%, 100% {
    height: 20%;
  }
  50% {
    height: 100%;
  }
}
@keyframes pulse {
  0% {
    height: 20%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 20%;
  }
}
html:has(.SearchModal.open) {
  overscroll-behavior: none;
}

body {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
body::-webkit-scrollbar {
  display: none;
}

body:has(.SearchModal.open) {
  overflow: hidden;
}

.SearchModal {
  --grid-row-gap: 1rem;
  --icon-color: #ffffff;
  --search-result-size: 3rem;
  isolation: isolate;
  display: none;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  overflow-y: auto;
  position: fixed;
  inset: 0 0 auto;
  z-index: 1000;
  margin: 0;
  padding: var(--s-175) 0 0;
  backdrop-filter: var(--effect-event-bg-blur);
  -webkit-backdrop-filter: var(--effect-event-bg-blur);
}
@supports (-webkit-touch-callout: none) {
  .SearchModal {
    /* CSS specific to iOS devices */
    height: 100%;
    height: var(--visual-viewport-height);
  }
}
@supports not (-webkit-touch-callout: none) {
  .SearchModal {
    /* CSS for other than iOS devices */
    bottom: 0;
  }
}
.SearchModal.open {
  display: grid;
  overscroll-behavior: contain;
  grid-template-rows: auto 1fr;
  opacity: 1;
}
@media (max-width: 61.24em) {
  .SearchModal.open {
    display: flex;
    flex-direction: column;
  }
  .SearchModal.open .SearchModal__InputWrapper {
    width: calc(100% - 2 * var(--s-100));
  }
}
@media (min-width: 61.25em) {
  .SearchModal.open {
    grid-template-columns: var(--grid-default-page);
  }
}
.SearchModal {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.SearchModal::-webkit-scrollbar {
  display: none;
}

.SearchModal {
  -webkit-overflow-scrolling: touch;
}
.SearchModal__InputWrapper {
  --search-icon-padding: 0.65rem;
  grid-column: content;
  display: flex;
  align-items: center;
  gap: var(--s-050);
  background-color: rgba(255, 255, 255, 0.1);
  outline: 1px solid rgba(255, 255, 255, 0.2901960784);
  border-radius: 100vw;
  margin: auto;
  transition-property: width, transform;
  transition-duration: 333ms;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-behavior: allow-discrete;
  width: 100%;
  transform: translateX(0);
}
@starting-style {
  .SearchModal__InputWrapper {
    width: 10%;
    transform: translateX(30vw);
  }
}
.SearchModal__Icon {
  margin-left: var(--search-icon-padding);
  transform: translateX(0);
}
.SearchModal__Icon .Icon-type-control-search {
  transform: translate3d(0, 0.15em, 0);
}
.SearchModal__Input {
  appearance: none;
  font-size: 16px !important;
  outline: none;
  border: none;
  padding: var(--s-100) 0;
  color: white;
  background-color: transparent;
  flex: 1;
  transform: translate3d(0, 0.05em, 0);
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: 600;
}
.SearchModal__Input::placeholder {
  color: var(--c-light-50);
}
.SearchModal__CloseButton {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  margin-right: var(--search-icon-padding);
  aspect-ratio: 1/1;
  transition: all 333ms cubic-bezier(0.19, 1, 0.22, 1);
}
.SearchModal__CloseButton svg {
  display: block;
}
.SearchModal__CloseButton:focus {
  scale: 1.05;
  outline: 1px solid rgba(255, 255, 255, 0.2901960784);
  background-color: var(--c-light-10);
  backdrop-filter: blur(15px);
  border-radius: 100vw;
}
.SearchModal__Output {
  padding: 0 0 var(--s-200);
}
@media (max-width: 61.24em) {
  .SearchModal__Output {
    margin-inline: 2.5rem;
    flex: 1;
  }
}
@media (min-width: 61.25em) {
  .SearchModal__Output {
    grid-column: content;
  }
}
.SearchModal__SectionTitle {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: #cacaca;
  margin-top: var(--spacing-padding-large);
  margin-bottom: var(--spacing-padding-medium);
}
.SearchModal__SectionTitle:not(:first-of-type) {
  margin-top: 2rem;
}
.SearchModal__Result {
  padding-block: 0.8rem;
}
@media (max-width: 61.24em) {
  .SearchModal__Result {
    padding-block: 0.5rem;
  }
}
.SearchModal__ResultContent {
  transition: transform 150ms ease-in-out;
  transform-origin: calc(0.5 * var(--search-result-size)) center;
}
.SearchModal__ResultContent:hover, .SearchModal__ResultContent:focus, .SearchModal__ResultContent:focus-within {
  transform: scale(1.02);
}
.SearchModal__ResultImage {
  border-radius: 50%;
  overflow: hidden;
  width: var(--search-result-size);
  height: var(--search-result-size);
  place-self: center;
}
.SearchModal__ResultImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.SearchModal__ResultLink {
  --gap: 0.8rem;
  display: grid;
  grid-template-columns: var(--search-result-size) 1fr;
  gap: var(--gap);
  text-decoration: none;
  transition: all 333ms cubic-bezier(0.19, 1, 0.22, 1);
  outline: 5px solid transparent;
}
.SearchModal__ResultLink:focus {
  box-shadow: none;
  outline: 10px solid var(--c-light-10);
  background-color: var(--c-light-10);
  backdrop-filter: blur(15px);
  border-radius: 100vw;
}
@media (min-width: 61.25em) {
  .SearchModal__ResultLink {
    --gap: 1.3rem;
  }
}
.SearchModal__ResultLink.DefaultResult .SearchModal__ResultContent {
  grid-column: 1/-1;
}
.SearchModal__ResultLink.DefaultResult .SearchModal__ResultImage {
  display: none;
}
.SearchModal__ResultHeading {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: 600;
  color: var(--c-text);
  margin-block: 0;
}
.SearchModal__ResultHeading time {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: var(--c-text-muted);
}
.SearchModal__ResultDescription {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  color: var(--c-text-muted);
  margin-block: 0;
}
.SearchModal__ResultContent {
  display: inline-flex;
  align-items: center;
}
.DefaultResult .SearchModal__ResultContent, .ArticleResult .SearchModal__ResultContent, .ImpressionResult .SearchModal__ResultContent {
  align-items: start;
  flex-direction: column;
  justify-content: center;
}
.SearchModal__NoResults {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: var(--fw-bold);
  color: #ffffff;
  margin-top: 3rem;
  text-align: center;
}

.ShareEvent__Modal[open] {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(100px);
  border: none;
}
.ShareEvent__Modal[open] .button-box {
  border-radius: var(--s-100);
}
.ShareEvent__ModalInner {
  gap: var(--s-050);
}
.ShareEvent__OpenButton, .ShareEvent__CloseButton {
  appearance: none;
  border: none;
}

.TicketSlider {
  --slider-padding: 0.275rem;
  --slider-thumb-size: 3rem;
  --slider-thumb-color: var(--c-color-red);
  --slider-track-width: 18rem;
  --slider-track-color: var(--c-blurry-light-10);
  --slider-track-outline-width: 1px;
  --slider-track-outline-color: var(--c-light-60);
  --slider-success-bg-color: var(--c-color-lime);
  display: grid;
  position: relative;
  /*********** Baseline, reset styles ***********/
}
@media (min-width: 61.25em) {
  .TicketSlider {
    --slider-thumb-size: 3rem;
  }
}
@media (min-width: 81.25em) {
  .TicketSlider {
    --slider-thumb-size: 4rem;
    --slider-track-width: 27rem;
  }
}
@media (min-width: 120em) {
  .TicketSlider {
    --slider-thumb-size: 5rem;
    --slider-track-width: 35rem;
  }
}
.TicketSlider__Link {
  grid-column: 1/-1;
  grid-row: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--c-light-60);
  text-decoration: none;
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.TicketSlider__Disclaimer {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
  grid-column: 1/-1;
  grid-row: 2/3;
  margin-top: var(--s-100);
  color: #ffffff;
  opacity: 0.5;
}
.TicketSlider__Input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: var(--slider-thumb-size);
  display: block;
  padding: 4px;
  /* Removes default focus */
  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  /* slider track */
  /* slider thumb */
  /*********** Firefox styles ***********/
  /* slider track */
  /* slider thumb */
}
.TicketSlider__Input:focus {
  outline: none;
}
.TicketSlider__Input::-webkit-slider-runnable-track {
  border-radius: 100vw;
  height: 100%;
}
.TicketSlider__Input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: 0px; /* Centers thumb on the track */
  background-color: transparent;
  border-radius: 100vw;
  box-shadow: none;
  height: var(--slider-thumb-size);
  width: var(--slider-thumb-size);
}
.TicketSlider__Input::-moz-range-track {
  border-radius: 100vw;
  height: 100%;
}
.TicketSlider__Input::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  background-color: transparent;
  border-radius: 100vw;
  box-shadow: none;
  height: var(--slider-thumb-size);
  width: var(--slider-thumb-size);
}
.TicketSlider__Input::-moz-range-progress {
  display: none;
}
.TicketSlider__InputWrapper {
  grid-column: 1/-1;
  grid-row: 1/2;
  background-color: var(--c-blurry-light-10);
  display: block;
  border-radius: 100vw;
  padding: 0;
  height: var(--slider-thumb-size);
  width: var(--slider-track-width);
  justify-self: center;
  position: relative;
  border: var(--slider-padding) solid transparent;
  outline: var(--slider-track-outline-width) solid var(--slider-track-outline-color);
  outline-offset: calc(-1 * var(--slider-track-outline-width));
  box-sizing: content-box;
}
.TicketSlider__InputWrapper.done {
  position: relative;
  overflow: hidden;
}
.TicketSlider__InputWrapper.done::after {
  content: "🤖";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  left: auto;
  width: var(--slider-thumb-size);
  color: var(--c-text-inverted);
  background-color: var(--slider-success-bg-color);
  border-radius: 100vw;
  animation: input-thumb-success-grow 0.5s forwards;
}
.TicketSlider__VisualThumb {
  background-image: url(/assets/icons/control-arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--slider-thumb-color);
  background-size: 40%;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100vw;
  pointer-events: none;
  transform: translateX(calc(var(--v, 0) * var(--x) * 1px - var(--v, 0) * 100%));
}

@keyframes input-thumb-success-grow {
  to {
    width: 100%;
  }
}
.ToggleSwitch {
  --toggle-width: 10rem;
  --toggle-height: 2rem;
  --toggle-border-radius: calc(var(--toggle-height) / 2);
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  width: var(--toggle-width);
  height: var(--toggle-height);
  display: grid;
  grid-template-columns: [left-start] 1fr [left-end right-start] 1fr [right-end];
}
.ToggleSwitch__Track {
  display: grid;
  grid-row: 1;
  grid-column: 1/-1;
  grid-template-columns: subgrid;
  height: var(--toggle-height);
  border-radius: var(--toggle-border-radius);
  background-color: var(--c-blurry-dark-55);
}
.ToggleSwitch__Pill {
  grid-area: left;
  position: relative;
  height: var(--toggle-height);
  transition: transform 0.3s ease;
  background-color: var(--c-background);
  color: var(--c-primary-text);
  height: var(--toggle-height);
  border-radius: var(--toggle-border-radius);
  -webkit-border-radius: var(--toggle-border-radius);
  box-shadow: 1.5px 1.5px 4px rgba(0, 0, 0, 0.2);
  outline: 2px solid var(--c-dark-off-black);
}
.ToggleSwitch[data-state=right] .ToggleSwitch__Pill {
  transform: translate(100%, 0);
}
.ToggleSwitch__Options {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  z-index: 1;
}
.ToggleSwitch__OptionA {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  background-color: transparent;
  flex: 1;
  text-align: center;
}
.ToggleSwitch__OptionB {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  color: var(--c-light-60);
  background-color: transparent;
  flex: 1;
  text-align: center;
}
.ToggleSwitch[data-state=right] .ToggleSwitch__Pill {
  transform: translate(100%, 0);
}
.ToggleSwitch[data-state=right] .ToggleSwitch__OptionA, .ToggleSwitch[data-state=left] .ToggleSwitch__OptionB {
  color: var(--c-light-60);
}
.ToggleSwitch[data-state=right] .ToggleSwitch__OptionB, .ToggleSwitch[data-state=left] .ToggleSwitch__OptionA {
  color: var(--c-text);
}

.VibeCheck {
  --icon-size: 1.3rem;
  --play-button-icon-size: 1.5rem;
  --social-icon-gap: 1rem;
  --main-link-gap: 1rem;
  --social-image-size: 4rem;
  display: grid;
  grid-template-columns: min-content auto max-content;
  gap: 1rem;
  background-color: var(--c-blurry-dark-55);
  align-items: center;
  border-radius: var(--border-radius-1);
  padding: 0.5rem;
  padding: var(--s-050) var(--s-250) var(--s-050) var(--s-050);
}
@media (max-width: 81.24em) {
  .VibeCheck {
    padding: var(--s-050) var(--s-200) var(--s-050) var(--s-050);
  }
}
@media (max-width: 46.24em) {
  .VibeCheck {
    padding: var(--s-050) 1.25rem var(--s-050) 0.5rem;
  }
}
.VibeCheck__PlayButton {
  --icon-size: 1.5rem;
  color: var(--c-text);
  display: grid;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  gap: var(--main-link-gap);
  border-radius: 3px;
  overflow: hidden;
}
.VibeCheck__PlayButton .Icon {
  --icon-size: var(--play-button-icon-size);
  grid-area: 1/-1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  transition: background-color 222ms ease-out;
}
.VibeCheck__PlayButton .Icon:hover {
  background-color: rgba(0, 0, 0, 0);
}
.VibeCheck__Image {
  width: var(--social-image-size);
  height: var(--social-image-size);
  grid-area: 1/-1;
}
.VibeCheck__Image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.VibeCheck__Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.VibeCheck__SocialLinks {
  --icon-size: 1.1rem;
  display: flex;
  gap: var(--social-icon-gap);
  align-items: center;
  justify-content: end;
}
.VibeCheck__SocialLinks a {
  display: grid;
  align-items: center;
  justify-items: center;
}
.VibeCheck__SocialLinks a:hover svg {
  color: var(--c-primary-text);
  transform: scale(0.95);
  transition: all 300ms ease;
}
.VibeCheck__SocialLinks svg {
  color: var(--c-secondary-text);
  transition: all 300ms ease;
}

.Upcoming {
  display: grid;
  grid-template-rows: auto 2rem;
  height: 100%;
  overflow: hidden;
  scroll-margin-top: 100px;
}
.Upcoming .splide__pagination > li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
}
.Upcoming .splide__pagination > li button {
  margin: 0;
}
.Upcoming .Card__Title {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-bold);
}
.Upcoming .Card__Byline {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}

.ImpressionsGallery {
  display: grid;
  height: 100%;
  width: 100%;
}
.ImpressionsGallery__Track {
  grid-area: 1/-1;
}
.ImpressionsGallery__Meta {
  grid-area: 1/-1;
  z-index: 1;
  padding: 0.5rem;
  pointer-events: none;
}
.ImpressionsGallery .Teaser__Image {
  position: absolute;
  inset: 0;
}

.FilterPage {
  --teaser-content-gap: var(--spacing-padding-small);
  background-color: var(--c-background);
}
.FilterPage__Header a {
  color: var(--c-primary-text);
  text-decoration: none;
}
.FilterPage .Banner, .FilterPage__Main {
  max-width: 100vw;
}
.FilterPage .FilterReset,
.FilterPage .FilterConfirm {
  position: fixed;
  top: 50%;
  width: min-content;
  transform: translateY(-50%);
  display: none;
}
.FilterPage .FilterReset {
  left: var(--s-100);
}
.FilterPage .FilterConfirm {
  right: var(--s-100);
}
.FilterPage[data-responsive-overlay-state=open] .FilterReset, .FilterPage[data-responsive-overlay-state=open] .FilterConfirm {
  display: block;
}
.FilterPage__FilterControls {
  margin: 0 var(--grid-padding-inline);
  display: flex;
  justify-content: end;
}
.FilterPage__FilterResults {
  display: grid;
  column-gap: var(--grid-col-gap);
  padding: var(--s-100) var(--grid-padding-inline);
  gap: var(--gap);
  grid-template-columns: var(--grid-filter-page);
  padding: var(--spacing-padding-medium) var(--grid-padding-inline);
}
@media (min-width: 61.25em) {
  .FilterPage__FilterResults {
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
    padding-bottom: 6rem;
  }
}
@media (max-width: 46.24em) {
  .FilterPage__FilterResults {
    display: flex;
    flex-direction: column;
  }
}
body.EventsPage.list-view-is-active .FilterPage__FilterResults, body.ArchivePage.list-view-is-active .FilterPage__FilterResults,
.FilterPage .dashboard-test-list {
  grid-template-columns: min-content repeat(4, auto);
}
body.EventsPage.list-view-is-active .FilterPage__FilterResults .Teaser__Main-Acts .Act, body.ArchivePage.list-view-is-active .FilterPage__FilterResults .Teaser__Main-Acts .Act,
.FilterPage .dashboard-test-list .Teaser__Main-Acts .Act {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: var(--fw-bold);
}

.dashboard-test-list,
.EventsPage.list-view-is-active .FilterPage__FilterResults,
.ArchivePage.list-view-is-active .FilterPage__FilterResults {
  display: flex;
  flex-direction: column;
  gap: var(--s-075);
}
.dashboard-test-list .Teaser__Main-Acts .Act,
.EventsPage.list-view-is-active .FilterPage__FilterResults .Teaser__Main-Acts .Act,
.ArchivePage.list-view-is-active .FilterPage__FilterResults .Teaser__Main-Acts .Act {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
  font-weight: var(--fw-bold);
}
.dashboard-test-list .EventsSlider,
.EventsPage.list-view-is-active .FilterPage__FilterResults .EventsSlider,
.ArchivePage.list-view-is-active .FilterPage__FilterResults .EventsSlider {
  display: none;
}

.FilterToggle {
  --icon-size: 40px;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: calc(var(--icon-size) / 2);
  line-height: var(--icon-size);
  background: var(--c-event-background);
  color: var(--c-light-60);
}
.FilterToggle .Icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  right: calc(var(--icon-size) / 2);
}
.FilterToggle .Icon, .FilterToggle .Icon svg {
  display: block;
}
.FilterToggle__Badge {
  --s: 10px;
  font-size: var(--s);
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
  width: calc(var(--s) * 1.5);
  height: calc(var(--s) * 1.5);
  border-radius: 100vw;
  background-color: var(--c-color-red);
  color: white;
  position: absolute;
  top: calc(var(--s) * 1.2);
  right: calc(var(--s) * 1.2);
}

.HeroPage {
  --Hero__Main-Overlap: var(--s-400);
  --main-background-color: rgba(118, 118, 118, 0.3);
  --icon-color: #ffffff;
  background-color: var(--c-background);
  color: var(--c-primary-text);
}
@media (min-width: 61.25em) {
  .HeroPage {
    --Hero__Main-Overlap: var(--s-500);
  }
}
.HeroPage:has(.overlay.open) {
  overflow: hidden;
}
.HeroPage .MainMenu {
  --main-menu-color-fg: #ffffff;
}
.HeroPage__Main {
  isolation: isolate;
  contain: paint;
  position: relative;
  padding: 6rem;
  z-index: 3;
  margin-top: calc(100svh - var(--Hero__Main-Overlap));
  background-color: var(--main-background-color);
  backdrop-filter: var(--effect-event-bg-blur);
  -webkit-backdrop-filter: var(--effect-event-bg-blur);
  border-radius: 1.5rem;
  position: relative;
  display: grid;
  column-gap: var(--grid-col-gap);
  padding: var(--s-100) var(--grid-padding-inline);
  --grid-row-gap: 2rem;
  grid-template-columns: var(--grid-hero-page);
  padding-bottom: 6rem;
}
.HeroPage__Main > * {
  grid-column: content;
}
@media (min-width: 61.25em) {
  .HeroPage__Main {
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
    padding-bottom: 6rem;
  }
}
.HeroPage__Main {
  position: relative;
}
.HeroPage__Main::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), transparent);
}
.HeroPage__Visual {
  --interval-duration: 5000; /* must be in milliseconds for JS */
  --transition-duration: 2s;
  display: grid;
  height: 100lvh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: max(0.5, 1 - var(--scroll-y-vh) * 0.01);
}
.ArticlePage .HeroPage__Visual {
  opacity: 1;
}
.HeroPage__Visual:not(.transition) picture:first-of-type {
  opacity: 1;
}
.HeroPage__Visual picture {
  grid-column: 1/2;
  grid-row: 1/2;
  opacity: 0;
}
.HeroPage__Visual picture.previous {
  opacity: 1;
  z-index: 10;
}
.HeroPage__Visual picture.current {
  opacity: 1;
  z-index: 100;
  transition: opacity var(--transition-duration) ease-in-out;
}
.HeroPage__Visual img {
  width: 100vw;
  height: 100lvh;
  object-fit: cover;
  object-position: var(--focus, center);
  filter: brightness(0.8);
}
.HeroPage__Text {
  height: calc(100svh - var(--Hero__Main-Overlap));
  display: grid;
  position: fixed;
  inset: 0;
  z-index: 2;
}
.HeroPage__Text .PrevNext {
  opacity: max(0, 20 - var(--scroll-y-vh, 0));
}
@media (min-width: 46.25em) {
  .HeroPage__Text .PrevNext {
    opacity: max(0, 30 - var(--scroll-y-vh, 0));
  }
}
.HeroPage__Title {
  grid-area: 1/-1;
  align-self: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 10rem rgba(42, 42, 42, 0.6);
  margin-inline: 6vw;
  pointer-events: none;
  opacity: max(0, 50 - var(--scroll-y-vh, 0));
}
.HeroPage__Title h1.is-event-title {
  font-size: var(--fs-5);
  line-height: var(--flh-5);
  letter-spacing: var(--fls-5);
  font-weight: 600;
  margin-block: 1rem;
}
.HeroPage__Title h1.is-event-title .Act__country-code {
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.HeroPage__Footer {
  height: 10rem;
  position: relative;
  z-index: 3;
}
.HeroPage__Footer .PrevNext {
  height: auto !important;
}
.HeroPage .PrevNext {
  grid-area: 1/-1;
  height: min-content;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-inline: var(--spacing-padding-medium);
  padding-block: var(--spacing-padding-large);
  z-index: -1;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (min-width: 12.5em) and (max-width: 46.24em) {
  .HeroPage .PrevNext {
    align-self: end;
  }
}
.HeroPage .PrevNext__Button {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.HeroPage .PrevNext__Button.overview {
  text-decoration: none;
}
.HeroPage .PrevNext__Button[data-swup-animation=prev] {
  justify-content: flex-start;
}
.HeroPage .PrevNext__Button[data-swup-animation=next] {
  justify-content: flex-end;
}
@media (max-width: 46.24em) {
  .HeroPage .HeroPage__Header .PrevNext__Button[data-swup-animation=prev] {
    align-items: end;
  }
}
@media (max-width: 46.24em) {
  .HeroPage .HeroPage__Header .PrevNext__Button[data-swup-animation=next] {
    align-items: end;
  }
}
.HeroPage .PrevNext__ButtonInner {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-background-color);
  backdrop-filter: var(--effect-event-bg-blur);
  text-decoration: none;
  border-radius: 5px;
  height: 3rem;
  width: 3rem;
  pointer-events: none;
}
.HeroPage .PrevNext__ButtonInner.overview {
  display: flex;
  align-items: center;
  min-width: 10rem;
  width: auto;
}
.HeroPage .PrevNext__ButtonInner .Icon {
  display: flex;
}
.HeroPage .PrevNext__Button[href=""] {
  pointer-events: none;
  color: #888888;
}
.HeroPage .PrevNext__Button[href=""] svg * {
  fill: #888888;
}

.Grid.GridAutoFill {
  grid-column: full;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: calc(var(--gap) * 3);
  column-gap: var(--gap);
  column-gap: 1.5rem;
}
@media (min-width: 46.25em) {
  .Grid.GridAutoFill {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 61.25em) {
  .Grid.GridAutoFill {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 81.25em) {
  .Grid.GridAutoFill {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 120em) {
  .Grid.GridAutoFill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.GridItem {
  width: 100%;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.GridItem__Image {
  width: 100%;
  aspect-ratio: 3/4;
  margin-bottom: 1rem;
  background-color: var(--c-blurry-dark);
}
.GridItem__Text {
  display: grid;
  grid-template-areas: "A C" "B C";
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
}
.GridItem__Title {
  grid-area: A;
}
.GridItem__Byline {
  grid-area: B;
  color: var(--c-text-muted);
}
.GridItem__Byline p {
  margin: 0 !important;
}
.GridItem__Image img {
  width: 100%;
  object-fit: cover;
}

.ArticlePage {
  --main-background-color: #0d0d0d;
  --main-foreground-color: #ffffff;
  --main-backdrop-filter: none;
}
.ArticlePage .Article__Body {
  grid-column: full;
}

.Article__Meta {
  grid-column: content;
  margin-bottom: var(--s-200);
}
.Article__Tag {
  text-decoration: none;
}
.Article__Lead {
  grid-column: content;
  margin-top: var(--s-150);
  margin-bottom: var(--s-300);
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-normal);
}
.Article__Byline {
  grid-column: content;
  color: var(--c-secondary-text);
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
  padding-bottom: var(--s-050);
  margin-bottom: var(--s-300);
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.Article__Photographer {
  color: var(--c-light-40);
}
.Article__Photographer::before {
  content: "Titelbild: ";
}
.Article__Body {
  display: grid;
  column-gap: var(--s-100);
  grid-template-columns: subgrid;
}
.Article__Body > * {
  grid-column: content;
}

.ArticlesPage .FilterPage__FilterResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--s-300);
}
.ArticlesPage .FilterPage__FilterResults .Card {
  width: clamp(360px, 35vw, 600px);
}
@media (min-width: 61.25em) {
  .ArticlesPage .FilterPage__FilterResults .Card.highlight {
    width: 85vw;
    --teaser-ratio: 2/1 !important;
  }
}
@media (max-width: 61.24em) {
  .ArticlesPage .FilterPage__FilterResults .Card {
    width: 100%;
  }
}

.DashboardPage {
  --icon-color: #ffffff;
  position: relative;
}
.DashboardPage .splide__pagination__page {
  border-radius: 50%;
}
.DashboardPage .Icon-type-cta-plus {
  --icon-size: 6rem;
  width: unset;
  height: unset;
}
@media (min-width: 46.25em) and (max-width: 81.24em) {
  .DashboardPage .Icon-type-cta-plus {
    --icon-size: 4rem;
  }
}
.DashboardPage .Card__Byline {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.DashboardPage .Card-Impression .Card__Title {
  margin-bottom: var(--s-050);
}

@keyframes dashboard-fade-in {
  0% {
    opacity: 0;
    filter: blur(100px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
.Dashboard {
  --gap-top: var(--header-height);
  --gap: 1rem;
  --border-radius: 1rem;
  --teaser-ratio: 6 / 7;
  --grid-column-height: calc((100vw - var(--gap)) / 5.5);
  --fixed-row-count: 21;
  --teaser-content-gap: var(--spacing-padding-small);
}
@media (min-width: 61.25em) {
  .Dashboard {
    --gap: 1.5rem;
  }
}
.Dashboard {
  gap: var(--gap);
  padding: var(--grid-padding-inline);
  padding-top: 0;
  display: grid;
  grid-auto-columns: 1fr;
  gap: var(--gap);
  grid-auto-flow: row;
  grid-template-rows: repeat(var(--fixed-row-count), var(--grid-column-height)) auto auto auto; /* Last three rows are flexible and adjust their height based on content*/
  grid-template-areas: "T1 T1" "T1 T1" "T1 T1" "T1 T1" "T1 T1" "F F" "W1 E" "W1 E" "W1 E" "K T2" "K T2" "K T2" "I I" "I I" "I I" "W2 A" "W2 A" "W2 A" "W3 W3" "W3 W3" "W3 W3" "N N" "C C" "B B";
}
@media (min-width: 46.25em) {
  .Dashboard {
    --grid-column-height: calc((100vw - 60px) / 3);
    --fixed-row-count: 10;
    grid-template-areas: "T1 T1 T1 T1" "T1 T1 T1 T1" "W1 W1 E E" "W1 W1 F F" "K K W3 W3" "A A W3 W3" "T2 T2 W2 W2" "T2 T2 W2 W2" "I I I I" "I I I I" "N N N N" "C C C C" ". B B .";
  }
}
@media (min-width: 61.25em) {
  .Dashboard {
    --grid-column-height: calc((100vw - 60px) / 6);
    --fixed-row-count: 7;
    grid-template-areas: "W1 T1 T1 E" "W1 T1 T1 F" "W2 T2 K W3" "W2 T2 A W3" "I I I I" "I I I I" "I I I I" ". N N ." "C C C C" ". B B .";
  }
}
.Dashboard > * {
  min-width: 0; /* Prevents content from affecting column width */
  max-width: 100%; /* Forces content to fit inside columns */
}
.Dashboard__Item {
  border-radius: var(--border-radius);
  position: relative;
}
.Dashboard__Item.short {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg, #1f1e1e);
}
.Dashboard__Item.short.cta {
  background-color: transparent;
  text-align: center;
}
.Dashboard__Item.short.cta h2 {
  color: #ffffff;
}
.Dashboard__Background {
  --Dashboard-bg-blur: 60px;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.Dashboard__Background .image {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100lvh;
  background-size: cover;
  background-position: center;
  filter: brightness(60%);
}
.Dashboard__Background .blur-overlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(var(--Dashboard-bg-blur));
  -webkit-backdrop-filter: blur(var(--Dashboard-bg-blur));
}
.Dashboard .TeaserMain {
  display: flex;
  flex-direction: column;
}
.Dashboard .TeaserMain__Tickets {
  text-align: center;
  margin: var(--s-100) 0 var(--s-075);
}
@media (min-width: 120em) {
  .Dashboard .TeaserMain__Tickets {
    margin: var(--s-250) 0;
  }
}
.Dashboard .Link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: var(--fg, #ffffff);
}
.Dashboard .Link__Title {
  text-align: center;
}
.Dashboard .LinkSection {
  margin-top: 5rem;
}
@media (max-width: 46.24em) {
  .Dashboard .LinkSection {
    margin-top: 0;
  }
}
.Dashboard .LinkSection__Row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}
.Dashboard .LinkSection__Row:not(:last-child) {
  margin-bottom: var(--gap);
}
.Dashboard .LinkSection__Link {
  flex: unset;
  width: 100%;
}
@media (min-width: 46.25em) {
  .Dashboard .LinkSection__Link {
    flex: 1;
  }
}
.Dashboard .LinkSection__Link.smol {
  --n: 3;
  display: flex;
  justify-content: center;
  flex-grow: 0;
  padding-inline: 2rem;
  width: calc(100% / var(--n) - var(--gap) * (var(--n) - 1) / var(--n));
}
@media (min-width: 46.25em) {
  .Dashboard .LinkSection__Link.smol {
    width: unset;
  }
}
.Dashboard .LinkSection__Link.smol::after {
  content: none;
}
.Dashboard .LinkSection__Link.smol .Icon {
  --icon-size: 1.5rem;
  display: block;
  margin-block: -0.2rem;
}
.Dashboard .LinkSection__Link.smol .Icon svg {
  display: block;
}
.Dashboard__FooterLink.button-box {
  margin-bottom: var(--gap);
  width: fit-content;
  align-self: center;
  padding: var(--s-065) var(--s-100);
  border-radius: 100vw;
  gap: 0.5em;
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
}
.Dashboard__FooterLink.button-box svg {
  --icon-size: 1em !important;
}
.Dashboard__Item:has(.Dashboard__FooterLink.button-box) {
  align-self: center;
  justify-self: center;
}
.Dashboard .Wildcard.default .Wildcard__Title H2,
.Dashboard .Link__Title,
.Dashboard .Upcoming .Teaser__Main-Acts {
  text-align: center;
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: 600;
}
@media (min-width: 46.25em) {
  .Dashboard .Wildcard.default .Wildcard__Title H2,
  .Dashboard .Link__Title,
  .Dashboard .Upcoming .Teaser__Main-Acts {
    font-size: var(--fs-3);
    line-height: var(--flh-3);
    letter-spacing: var(--fls-3);
    font-weight: 600;
  }
}

.DefaultPage .Banner {
  --Banner-color: #ffffff;
}

.content {
  grid-column: content;
}

.wide {
  grid-column: wide;
}

.full {
  grid-column: full;
}

.EventPage .HeroPage__Main {
  grid-row-gap: 1.5rem;
  display: grid;
  column-gap: var(--grid-col-gap);
  padding: var(--s-100) var(--grid-padding-inline);
  --grid-row-gap: 2rem;
  grid-template-columns: var(--grid-hero-page);
  padding-bottom: 6rem;
}
.EventPage .HeroPage__Main > * {
  grid-column: content;
}
@media (min-width: 61.25em) {
  .EventPage .HeroPage__Main {
    padding: var(--spacing-padding-medium) var(--grid-padding-inline);
    padding-bottom: 6rem;
  }
}
.EventPage__Meta {
  display: flex;
  gap: var(--s-050);
}
.EventPage__Meta .spacer {
  flex: 1;
}
@media (min-width: 12.5em) {
  .EventPage__Meta .spacer {
    display: none;
  }
}
.EventPage .ops {
  white-space: nowrap;
}
.EventPage__SupportActs .Act__name, .EventPage__Subtitle .Act__name {
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: var(--fw-bold);
}
.EventPage__SupportActs .Act__country-code, .EventPage__Subtitle .Act__country-code {
  margin-left: var(--s-030);
  color: var(--c-secondary-text);
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.EventPage__SupportActs dt, .EventPage__Subtitle dt {
  color: var(--c-secondary-text);
}
.EventPage__InfoBox {
  border-radius: var(--s-050);
  background: var(--c-blurry-dark-55);
  margin-bottom: var(--s-200);
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  padding: var(--s-200) var(--s-250);
}
@media screen and (max-width: 1300px) {
  .EventPage__InfoBox {
    padding: var(--s-150) var(--s-200);
  }
}
@media screen and (max-width: 740px) {
  .EventPage__InfoBox {
    padding: var(--s-100) 1.25rem;
  }
}
.EventPage__InfoBox dl {
  display: grid;
  grid-template-columns: max-content auto;
}
.EventPage__InfoBox dt {
  grid-column-start: 1;
  padding: var(--s-050) 0;
  margin-right: 0;
}
.EventPage__InfoBox dt:first-of-type {
  padding-top: 0;
}
.EventPage__InfoBox dt:last-of-type {
  padding-bottom: 0;
}
.EventPage__InfoBox dt:not(:last-of-type) {
  border-bottom: 1px solid var(--c-light-30);
}
@media (max-width: 46.24em) {
  .EventPage__InfoBox dt {
    padding: var(--s-050) 0;
  }
}
.EventPage__InfoBox dd {
  grid-column-start: 2;
  text-align: right;
  padding: var(--s-050) 0;
  margin-right: 0;
}
.EventPage__InfoBox dd:first-of-type {
  padding-top: 0;
}
.EventPage__InfoBox dd:last-of-type {
  padding-bottom: 0;
}
.EventPage__InfoBox dd:not(:last-of-type) {
  border-bottom: 1px solid var(--c-light-30);
}
@media (max-width: 46.24em) {
  .EventPage__InfoBox dd {
    padding: var(--s-050) 0;
  }
}
.EventPage__Tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: var(--s-050);
}
.EventPage__Tickets {
  margin-block: var(--block-margin-3);
  justify-self: center;
  z-index: 1;
}
.EventPage__Tickets a.capsule {
  pointer-events: all;
  transition: background-color, scale;
  margin-top: 0;
  transition-duration: 444ms;
  transition-timing-function: ease-out;
  padding: 0.6rem 1.6rem;
  border-radius: 100vh;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (min-width: 61.25em) {
  .EventPage__Tickets a.capsule {
    padding: 0.6rem 2rem;
  }
}
.EventPage__Tickets a.capsule a {
  text-decoration: none;
}
.EventPage__Tickets a.capsule:hover {
  scale: 1.1;
  transition-duration: 222ms;
  background-color: var(--c-light-100);
}
.EventPage__Tickets:not(.footer) {
  opacity: max(0, 50 - var(--scroll-y-vh, 0));
}
.EventPage__Tickets:not(.footer) a.capsule {
  margin-top: var(--s-100);
}
.EventPage__Tickets.footer {
  position: fixed;
  bottom: 2rem;
  width: 100%;
  margin: 0;
  text-align: center;
  transform: translateY(6rem);
  transition: transform 333ms ease-out;
}
.EventPage__Tickets.footer.visible {
  transform: translateY(0);
}
.EventPage__Tickets.footer.scroll-end {
  position: relative;
}

.EventsPage .FilterPage__FilterResults {
  grid-auto-flow: dense;
}
.EventsPage .EventsSlider {
  --slider-padding-inline: var(--grid-padding-inline);
  grid-column: 1/-1;
  padding-block: 5rem 8rem;
  margin-inline: calc(var(--slider-padding-inline) * -1);
}
.EventsPage .EventsSlider__Title {
  margin: 0 var(--slider-padding-inline);
  margin-bottom: var(--s-200);
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-bold);
}
.EventsPage .EventsSlider__PagesWrapper {
  overflow-x: auto;
}
.EventsPage .EventsSlider__PagesWrapper {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.EventsPage .EventsSlider__PagesWrapper::-webkit-scrollbar {
  display: none;
}
.EventsPage .EventsSlider__Pages {
  display: grid;
  gap: var(--gap);
  width: fit-content;
  padding-inline: var(--slider-padding-inline);
  grid-auto-columns: 11rem;
}
@media (min-width: 46.25em) {
  .EventsPage .EventsSlider__Pages {
    grid-auto-columns: 21rem;
  }
}
@media (min-width: 61.25em) {
  .EventsPage .EventsSlider__Pages {
    grid-auto-columns: 21rem;
  }
}
.EventsPage .EventsSlider .PageSlide {
  grid-row: 1/-1;
}
@media (max-width: 46.24em) {
  .EventsPage .EventsSlider .Teaser__Main-Acts {
    --fs-3: 1.3rem;
  }
}
.EventsPage .EventsSlider .Teaser__Support-Acts {
  font-size: var(--fs-extra-small);
}

.ImpressionPage {
  --page-padding: 0;
  --grid-padding-block: 0;
  --page-elements-padding: 2rem;
  --page-controls-stroke-width: 4;
  --thumbnail-height: 50px;
  --thumbnail-width: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100svh;
  margin: 0;
}
.ImpressionPage .Banner {
  --icon-color: #ffffff;
}
.ImpressionPage .MainMenu {
  --main-menu-color-fg: #ffffff;
}
.ImpressionPage__Text {
  transition: opacity 1s ease-in-out;
  opacity: var(--opacity);
  text-align: center;
}
.ImpressionPage .ImpressionPage__Title {
  margin-bottom: 0.7rem;
}
.ImpressionPage .ImpressionPage__Byline {
  margin: 0;
}
.ImpressionPage .DefaultPage__Main {
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 1;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid: initial;
  margin: 0;
  padding: 0;
}
.ImpressionPage .DefaultPage__Main .Gallery {
  grid-column: 1/2;
  grid-row: 1/3;
}
.ImpressionPage .DefaultPage__Main .Controls {
  grid-column: 1/2;
  grid-row: 2/3;
}
.ImpressionPage .Header {
  z-index: 2;
  color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.ImpressionPage .Gallery {
  z-index: 1;
}
.ImpressionPage .Controls {
  z-index: 3;
  place-self: end center;
}
.ImpressionPage .Gallery__Slide {
  width: 100vw;
  height: 100svh;
}
.ImpressionPage .Gallery__Image {
  height: 100%;
  width: 100%;
}
.ImpressionPage .Gallery__Image img,
.ImpressionPage .Gallery__Image figure {
  height: 100%;
  width: 100%;
  position: relative;
}
.ImpressionPage .Gallery__Image figcaption {
  z-index: 1000;
  position: absolute;
  left: var(--page-elements-padding);
  bottom: var(--page-elements-padding);
  color: #ffffff;
  font-size: var(--fs-small);
  line-height: var(--flh-small);
}
@media (max-width: 61.24em) {
  .ImpressionPage .Gallery__Image figcaption {
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(1.2 * var(--thumbnail-height) + var(--page-elements-padding));
    text-align: center;
  }
}
.ImpressionPage .Gallery__Image img {
  object-fit: contain;
}
.ImpressionPage .splide.Controls {
  width: 100%;
}
.ImpressionPage .splide.Controls .splide__track {
  overflow: visible;
}
.ImpressionPage .splide__arrow {
  height: calc(100% - 2 * var(--s-100));
  width: calc(50% - var(--s-100) - var(--s-050));
}
.ImpressionPage .splide__arrow svg {
  opacity: 0;
}
.ImpressionPage .Thumbnail {
  overflow: hidden;
  list-style: none;
  cursor: pointer;
  border-radius: 0.5rem;
  transform: scale(0.8);
  transition: transform 150ms ease-in-out;
}
.ImpressionPage .Thumbnail.is-active {
  transform: scale(1.05);
  transition: transform 150ms ease-in-out;
  outline: none !important;
  border: none !important;
}
.ImpressionPage .MainGallery__Controls {
  position: fixed;
  bottom: var(--s-200);
  right: 8rem;
  left: 8rem;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing-padding-small);
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 61.25em) {
  .ImpressionPage .MainGallery__Controls {
    flex-direction: row;
  }
}
@media (max-width: 46.24em) {
  .ImpressionPage .MainGallery__Controls {
    right: 0;
    left: 0;
    bottom: 6rem;
  }
}
.ImpressionPage .Thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--focus, center);
}
.ImpressionPage .splide__toggle {
  background-color: transparent;
}
.ImpressionPage .splide__controls {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed;
  padding-inline: var(--s-300);
  right: 0;
  bottom: var(--s-200);
  z-index: 1;
}
@media (max-width: 46.24em) {
  .ImpressionPage .splide__controls {
    justify-content: center;
    bottom: var(--s-100);
  }
}
.ImpressionPage .splide__controls svg circle#progress {
  stroke-dashoffset: var(--stroke-dashoffset);
  stroke-dasharray: 1000 1000;
  stroke-width: var(--page-controls-stroke-width);
}
.ImpressionPage .splide__controls .splide__toggle {
  --icon-size: 4rem;
  appearance: none;
  border: none;
  display: grid;
  width: var(--icon-size);
  height: var(--icon-size);
}
.ImpressionPage .splide__controls .splide__toggle svg {
  grid-column: 1/2;
  grid-row: 1/2;
}

.ImpressionsPage .DefaultPage__Main {
  row-gap: var(--gap);
}
@media (max-width: 46.24em) {
  .ImpressionsPage .Card {
    aspect-ratio: 3/2;
  }
}

.ReelsPage {
  position: relative;
  display: grid;
}
.ReelsPage__Header {
  --icon-color: white;
  z-index: 1;
}
.ReelsPage__Main {
  position: fixed;
  inset: 0;
  display: grid;
}

.Reels {
  grid-area: 1/-1;
}
.Reels .splide__pagination {
  z-index: 40000;
}
.Reels .splide__video {
  transform: scale(2.4);
  transform-origin: center center;
}
@media (orientation: landscape) {
  .Reels .splide__video {
    transform: scale(2.4);
  }
}
@media (orientation: portrait) {
  .Reels .splide__video {
    transform: scale(3.4);
  }
}
.Reels__Controls {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-inline: var(--spacing-padding-large);
}
@media (max-width: 46.24em) {
  .Reels__Controls {
    margin-inline: var(--spacing-padding-small);
  }
}
.Reels__Mute-Button {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  background: var(--c-blurry-dark);
  border-radius: 50%;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-image: url("/assets/icons/control-audio-mute.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.Reels__Mute-Button:checked {
  background-image: url("/assets/icons/control-audio-unmute.svg");
}
.Reels__All-Events {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  text-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-1);
  padding: 8px 24px;
  background-color: rgba(42, 42, 42, 0.6);
  backdrop-filter: blur(8px);
}
.Reels .splide__video__play:after {
  display: none !important;
}
.Reels .splide__slide iframe {
  mix-blend-mode: screen;
}
.Reels .splide__video__wrapper {
  background: transparent !important;
}

.Reel {
  display: grid;
  height: 100%;
  cursor: grab;
}
.Reel__Item {
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  overflow: hidden;
}
.Reel__Video {
  grid-area: 1/-1;
  z-index: -1;
  overflow: hidden;
}
.Reel__Iframe {
  object-fit: cover;
  transform: scale(2.3);
}
@media (max-width: 46.24em) {
  .Reel__Iframe {
    transform: scale(4.2);
  }
}
.Reel__Content {
  padding: 2.5rem;
  padding-bottom: 0;
  grid-area: 1/-1;
  display: grid;
  color: white;
  position: absolute;
  inset: 0;
  opacity: 1;
  z-index: 100000;
}
@media (max-width: 46.24em) {
  .Reel__Content {
    padding: 0.5rem;
  }
}
.Reel__Text {
  grid-area: 1/-1;
  place-self: center;
  text-align: center;
}
.Reel__Title a {
  text-decoration: none;
  color: inherit;
}
.Reel__Title h2 {
  font-size: var(--fs-5);
  line-height: var(--flh-5);
  letter-spacing: var(--fls-5);
  font-weight: 600;
  margin-top: var(--spacing-padding-small);
  margin-bottom: var(--spacing-padding-medium);
  font-size: calc((20rem + 66vw) * min(0.125, var(--fs)));
}
.Reel__Link.capsule {
  z-index: 10000;
  pointer-events: all;
  cursor: pointer;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  padding: 0.6rem 1.6rem;
  border-radius: 100vh;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (min-width: 61.25em) {
  .Reel__Link.capsule {
    padding: 0.6rem 2rem;
  }
}
.Reel__Link.capsule a {
  text-decoration: none;
}
.Reel__VideoTitle {
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
  grid-area: 1/-1;
  place-self: end start;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 4px;
  width: 100%;
  --icon-size: 20px;
}
@media (max-width: 46.24em) {
  .Reel__VideoTitle {
    margin-bottom: 5px;
    --icon-size: 18px;
  }
}
.Reel__VideoTitleText {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Reel__VideoTitleMarker {
  margin-top: 2px;
}
.Reel__Progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.Reel__Progress::after {
  content: "";
  display: block;
  height: 100%;
  background-color: #ff0000;
  transition: width 0.5s linear; /* Adjust duration for smoother updates */
}
.splide__slide.is-active .Reel__Progress::after {
  width: calc(1% * var(--progress));
}

.Reels .splide__slide::after {
  content: "Loading...";
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Reels .splide__slide.is-visible::after {
  content: none;
}
.Reels .splide__slide iframe,
.Reels .splide__slide .Reel__Text {
  opacity: 0;
}
.Reels .splide__slide.is-visible iframe, .Reels .splide__slide.is-visible .Reel__Text {
  opacity: 1;
}

.Reels .splide__slide.is-active {
  background-image: none;
}

.Reels .splide__slide,
.Reels__LoadingOverlay {
  background-image: url("/assets/icons/grain.gif");
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: relative;
}

.Reels__LoadingOverlay {
  background-image: url("/assets/icons/grain.gif");
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 9999;
}

:root {
  --splash-animation-duration: 600ms;
}

.ReelsPage__Header {
  animation-name: headerOpacity;
  animation-duration: var(--splash-animation-duration);
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-delay: 2.8s;
}

.SplashPage {
  background: rgb(41, 41, 41);
  height: 100svh;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200000;
  animation-duration: var(--splash-animation-duration);
  animation-delay: 2.8s;
  animation-name: splashAnimationFadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 1;
  pointer-events: none;
}
.SplashPage__Title {
  background: var(--c-brand-primary);
  margin: 1rem;
  padding: 0.6rem 1.2rem;
  border-radius: var(--border-radius-1);
  font-feature-settings: "ss02";
  overflow: hidden;
  color: var(--c-text-inverted);
  font-size: var(--fs-3);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-3);
  font-weight: 600;
  animation-name: splashTitleBoxReveal;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-duration: var(--splash-animation-duration);
  animation-delay: 0;
}
.SplashPage__Title div {
  white-space: nowrap;
  text-align: center;
  animation-name: splashTitleReveal;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-duration: 300ms;
  animation-delay: var(--splash-animation-duration);
  opacity: 0;
}
.SplashPage__Byline {
  overflow: hidden;
  height: 1.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  color: var(--c-brand-primary);
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.SplashPage__Byline div {
  text-align: center;
  transform: translateY(100px);
  animation-duration: var(--splash-animation-duration);
  animation-delay: var(--splash-animation-duration);
  animation-name: splashBylineSlideIn;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes splashBylineSlideIn {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes splashTitleBoxReveal {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes splashTitleReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes splashAnimationFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes headerOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ShopPage .GridItem__Image {
  aspect-ratio: 3/4;
  background-color: lightgrey;
  background: var(--c-blurry-dark);
  width: 100%;
  overflow: hidden;
}
.ShopPage .GridItem__Image.Product_contain {
  position: relative;
}
.ShopPage .GridItem__Image.Product_contain img {
  width: 80%;
  max-height: 80%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 5px rgb(0, 0, 0));
}
.ShopPage .GridItem__Image.Product_cover img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ShopPage .Product__BuyButton {
  grid-area: C;
  max-width: fit-content;
  justify-self: end;
  transition: all 0.3s ease;
}
.ShopPage .Product__BuyButton:hover {
  background: var(--c-color-lime);
  color: var(--c-light-white);
  border-color: var(--c-color-lime);
  transition: all 0.3s ease;
}

.PersonsPage .GridItem a {
  text-decoration: none;
}

.NewsletterPage {
  --border-radius: 1rem;
}
.NewsletterPage #mc_embed_shell {
  grid-column: content;
}
.NewsletterPage #mc_embed_shell h2 {
  display: none;
}
.NewsletterPage .indicates-required {
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  text-align: left;
  color: var(--c-secondary-text);
}
.NewsletterPage .mc-field-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
.NewsletterPage .mc-field-group label {
  opacity: 0;
  font-size: var(--fs-extra-small);
  line-height: var(--flh-extra-small);
  letter-spacing: var(--fls-extra-small);
  font-weight: var(--fw-normal);
}
.NewsletterPage .mc-field-group label:has(+ input:focus-visible) {
  opacity: 1;
}
.NewsletterPage .mc-field-group input[type=email],
.NewsletterPage .mc-field-group input[type=text] {
  width: 100%;
  border: none;
  border: 2px solid transparent;
  font-size: 16px;
}
.NewsletterPage .mc-field-group input[type=email],
.NewsletterPage .mc-field-group input[type=text] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  text-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  padding: var(--s-100) var(--s-200);
  background-color: var(--c-blurry-dark);
  backdrop-filter: blur(8px);
}
.NewsletterPage .mc-field-group input[type=email] svg,
.NewsletterPage .mc-field-group input[type=text] svg {
  --icon-size: 1.1rem;
  display: block;
}
.NewsletterPage .mc-field-group input[type=email]:focus-visible,
.NewsletterPage .mc-field-group input[type=text]:focus-visible {
  outline: 2px solid var(--c-light-30);
}
.NewsletterPage .mc-field-group input[type=email]:focus-visible::placeholder,
.NewsletterPage .mc-field-group input[type=text]:focus-visible::placeholder {
  opacity: 0;
}
.NewsletterPage .mc-field-group input[type=email].mce_inline_error,
.NewsletterPage .mc-field-group input[type=text].mce_inline_error {
  border: 2px solid var(--c-color-red);
}
.NewsletterPage .mc-field-group div.mce_inline_error {
  background: transparent !important;
  color: var(--c-color-red) !important;
  display: none !important;
}
.NewsletterPage .mergeRow {
  margin-top: 2rem;
}
.NewsletterPage .mergeRow p {
  color: var(--c-text-muted) !important;
}
.NewsletterPage .mergeRow label {
  opacity: 1;
  margin-bottom: 1rem;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
.NewsletterPage .mergeRow .mc_fieldset {
  border: none;
}
.NewsletterPage .mergeRow label.checkbox {
  display: flex;
  align-items: start;
}
.NewsletterPage .mergeRow input[type=checkbox] {
  margin-right: 1rem;
  padding-top: 0.75rem;
  width: 1.35rem;
  height: 1.35rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 16px;
}
.NewsletterPage .mergeRow input[type=checkbox].mce_inline_error {
  outline: 2px solid var(--c-color-red);
}
.NewsletterPage input[type=text],
.NewsletterPage select {
  width: 100%;
  font-size: 16px;
}
.NewsletterPage input[type=submit] {
  width: 100%;
  background: var(--c-color-lime) !important;
  color: var(--c-text-inverted) !important;
  border: none;
  margin-top: 2rem;
  width: max-content;
  font-size: 16px;
  padding: 0.6rem 1.6rem;
  border-radius: 100vh;
  font-size: var(--fs-1);
  line-height: var(--flh-1);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
}
@media (min-width: 61.25em) {
  .NewsletterPage input[type=submit] {
    padding: 0.6rem 2rem;
  }
}
.NewsletterPage input[type=submit] a {
  text-decoration: none;
}
.NewsletterPage input[type=submit]:hover {
  filter: brightness(0.9);
}
.NewsletterPage #mce-responses {
  margin-top: 2rem;
}

body[data-responsive-overlay-state=open] {
  overflow: hidden;
}

.ResponsiveOverlay {
  width: 100%;
  margin: 0 var(--spacing-padding-medium);
}
.ResponsiveOverlay__Toggle {
  display: none;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}
@media (max-width: 46.24em) {
  .ResponsiveOverlay__Toggle {
    display: block;
  }
}
@media (max-width: 46.24em) {
  .ResponsiveOverlay__Overlay {
    display: none;
    position: fixed;
    z-index: 1000;
    inset: 0;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: var(--c-blurry-background);
  }
  body[data-responsive-overlay-state=open] .ResponsiveOverlay__Overlay {
    display: grid;
  }
  body[data-responsive-overlay-state=open] .ResponsiveOverlay__Overlay .FilterOverlay__Tags {
    height: 100%;
    overflow: auto;
    padding: var(--spacing-padding-extra-large) 0;
  }
  body[data-responsive-overlay-state=open] .ResponsiveOverlay__Overlay .FilterOverlay__Tags {
    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    /* Hide scrollbar for Chrome, Safari and Opera */
  }
  body[data-responsive-overlay-state=open] .ResponsiveOverlay__Overlay .FilterOverlay__Tags::-webkit-scrollbar {
    display: none;
  }
  body[data-responsive-overlay-state=open] .ResponsiveOverlay__Overlay .Filtertag {
    --duration: 333ms;
    --separation: 66ms;
    --delay: var(--promoted-index);
    opacity: 0;
    transform: translateY(3em);
    animation-name: enter-filtertag;
    animation-duration: var(--duration);
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.53, -0.46, 0.71, 1.63);
    animation-delay: calc(var(--delay) * var(--separation));
  }
  body[data-filter-overlay-stagger=false] .ResponsiveOverlay__Overlay .Filtertag {
    animation: none;
    opacity: 1;
    transform: none;
  }
}

@media (max-width: 46.24em) {
  body[data-responsive-overlay-state=open] .FilterPage__FilterTags.show-all-filters .Filtertag {
    --delay: var(--index);
  }
}
a:focus-visible,
input:focus-visible,
button:focus-visible,
#ToggleSearch:focus-visible,
.Card:has(.Card__Link:focus-visible),
.SearchModal__InputWrapper:has(input:focus-visible),
.DashboardPage .Upcoming .splide__track:has(.Card__Link:focus-visible),
[tabindex]:focus-visible {
  --focus-offset: 2px;
  --focus-width: 3px;
  --focus-color: var(--c-color-red);
  --focus-color: Highlight;
  --focus-color: white;
  --focus-blur: 0;
  --focus-spread: calc(var(--focus-offset) + var(--focus-width));
  --focus-xoff: 0;
  --focus-yoff: 0;
  outline: none;
  box-shadow: 0 0 0 var(--focus-offset) black, var(--focus-xoff) var(--focus-yoff) var(--focus-blur) var(--focus-spread) var(--focus-color);
}

.ToggleSwitch__Options button:focus-visible {
  border-radius: var(--toggle-border-radius);
}

.Banner__Link.back:focus-visible,
a.TicketLink:focus-visible {
  margin-bottom: 0;
  border-radius: var(--border-radius-2);
}
.Banner__Link.back:focus-visible .TicketLink__Icon,
a.TicketLink:focus-visible .TicketLink__Icon {
  opacity: 1;
}
.Banner__Link.back:focus-visible,
.Banner__Link.back:focus-visible svg,
.Banner__Link.back:focus-visible .Icon,
a.TicketLink:focus-visible,
a.TicketLink:focus-visible svg,
a.TicketLink:focus-visible .Icon {
  display: block;
}

.MainMenu__Toggle:focus-visible,
#ToggleSearch:focus-visible {
  --focus-offset: 8px;
  border-radius: 100vw;
}

a.TicketSlider__Link,
a.Banner__Link.home:focus-visible {
  border-radius: 11px;
}

.SearchModal__InputWrapper input:focus-visible {
  --focus-offset: 2px;
  box-shadow: none;
}

.Card__Link:focus-visible {
  box-shadow: none;
}

.DashboardPage .Upcoming:has(.Card__Link:focus-visible) {
  overflow: visible;
}
.DashboardPage .Upcoming .splide__track:has(.Card__Link:focus-visible) {
  border-radius: var(--border-radius-2);
}

.fs-extra-small {
  font-size: clamp(0.63rem, 0.26vi + 0.56rem, 0.88rem);
  line-height: 1.2;
  letter-spacing: 0.05rem;
}

.fs-small {
  font-size: clamp(0.75rem, 0.26vi + 0.69rem, 1rem);
  line-height: 1.36;
  letter-spacing: 0.03rem;
}

.fs-1 {
  font-size: clamp(0.9rem, 0.26vi + 0.85rem, 1.24rem);
  line-height: 1.36;
  letter-spacing: 0.02rem;
}

.fs-2 {
  font-size: clamp(1.13rem, 0.45vi + 1.02rem, 1.56rem);
  line-height: 1.26;
  letter-spacing: 0.01rem;
}

.fs-3 {
  font-size: clamp(1.56rem, 0.78vi + 1.38rem, 2.31rem);
  line-height: 1.1;
  letter-spacing: 0.01rem;
}

.fs-4 {
  font-size: clamp(2.19rem, 4.85vi + 1.05rem, 6.88rem);
  line-height: 1;
  letter-spacing: -0.01em;
}

.fs-5 {
  font-size: clamp(2.5rem, 7.77vi + 0.68rem, 10rem);
  line-height: 0.97;
  letter-spacing: -0.02em;
}

.capsule {
  --Capsule-Color: var(--c-text-inverted);
  --Capsule-Background: var(--c-background-inverted);
  white-space: nowrap;
  font-size: var(--fs-small);
  line-height: var(--flh-small);
  letter-spacing: var(--fls-small);
  font-weight: var(--fw-normal);
  padding: 0.2rem 0.6rem;
  border-radius: 2.2rem;
  display: inline-block;
  color: var(--Capsule-Color);
  background: var(--Capsule-Background);
  text-decoration: none;
  height: max-content;
}
@media (min-width: 61.25em) {
  .capsule {
    padding: 0.4rem 0.8rem;
  }
}
.capsule a {
  color: var(--Capsule-Color);
  text-decoration: none;
}
.capsule-important {
  padding: 0.6rem 1.6rem;
}
@media (min-width: 61.25em) {
  .capsule-important {
    padding: 0.8rem 2rem;
  }
}
.capsule-date {
  --Capsule-Color: var(--c-light-white);
  --Capsule-Background: var(--c-blurry-dark);
  backdrop-filter: blur(8px);
  width: max-content;
}
.capsule-green {
  color: black;
  background: var(--c-color-lime);
}
.capsule-gray {
  --Capsule-Color: var(--c-primary-text-inverted);
  --Capsule-Background: var(--c-light-lightgrey);
  border: none;
}
.capsule-blurry {
  --Capsule-Color: var(--c-light-white);
  --Capsule-Background: var(--c-blurry-dark);
  backdrop-filter: blur(8px);
  border: none;
}
.capsule-white {
  --Capsule-Color: var(--c-dark-black);
  --Capsule-Background: var(--c-light-white);
}
.capsule-outline {
  --Capsule-Color: var(--c-light-white);
  --Capsule-Background: transparent;
  border: 1px solid var(--c-light-60);
}
.capsule-ausverkauft {
  --Capsule-Background: var(--c-state-disabled);
  --Capsule-Color: var(--c-text-inverted);
}
.capsule-abgesagt {
  --Capsule-Background: var(--c-state-error);
  --Capsule-Color: var(--c-text-inverted);
}
.capsule-verschoben {
  --Capsule-Background: var(--c-state-warning);
  --Capsule-Color: var(--c-text-inverted);
}
.capsule-letzte-tickets {
  --Capsule-Background: var(--c-state-info);
  --Capsule-Color: var(--c-text-inverted);
}

.Filtertag .capsule {
  font-size: var(--fs-2);
  line-height: var(--flh-2);
  letter-spacing: var(--fls-2);
  font-weight: var(--fw-normal);
  padding: var(--s-075) var(--s-150);
}

small.capsule {
  font-size: 0.25rem;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.capitalize {
  text-transform: capitalize;
}

.button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  text-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  padding: var(--s-100) var(--s-200);
  background-color: var(--c-blurry-dark);
  backdrop-filter: blur(8px);
}
.button-box svg {
  --icon-size: 1.1rem;
  display: block;
}

body[data-dash-filter-overlay-state=open] .overlay,
body[data-responsive-overlay-state=open] .overlay {
  background-color: var(--main-background-color, rgba(42, 42, 42, 0.6));
  backdrop-filter: var(--effect-event-bg-blur, blur(120px));
  -webkit-backdrop-filter: var(--effect-event-bg-blur, blur(120px));
}

.dropshadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.FadeOverflow {
  /* Hide scrollbar for IE, Edge and Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.FadeOverflow::-webkit-scrollbar {
  display: none;
}
.FadeOverflow {
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  flex: 1;
  cursor: grab;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row.aic {
  align-items: center;
}
.flex-row.jcc {
  justify-content: center;
}