@use "sass:math";
@use "./Mixins.scss" as *;

$transition-duration: 0.75s;
// $transition-duration: 30s;
$transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);

// hides the next aside when the current aside (filtertag) is changing
html.is-rendering aside:has(+ aside) {
  display: none;
}

header.is-previous-container {
  z-index: 0;
}

.transition-slide {
  $duration: 0.666s;
  $opacity-previous: 0.5;

  scale: 1.05;

  html.is-changing & {
    transition: transform $duration $duration * 0.5, scale $duration ease-out,
      opacity $duration ease-out;
    @include mq($until: tablet) {
      transition-duration: $duration * 0.75, $duration * 0.5, $duration;
      transition-delay: $duration * 0.25, 0, 0;
    }
  }
  html.to-next .is-previous-container & {
    scale: 1;
    opacity: $opacity-previous;
  }
  html.to-next .is-next-container & {
    transform: translate3d(100%, 0, 0);
  }
  html.to-prev .is-previous-container & {
    scale: 1;
    opacity: $opacity-previous;
  }
  html.to-prev .is-next-container & {
    transform: translate3d(-100%, 0, 0);
  }
}

.transition-fade {
  html.is-changing & {
    transition: opacity $transition-duration * 0.75 $transition-timing-function;
    transition-delay: $transition-duration * 0.5;

    @include mq($until: tablet) {
      transition-duration: $transition-duration * 0.5;
      transition-delay: $transition-duration * 0.25;
    }
  }
  .is-previous-container & {
    opacity: 0;
  }
  .is-next-container & {
    opacity: 0;
  }
}

.transition-filtertag {
  html.is-changing & {
    transition-duration: var(--filtertag-transition-time);
    transition-duration: $transition-duration;
    transition-timing-function: var(--filtertag-easing);
  }
}

/* * The animation when filtering cards */

#results-list {
  &.is-changing {
    transition: opacity 250ms;
    .Card {
      transition: transform 150ms;
    }
  }
  &.is-animating {
    opacity: 0;
    .Card {
      transform: scale(0.975);
    }
  }
}

// html.is-changing.to-overview,
// html.is-changing.to-overview * {
//   transition-duration: 10ms;
//   transition-property: opacity;
//   opacity: 0;
// }
