@use "../Variables" as *;
@use "../Mixins" as *;

@mixin debugOverflow($color: red) {
  // outline: 3px dashed $color;
  // outline-offset: -3px;
}

@mixin search-icon-transition() {
  // transition: transform, opacity;
  // transition-duration: 333ms;
  // transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  // @starting-style {
  //   opacity: 0;
  //   transform: translateX(80vw);
  // }
}

html:has(.SearchModal.open) {
  overscroll-behavior: none;
}

body {
  @include hide-scrollbars;
}

body:has(.SearchModal.open) {
  overflow: hidden;
}

.SearchModal {
  --grid-row-gap: 1rem;
  --icon-color: #{$col-White};
  --search-result-size: 3rem;

  isolation: isolate;
  display: none;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  overflow-y: auto;
  position: fixed;
  inset: 0 0 auto;
  z-index: 1000;
  margin: 0;
  padding: var(--s-175) 0 0;
  backdrop-filter: var(--effect-event-bg-blur);
  -webkit-backdrop-filter: var(--effect-event-bg-blur);

  @include debugOverflow();

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: 100%;
    height: var(--visual-viewport-height);
  }
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    bottom: 0;
  }

  &.open {
    display: grid;
    overscroll-behavior: contain;
    grid-template-rows: auto 1fr;
    opacity: 1;

    @include mq($until: desktop) {
      display: flex;
      flex-direction: column;
      .SearchModal__InputWrapper {
        width: calc(100% - 2 * var(--s-100));
      }
    }
    @include mq($from: desktop) {
      & {
        grid-template-columns: var(--grid-default-page);
      }
    }
  }

  @include hide-scrollbars();
}

.SearchModal {
  -webkit-overflow-scrolling: touch;
  &__InputWrapper {
    --search-icon-padding: 0.65rem;

    grid-column: content;
    display: flex;
    align-items: center;
    gap: var(--s-050);

    background-color: rgba(255 255 255/ 0.1);
    outline: 1px solid #ffffff4a;
    border-radius: 100vw;

    margin: auto;
    transition-property: width, transform;
    transition-duration: 333ms;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-behavior: allow-discrete;
    width: 100%;
    transform: translateX(0);

    @starting-style {
      width: 10%;
      transform: translateX(30vw);
    }
  }

  &__Icon {
    margin-left: var(--search-icon-padding);
    transform: translateX(0);
    @include search-icon-transition();
    & .Icon-type-control-search {
      transform: translate3d(0, 0.15em, 0);
    }
  }

  &__Input {
    appearance: none;
    font-size: 16px !important;
    outline: none;
    border: none;
    padding: var(--s-100) 0;
    color: white;
    background-color: transparent;
    flex: 1;
    transform: translate3d(0, 0.05em, 0);
    @include font-copy($font-weight: $font-weight-bold);
    @include search-icon-transition();

    &::placeholder {
      color: var(--c-light-50);
    }
  }

  &__CloseButton {
    @include button-reset;
    margin-right: var(--search-icon-padding);
    aspect-ratio: 1/1;
    transition: all 333ms cubic-bezier(0.19, 1, 0.22, 1);

    svg {
      display: block;
    }

    &:focus {
      scale: 1.05;
      // outline:
      outline: 1px solid rgba(255, 255, 255, 0.2901960784);
      background-color: var(--c-light-10);
      backdrop-filter: blur(15px);
      border-radius: 100vw;
    }
  }

  &__Output {
    padding: 0 0 var(--s-200);
    @include debugOverflow(pink);
    @include mq($until: desktop) {
      margin-inline: $spacing-padding-large;
      flex: 1;
    }
    @include mq($from: desktop) {
      grid-column: content;
    }
  }

  &__SectionTitle {
    @include font-small();
    color: #cacaca;
    margin-top: var(--spacing-padding-large);
    margin-bottom: var(--spacing-padding-medium);

    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  &__Result {
    padding-block: 0.8rem;

    @include mq($until: desktop) {
      padding-block: 0.5rem;
    }
  }

  &__ResultContent {
    transition: transform 150ms ease-in-out;
    transform-origin: calc(0.5 * var(--search-result-size)) center;

    &:hover,
    &:focus,
    &:focus-within {
      transform: scale(1.02);
    }
  }

  &__ResultImage {
    border-radius: 50%;
    overflow: hidden;
    width: var(--search-result-size);
    height: var(--search-result-size);
    place-self: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__ResultLink {
    --gap: 0.8rem;
    display: grid;
    grid-template-columns: var(--search-result-size) 1fr;
    gap: var(--gap);
    text-decoration: none;
    transition: all 333ms cubic-bezier(0.19, 1, 0.22, 1);
    outline: 5px solid transparent;

    &:focus {
      box-shadow: none;
      outline: 10px solid var(--c-light-10);
      background-color: var(--c-light-10);
      backdrop-filter: blur(15px);
      border-radius: 100vw;
    }

    @include mq($from: desktop) {
      --gap: 1.3rem;
    }

    &.DefaultResult {
      .SearchModal__ResultContent {
        grid-column: 1/-1;
      }
      .SearchModal__ResultImage {
        display: none;
      }
    }
  }

  &__ResultHeading {
    @include font-copy($font-weight: $font-weight-bold);
    color: var(--c-text);
    margin-block: 0;
    time {
      @include font-small();
      color: var(--c-text-muted);
    }
  }

  &__ResultDescription {
    @include font-small();
    color: var(--c-text-muted);
    margin-block: 0;
  }

  &__ResultContent {
    display: inline-flex;
    align-items: center;

    .DefaultResult &,
    .ArticleResult &,
    .ImpressionResult & {
      align-items: start;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__NoResults {
    @include font-h1();
    color: $col-White;
    margin-top: 3rem;
    text-align: center;
  }
}
