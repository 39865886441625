@use "../Variables" as *;
@use "../Mixins" as *;

.DefaultPage {
  .Banner {
    --Banner-color: #{$col-Black};
  }
}

.content {
  grid-column: content;
}
.wide {
  grid-column: wide;
}
.full {
  grid-column: full;
}
