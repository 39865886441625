@use "../Variables" as *;

.Icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;

  svg {
    &.outline * {
      stroke: var(--icon-color, #{$col-Black});
    }
    .stroke {
      stroke: var(--icon-color, #{$col-Black});
    }
    &.solid * {
      fill: var(--icon-color, #{$col-Black});
    }
    .fill {
      fill: var(--icon-color, #{$col-Black});
    }
    .background {
      fill: var(--icon-background-color, #{$col-White});
    }
  }
}

svg {
  height: var(--icon-size, initial);
  width: var(--icon-size, initial);
}
