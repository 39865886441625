@use "sass:math";
@use "../Variables" as *;
@use "../Mixins" as *;

.ImpressionPage {
  --page-padding: 0;
  --grid-padding-block: 0;
  --page-elements-padding: 2rem;
  --page-controls-stroke-width: 4;
  --thumbnail-height: 50px;
  --thumbnail-width: 30px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100svh;
  margin: 0;

  .Banner {
    --icon-color: #{$col-White};
  }
  .MainMenu {
    --main-menu-color-fg: #{$col-White};
  }

  &__Text {
    transition: opacity 1s ease-in-out;
    opacity: var(--opacity);
    text-align: center;
  }

  & &__Title {
    margin-bottom: 0.7rem;
  }
  & &__Byline {
    margin: 0;
  }

  .DefaultPage__Main {
    grid-column: 1/2;
    grid-row: 1/2;
    z-index: 1;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid: initial;
    margin: 0;
    padding: 0;
    .Gallery {
      grid-column: 1/2;
      grid-row: 1/3;
    }
    .Controls {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }

  .Header {
    z-index: 2;
    color: $col-White;
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  .Gallery {
    z-index: 1;
  }
  .Controls {
    z-index: 3;
    place-self: end center;
  }

  .Gallery {
    &__Slide {
      width: 100vw;
      height: 100svh;
    }

    &__Image {
      height: 100%;
      width: 100%;

      img,
      figure {
        height: 100%;
        width: 100%;
        position: relative;
      }

      figcaption {
        z-index: 1000;
        position: absolute;
        left: var(--page-elements-padding);
        bottom: var(--page-elements-padding);
        color: $col-White;
        font-size: var(--fs-small);
        line-height: var(--flh-small);

        @include mq($until: desktop) {
          left: 50%;
          transform: translateX(-50%);
          bottom: calc(
            1.2 * var(--thumbnail-height) + var(--page-elements-padding)
          );
          text-align: center;
        }
      }

      img {
        object-fit: contain;
      }
    }
  }

  & .splide.Controls {
    width: 100%;
    .splide__track {
      overflow: visible;
    }
  }

  & .splide__arrow {
    height: calc(100% - 2 * var(--s-100));
    width: calc((50% - var(--s-100)) - var(--s-050));
    svg {
      opacity: 0;
    }
  }

  .Thumbnail {
    overflow: hidden;
    list-style: none;
    cursor: pointer;
    border-radius: 0.5rem;
    transform: scale(0.8);

    transition: transform 150ms ease-in-out;

    &.is-active {
      transform: scale(1.05);
      transition: transform 150ms ease-in-out;
      outline: none !important;
      border: none !important;
      @include mq($from: desktop) {
      }
    }
  }

  .MainGallery__Controls {
    position: fixed;
    bottom: var(--s-200);
    right: 8rem;
    left: 8rem;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--spacing-padding-small);
    overflow: hidden;
    z-index: 1;

    @include mq($from: desktop) {
      flex-direction: row;
    }

    @include mq($until: tablet) {
      right: 0;
      left: 0;
      bottom: 6rem;
    }
  }

  .Thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: var(--focus, center);
  }

  .splide__toggle {
    background-color: transparent;
  }
  .splide__controls {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    position: fixed;
    padding-inline: var(--s-300);
    right: 0;
    bottom: var(--s-200);
    z-index: 1;

    @include mq($until: tablet) {
      justify-content: center;
      bottom: var(--s-100);
    }

    svg {
      circle#progress {
        stroke-dashoffset: var(--stroke-dashoffset);
        stroke-dasharray: 1000 1000;
        stroke-width: var(--page-controls-stroke-width);
      }
    }

    .splide__toggle {
      --icon-size: 4rem;
      appearance: none;
      border: none;
      display: grid;
      width: var(--icon-size);
      height: var(--icon-size);
      svg {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }
  }
}
