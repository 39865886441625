@use "../Variables" as *;
@use "../Mixins" as *;

// TODO: Add a mixin that creates a click feedback effect WIP
@mixin click-feedback {
  position: relative;
  transition: all 0.3s ease-out;
  &::after {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 100vh;
    clip-path: circle(0% at 50% 50%);
    // background-color: $col-GreyBlurry;
    background-color: $col-Event-BG-Blurry;
    backdrop-filter: blur(150px);
    transition: all 0.3s ease-out;
    z-index: -1;
  }

  &:hover,
  &:active,
  &:focus {
    transform: scale(0.9);
    transition: all 0.3s ease-out;
    &::after {
      content: "";
      clip-path: circle(100% at 50% 50%);
    }
  }
}

.Banner {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;

  gap: 1rem;
  padding: 0 var(--grid-padding-inline);
  width: 100%;
  height: var(--header-height, auto);

  &.visible-banner {
    transform: translateY(0);
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &.hidden-banner {
    transform: translateY(-100%);
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  .HeroPage & {
    // position: absolute;
    z-index: 100;
  }

  &__left {
    place-self: center self-start;
    display: flex;
    pointer-events: all;
  }
  &__center {
    place-self: center center;
    display: flex;
    flex-direction: row;
    pointer-events: all;
  }
  &__right {
    place-self: center self-end;
    display: flex;
    gap: 0.5rem;
    pointer-events: all;

    .MainMenu__Toggle,
    #ToggleSearch,
    .Banner__Link {
      z-index: 100000;
      width: 2rem;
      height: 2rem;
      @include button-reset;
      @include click-feedback;
    }
  }

  .Banner__Item,
  .Banner__Link {
    text-decoration: none;
    margin-bottom: 0;
  }
  .Banner__Link {
    &.back {
      .Icon,
      svg {
        display: block;
        margin-bottom: 0;
      }
    }
    &:has(.KTV) {
      border-radius: 100vw;
    }
  }
}

.KTV {
  --ktv-visual-size: 1.9rem;
  --ktv-visual-padding: 0.125rem;
  --ktv-gap: 0.8rem;
  display: flex;
  align-items: center;
  background-color: var(--c-light-white);
  padding: var(--ktv-visual-padding);
  border-radius: 100vw;
  box-shadow: 0 1px 20px rgba(0 0 0 / 0.1);

  &__Visual {
    --icon-color: var(--c-color-lime);
    --icon-size: 1.5rem;
    display: grid;
    width: var(--ktv-visual-size);
    height: var(--ktv-visual-size);
    border-radius: 50%;
    overflow: hidden;

    @include mq($until: tablet) {
      --ktv-visual-size: 1.5rem;
      --icon-size: 1rem;
    }

    img,
    video,
    .Icon {
      display: grid;
      grid-area: 1 / -1;
      height: 100%;
      width: 100%;
      object-fit: cover;
      place-content: center center;
    }

    svg {
      display: block;
    }
  }

  &__Text {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-feature-settings: "ss02";
    color: var(--c-text-inverted);
    margin-inline: var(--ktv-gap);
    @include mq($until: tablet) {
      --ktv-gap: 0.5rem;
    }
  }
}
