@use "Mixins" as *;

html {
  background: var(--c-background);
  color: var(--c-text);
  @include hide-scrollbars;
}

body {
  display: grid;
  grid-template-areas: "header" "aside" "main" "footer";
  grid-template-rows: auto auto 1fr auto;
  min-height: 100svh;

  header {
    grid-area: header;
  }
  aside {
    grid-area: aside;
  }
  main {
    grid-area: main;
  }
  footer {
    grid-area: footer;
  }
}

main.DefaultPage__Main {
  @include grid;
}

a:not([class]) {
  color: var(--c-text);
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.DefaultPage__Header,
.FilterPage__Header {
  margin-top: var(--header-height);
  @include mq(desktop) {
    margin-top: calc(var(--header-height) * 1.5);
  }
}
.DashboardPage__Header {
  margin-top: var(--header-height);
}

button[type="button"] {
  @include button-reset;
}

.splide__pagination {
  gap: 0.23rem;
}
button.splide__pagination__page {
  $transition-time: 250ms;
  background: var(--c-light-darkgrey);
  opacity: 1;
  height: 6px;
  width: 6px;
  transition: width $transition-time ease-in-out,
    height $transition-time ease-in-out;
  border-radius: 50%;

  &.is-active {
    transform: scale(1);
    background: var(--c-light-white);
    height: 8px;
    width: 8px;
  }
}

dl {
  display: inline; /* Make the list inline */
}

dt {
  margin-right: 5px; /* Add some space after the term */
}

dd {
  margin-right: 10px; /* Add space between the definition and next term */
}

u {
  text-decoration: none;
}
