@use "../Variables" as *;
@use "../Mixins" as *;

.ReelsPage {
  position: relative;
  display: grid;
  &__Header {
    --icon-color: white;
    z-index: 1;
  }
  &__Main {
    position: fixed;
    inset: 0;
    display: grid;
  }
}
.Reels {
  grid-area: 1 / -1;
  .splide__pagination {
    z-index: 40000;
  }
  & .splide__video {
    transform: scale(2.4);
    transform-origin: center center;
    @media (orientation: landscape) {
      transform: scale(2.4);
    }
    @media (orientation: portrait) {
      transform: scale(3.4);
    }
  }

  &__Controls {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: end;
    gap: 12px;
    margin-inline: var(--spacing-padding-large);
    @include mq($until: tablet) {
      margin-inline: var(--spacing-padding-small);
    }
  }
  &__Mute-Button {
    @include button-reset;
    background: var(--c-blurry-dark);
    border-radius: 50%;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background-image: url("/assets/icons/control-audio-mute.svg");
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    &:checked {
      background-image: url("/assets/icons/control-audio-unmute.svg");
    }
  }

  &__All-Events {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $col-White;
    text-decoration: none;
    text-shadow: $effect-drop-shadow;
    border-radius: var(--border-radius-1);
    padding: 8px 24px;
    background-color: $col-DarkBlurry;
    backdrop-filter: $effect-button-bg-blur;
  }

  .splide__video__play:after {
    display: none !important;
  }
  .splide__slide iframe {
    mix-blend-mode: screen;
  }
  .splide__video__wrapper {
    background: transparent !important;
  }

  // li.splide__slide.splide__slide--has-video.is-active.is-visible iframe {
  //   animation-name: grainEffectFadeIn;
  //   animation-duration: 2s;
  //   animation-fill-mode: forwards;
  //   opacity: 0.1;
  //   mix-blend-mode: screen;
  // }

  // @keyframes grainEffectFadeIn {
  //   0% {
  //     opacity: 0.1;
  //   }
  //   100% {
  //     opacity: 1;
  //   }
  // }
}

.Reel {
  display: grid;
  height: 100%;
  cursor: grab;

  &__Item {
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    overflow: hidden;
  }

  &__Video {
    grid-area: 1 / -1;
    z-index: -1;
    overflow: hidden;
  }

  &__Iframe {
    object-fit: cover;
    transform: scale(2.3);

    @include mq($until: tablet) {
      transform: scale(4.2);
    }
  }

  &__Content {
    padding: $spacing-padding-large;
    padding-bottom: 0;
    grid-area: 1 / -1;
    display: grid;
    color: white;
    position: absolute;
    inset: 0;
    opacity: 1;
    z-index: 100000;

    @include mq($until: tablet) {
      padding: $spacing-padding-small;
    }
  }

  &__Text {
    grid-area: 1 / -1;
    place-self: center;
    text-align: center;
  }

  &__Title {
    a {
      text-decoration: none;
      color: inherit;
    }
    h2 {
      @include font-event-title($font-weight-bold);
      margin-top: var(--spacing-padding-small);
      margin-bottom: var(--spacing-padding-medium);
      font-size: calc(((20rem + 66vw) * min(0.125, var(--fs))));
    }
  }

  &__Link.capsule {
    z-index: 10000;
    pointer-events: all;
    cursor: pointer;
    @include font-copy;
    @include call-to-action;
  }
  &__VideoTitle {
    @include font-extra-small();
    grid-area: 1 / -1;
    place-self: end start;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 4px;
    width: 100%;
    --icon-size: 20px;

    @include mq($until: tablet) {
      margin-bottom: 5px;
      --icon-size: 18px;
    }

    &Text {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &Marker {
      margin-top: 2px;
    }
  }

  &__Progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      height: 100%;
      // width: calc(1% * var(--progress));
      background-color: #ff0000;
      transition: width 0.5s linear; /* Adjust duration for smoother updates */
    }

    .splide__slide.is-active &::after {
      // width: var(--progress, 0);
      width: calc(1% * var(--progress));
    }

    // body.Juni & {
    //   background: linear-gradient(
    //     to right,
    //     rgb(237, 34, 36),
    //     rgb(243, 91, 34),
    //     rgb(249, 150, 33),
    //     rgb(245, 193, 30),
    //     rgb(241, 235, 27) 27%,
    //     rgb(241, 235, 27),
    //     rgb(241, 235, 27) 33%,
    //     rgb(99, 199, 32),
    //     rgb(12, 155, 73),
    //     rgb(33, 135, 141),
    //     rgb(57, 84, 165),
    //     rgb(97, 55, 155),
    //     rgb(147, 40, 142)
    //   );
    // }
  }
}

.Reels .splide__slide {
  &::after {
    content: "Loading...";
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.is-visible::after {
    content: none;
  }
  iframe,
  .Reel__Text {
    opacity: 0;
  }
  &.is-visible iframe,
  &.is-visible .Reel__Text {
    opacity: 1;
  }
}

.Reels .splide__slide.is-active {
  background-image: none;
}

.Reels .splide__slide,
.Reels__LoadingOverlay {
  background-image: url("/assets/icons/grain.gif");
  // transition: background-image 0s 2s;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: relative;
}

.Reels__LoadingOverlay {
  background-image: url("/assets/icons/grain.gif");
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 9999;
}
