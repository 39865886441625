$settings-overlay-breakpoint: tablet;

// $col-BG: #f1f1f1;
$col-BG: #0d0d0d;
$col-BGOff-Grey: #e8e8e8;
// $col-Black: #262626;

$col-Black: #ffffff;

$col-DarkBlurry: rgba(42, 42, 42, 0.6);
$col-Darkgrey: #888888;
$col-Event-BG-Blurry: rgba(118, 118, 118, 0.3);
$col-Full-Black: #000000;
$col-Off-Black: #1f1e1e;
$col-GreyBlurry: rgba(38, 38, 38, 0.25);
$col-Lightgrey: #e7e7e7;
$col-Lime: #bdff00;
$col-OffWhite: #f8f3e9;
$col-Red: #ff1c1c;
$col-RedAusverkauft: #ff5a59;
$col-White: #ffffff;

$col-primary-text: #ffffff;
$col-secondary-text: $col-Lightgrey;
$col-tetriary-text: $col-Darkgrey;

$col-WhiteBlury: rgba(255, 255, 255, 0.1);

$spacing-padding-small: 0.5rem;
$spacing-padding-medium: 1.5rem;
$spacing-padding-large: 2.5rem;
$spacing-padding-extra-large: 6rem;

$col-background: $col-BG;
$col-foreground: $col-Black;
$col-brand-primary: $col-Red;
$col-brand-secondary: $col-Lime;

$spacing: (
  "033": 0.333rem,
  "050": 0.5rem,
  "065": 0.65rem,
  "075": 0.75rem,
  "100": 1rem,
  "150": 1.5rem,
  "175": 1.75rem,
  "200": 2rem,
  "250": 2.5rem,
  "300": 3rem,
  "400": 4rem,
  "500": 5rem,
  "600": 6rem,
  "650": 6.5rem,
  "700": 7rem,
  "800": 8rem,
);

$effect-drop-shadow-capsule: 0px 1px 20px rgba(0, 0, 0, 0.14);
$effect-drop-shadow: 1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.25);
$effect-button-bg-blur: blur(8px);
$effect-bg-blur-2: blur(50px);
$effect-event-bg-blur: blur(120px);

$page-padding-bottom: 10rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$font-weights: (
  "normal": 400,
  "medium": 500,
  "bold": 600,
);

$font-sizes: (
  "extra-small": clamp(0.63rem, 0.26vi + 0.56rem, 0.88rem),
  "small": clamp(0.75rem, 0.26vi + 0.69rem, 1rem),
  "1": clamp(0.9rem, 0.26vi + 0.85rem, 1.24rem),
  "2": clamp(1.13rem, 0.45vi + 1.02rem, 1.56rem),
  "3": clamp(1.56rem, 0.78vi + 1.38rem, 2.31rem),
  "4": clamp(2.19rem, 4.85vi + 1.05rem, 6.88rem),
  "5": clamp(2.5rem, 7.77vi + 0.68rem, 10rem),
);

$font-line-heights: (
  "extra-small": 1.2,
  "small": 1.36,
  "1": 1.36,
  "2": 1.26,
  "3": 1.1,
  "4": 1,
  "5": 0.97,
);

$font-letter-spacing: (
  "extra-small": 0.05rem,
  "small": 0.03rem,
  "1": 0.02rem,
  "2": 0.01rem,
  "3": 0.01rem,
  "4": -0.01em,
  "5": -0.02em,
);

// :root {
// //   https://www.fluid-type-scale.com/calculate?minFontSize=40&minWidth=375&minRatio=1.1&maxFontSize=160&maxWidth=1920&maxRatio=2&steps=1&baseStep=1&prefix=fs&useContainerWidth=false&includeFallbacks=false&useRems=true&remValue=16&decimals=2&previewFont=Inter&previewText=Almost+before+we+knew+it%2C+we+had+left+the+ground&previewWidth=1280
//   --fs-copy-xs: clamp(0.63rem, 0.26vi + 0.56rem, 0.88rem);
//   --fs-copy-s: clamp(0.75rem, 0.26vi + 0.69rem, 1rem);
//   --fs-copy: clamp(0.88rem, 0.26vi + 0.81rem, 1.13rem);
//   --fs-lead: clamp(1.13rem, 0.45vi + 1.02rem, 1.56rem);
//   --fs-h3: clamp(1rem, 0.58vi + 0.86rem, 1.56rem);
//   --fs-h2: clamp(1.56rem, 0.78vi + 1.38rem, 2.31rem);
//   --fs-h1: clamp(2.19rem, 4.85vi + 1.05rem, 6.88rem);
//   --fs-event-title: clamp(2.5rem, 7.77vi + 0.68rem, 10rem);
// }
