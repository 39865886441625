*,
*::before,
*::after {
  font: inherit;
  box-sizing: border-box;
} /* Switch to border-box for box-sizing. */

* {
  margin: 0;
  padding: 0;
  font: inherit; /* Get rid of all font sizes and heights */
  hanging-punctuation: none;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  margin: 0; /* Remove the tiny space around the edge of the page */
}

img,
video,
canvas,
audio,
iframe,
embed,
object {
  max-width: 100%;
  height: auto;
  display: block; /* Switch display mode to block, since that's what we usually want for images. */
  vertical-align: middle; /* If you override, and make an image inline, it's likely you'll want middle vertical alignment. */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // text-wrap: balance;
}

p {
  // text-wrap: pretty;
}

ul[class],
ol[class] {
  list-style: none;
}
