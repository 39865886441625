@use "../Variables" as *;
@use "../Mixins" as *;

.Grid {
  &.GridAutoFill {
    grid-column: full;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: calc(var(--gap) * 3);
    column-gap: var(--gap);
    column-gap: 1.5rem;
    @include mq($from: tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include mq($from: wide) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include mq($from: full) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}

.GridItem {
  width: 100%;
  @include font-copy();

  &__Image {
    width: 100%;
    aspect-ratio: 3/4;
    margin-bottom: 1rem;
    background-color: var(--c-blurry-dark);
  }

  &__Text {
    display: grid;
    grid-template-areas:
      "A C"
      "B C";
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
  }

  &__Title {
    grid-area: A;
  }

  &__Byline {
    grid-area: B;
    color: var(--c-text-muted);
    p {
      margin: 0 !important;
    }
  }

  &__Image img {
    width: 100%;
    object-fit: cover;
  }
}
