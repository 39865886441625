@use "../Variables" as *;
@use "../Mixins" as *;

.EventsPage {
  .FilterPage__FilterResults {
    grid-auto-flow: dense;
  }
  .EventsSlider {
    --slider-padding-inline: var(--grid-padding-inline);
    grid-column: 1/-1;
    padding-block: 5rem 8rem;
    margin-inline: calc(var(--slider-padding-inline) * -1);

    &__Title {
      margin: 0 var(--slider-padding-inline);
      margin-bottom: var(--s-200);
      // color: var(--c-light-30);
      @include font-h3;
    }

    &__PagesWrapper {
      overflow-x: auto;
      @include hide-scrollbars;
    }

    &__Pages {
      display: grid;
      gap: var(--gap);
      width: fit-content;
      padding-inline: var(--slider-padding-inline);

      grid-auto-columns: 11rem;

      @include mq($from: tablet) {
        grid-auto-columns: 21rem;
      }

      @include mq($from: desktop) {
        grid-auto-columns: 21rem;
      }
    }
    .PageSlide {
      grid-row: 1/-1;
    }

    .Teaser__Main-Acts {
      @include mq($until: tablet) {
        --fs-3: 1.3rem;
      }
    }
    .Teaser__Support-Acts {
      font-size: var(--fs-extra-small);
    }
  }
}
