@use "../Variables" as *;
@use "../Mixins" as *;

.ShopPage {
  .GridItem__Image {
    aspect-ratio: 3 / 4;
    background-color: lightgrey;
    background: var(--c-blurry-dark);
    width: 100%;
    overflow: hidden;

    &.Product_contain {
      position: relative;
    }
    &.Product_contain img {
      width: 80%;
      max-height: 80%;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 1));
    }
    &.Product_cover img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .Product__BuyButton {
    grid-area: C;
    max-width: fit-content;
    justify-self: end;
    transition: all 0.3s ease;

    &:hover {
      background: var(--c-color-lime);
      color: var(--c-light-white);
      border-color: var(--c-color-lime);
      transition: all 0.3s ease;
    }
  }
}
