@use "../Variables" as *;
@use "../Mixins" as *;

.ClaimWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  contain: paint;
  height: 80svh;
}

.Claim {
  @include font-event-title($font-weight-bold);
  perspective: 1200px;
  line-height: 1;
  color: var(--c-primary-text);
  margin-top: 1em;
  position: relative;
  text-align: center;
  // filter: blur(100px);
  transition: filter 1s;

  // .ClaimWrapper.in-view & {
  //   filter: blur(0);
  // }
}

.ClaimPart {
  &:not(.static) {
    position: absolute;
    left: 0;
    bottom: 100%;
    right: 0;
    margin-bottom: 0;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
  }
  &:first-of-type {
    opacity: 1;
  }
}
