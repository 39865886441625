@use "../Variables" as *;
@use "../Mixins" as *;

body[data-responsive-overlay-state="open"] {
  overflow: hidden;
}

.ResponsiveOverlay {
  width: 100%;
  margin: 0 var(--spacing-padding-medium);
  &__Toggle {
    display: none;
    width: 100%;
    @include button-reset;
    @include mq($until: $settings-overlay-breakpoint) {
      display: block;
    }
  }
  &__Overlay {
    @include mq($until: $settings-overlay-breakpoint) {
      display: none;
      position: fixed;
      z-index: 1000;
      inset: 0;
      color: white;
      align-items: center;
      justify-content: center;
      background-color: var(--c-blurry-background);

      body[data-responsive-overlay-state="open"] & {
        display: grid;
        // padding: var(--spacing-padding-extra-large) 0;
        // overflow: auto;
        .FilterOverlay__Tags {
          height: 100%;
          overflow: auto;
          padding: var(--spacing-padding-extra-large) 0;
          // TODO: this breaks scrollability of content, top is never visible
          // display: grid;
          // display: flex;
          // align-items: center;
          @include hide-scrollbars;
        }
        // @include hide-scrollbars;
        & .Filtertag {
          --duration: 333ms;
          --separation: 66ms;
          --delay: var(--promoted-index);
          opacity: 0;
          transform: translateY(3em);
          animation-name: enter-filtertag;
          animation-duration: var(--duration);
          animation-fill-mode: forwards;
          animation-timing-function: cubic-bezier(0.53, -0.46, 0.71, 1.63);
          animation-delay: calc(var(--delay) * var(--separation));
        }
      }
      body[data-filter-overlay-stagger="false"] & {
        & .Filtertag {
          animation: none;
          opacity: 1;
          transform: none;
        }
      }
    }
  }
}

@include mq($until: $settings-overlay-breakpoint) {
  body[data-responsive-overlay-state="open"]
    .FilterPage__FilterTags.show-all-filters
    .Filtertag {
    --delay: var(--index);
  }
}
