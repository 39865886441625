a:focus-visible,
input:focus-visible,
button:focus-visible,
#ToggleSearch:focus-visible,
.Card:has(.Card__Link:focus-visible),
.SearchModal__InputWrapper:has(input:focus-visible),
.DashboardPage .Upcoming .splide__track:has(.Card__Link:focus-visible),
[tabindex]:focus-visible {
  --focus-offset: 2px;
  --focus-width: 3px;
  --focus-color: var(--c-color-red);
  --focus-color: Highlight;
  --focus-color: white;
  --focus-blur: 0;
  --focus-spread: calc(var(--focus-offset) + var(--focus-width));
  --focus-xoff: 0;
  --focus-yoff: 0;
  //   mix-blend-mode: lighten;

  outline: none;
  box-shadow: 0 0 0 var(--focus-offset) black,
    var(--focus-xoff) var(--focus-yoff) var(--focus-blur) var(--focus-spread)
      var(--focus-color);
}

.ToggleSwitch__Options button:focus-visible {
  border-radius: var(--toggle-border-radius);
}

.Banner__Link.back:focus-visible,
a.TicketLink:focus-visible {
  margin-bottom: 0;
  border-radius: var(--border-radius-2);

  .TicketLink__Icon {
    opacity: 1;
  }

  &,
  svg,
  .Icon {
    display: block;
  }
}

.MainMenu__Toggle:focus-visible,
#ToggleSearch:focus-visible {
  --focus-offset: 8px;
  border-radius: 100vw;
}

a.TicketSlider__Link,
a.Banner__Link.home:focus-visible {
  border-radius: 11px;
}

.SearchModal__InputWrapper input:focus-visible {
  --focus-offset: 2px;
  box-shadow: none;
}

.Card__Link:focus-visible {
  box-shadow: none;
}

.DashboardPage .Upcoming {
  &:has(.Card__Link:focus-visible) {
    overflow: visible;
  }

  & .splide__track:has(.Card__Link:focus-visible) {
    border-radius: var(--border-radius-2);
  }
}
