@use "../Mixins.scss" as *;
// https://nemzes.net/posts/animating-height-auto/
// https://css-tricks.com/animating-height-the-right-way/

.Alert {
  --alert-transition-duration: 0.3s;
  --alert-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  --alert-side-size: var(--s-300);
  --alert-gap: var(--s-050);

  color: var(--c-dark-background);
  // padding: var(--s-050) var(--s-200);
  padding: 0 var(--s-100);
  border-radius: var(--s-050);
  background: var(--c-state-disabled);

  display: grid;
  column-gap: var(--alert-gap);
  grid-template-columns: var(--alert-side-size) 1fr var(--alert-side-size);
  grid-template-rows: auto 0fr;
  grid-template-areas:
    "Icon Info Toggle"
    ". Disclosable .";
  transition: grid-template-rows 0.3s ease;
  transition-delay: 0.3s;
  @include info-box-paddings;
  @include mq($until: tablet) {
    --alert-side-size: var(--s-200);
    .Alert__Title {
      margin: 0;
    }
    svg {
      display: block;
    }
    .Icon {
      padding: 0.1rem 0;
      transform: scale(0.75);
    }
    // .Icon-type-accordion-open circle {
    //   opacity: 0;
    // }
  }

  @include mq($from: tablet) {
    align-items: center;
  }

  &:has(.Alert__Toggle > input:checked) {
    grid-template-rows: auto 1fr;
    transition-delay: 0s;

    & .Alert__Byline {
      @include line-clamp($revert: true);
    }
    & .Alert__Disclosable {
      transition-delay: 0.3s;
      opacity: 1;
    }
  }

  &__Icon {
    grid-area: Icon;
  }
  &__Title {
    margin-bottom: var(--s-033);
    @include font-copy($font-weight: bold);
  }
  &__Text {
    grid-area: Info;
    color: var(--c-dark-background);
    @include font-copy;

    a {
      color: var(--c-dark-text);
      text-decoration: underline;
    }
  }
  &__Byline {
    overflow: hidden;
    @include line-clamp(1);
  }
  &__Toggle {
    grid-area: Toggle;
    justify-self: end;

    input {
      display: none;
    }
    .Icon-type-accordion-open svg {
      transition: transform var(--alert-transition-duration)
        var(--alert-transition-timing-function);
    }
    &:has(> input:checked) {
      .Icon-type-accordion-open svg {
        transform: rotate(180deg);
      }
    }
  }
  &__Disclosable {
    transition: opacity 300ms;
    transition-delay: 0ms;
    grid-area: Disclosable;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    @include font-copy;
    @include mq($until: tablet) {
      grid-column: 1 / -1;
    }
    & > *:first-child::before {
      content: "";
      display: block;
      height: var(--s-100);
    }
  }

  &[data-alert-type="soldout"] {
    background: var(--c-state-disabled);
  }
  &[data-alert-type="canceled"] {
    background: var(--c-state-error);
  }
  &[data-alert-type="postponed"] {
    background: var(--c-state-warning);
  }
  &[data-alert-type="info"] {
    background: var(--c-state-info);
  }
}
