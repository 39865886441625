@use "../Variables" as *;
@use "../Mixins" as *;

:root {
  --splash-animation-duration: 600ms;
}
.ReelsPage__Header {
  animation-name: headerOpacity;
  animation-duration: var(--splash-animation-duration);
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-delay: 2.8s;
}

.SplashPage {
  background: rgb(41, 41, 41);
  height: 100svh;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200000;
  animation-duration: var(--splash-animation-duration);
  animation-delay: 2.8s;
  animation-name: splashAnimationFadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 1;
  pointer-events: none;

  &__Title {
    background: var(--c-brand-primary);
    margin: 1rem;
    padding: 0.6rem 1.2rem;
    border-radius: var(--border-radius-1);
    font-feature-settings: "ss02";
    overflow: hidden;
    color: var(--c-text-inverted);
    @include font-h2($font-weight-bold);
    animation-name: splashTitleBoxReveal;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    animation-duration: var(--splash-animation-duration);
    animation-delay: 0;

    div {
      white-space: nowrap;
      text-align: center;
      animation-name: splashTitleReveal;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
      animation-duration: 300ms;
      animation-delay: var(--splash-animation-duration);
      opacity: 0;
    }
  }
  &__Byline {
    overflow: hidden;
    height: 1.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    color: var(--c-brand-primary);
    @include font-small();
    div {
      text-align: center;
      transform: translateY(100px);
      animation-duration: var(--splash-animation-duration);
      animation-delay: var(--splash-animation-duration);
      animation-name: splashBylineSlideIn;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
}

@keyframes splashBylineSlideIn {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes splashTitleBoxReveal {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes splashTitleReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes splashAnimationFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes headerOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
