@use "../Variables" as *;
@use "../Mixins" as *;

.TicketSlider {
  --slider-padding: 0.275rem;
  --slider-thumb-size: 3rem;
  --slider-thumb-color: var(--c-color-red);
  --slider-track-width: 18rem;
  --slider-track-color: var(--c-blurry-light-10);
  --slider-track-outline-width: 1px;
  --slider-track-outline-color: var(--c-light-60);
  --slider-success-bg-color: var(--c-color-lime);

  display: grid;
  position: relative;

  @include mq($from: desktop) {
    --slider-thumb-size: 3rem;
  }

  @include mq($from: wide) {
    --slider-thumb-size: 4rem;
    --slider-track-width: 27rem;
  }
  @include mq($from: full) {
    --slider-thumb-size: 5rem;
    --slider-track-width: 35rem;
  }

  @mixin thumb-styles {
    background-color: transparent;
    border-radius: 100vw;
    box-shadow: none;
    height: var(--slider-thumb-size);
    width: var(--slider-thumb-size);
  }

  &__Link {
    grid-column: 1/-1;
    grid-row: 1/2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--c-light-60);
    text-decoration: none;
    @include font-small();
  }

  &__Disclaimer {
    @include font-extra-small();
    grid-column: 1/-1;
    grid-row: 2/3;
    margin-top: var(--s-100);
    color: $col-White;
    opacity: 0.5;
  }

  /*********** Baseline, reset styles ***********/
  &__Input {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: var(--slider-thumb-size);
    display: block;
    padding: 4px;

    /* Removes default focus */
    &:focus {
      outline: none;
    }

    /******** Chrome, Safari, Opera and Edge Chromium styles ********/
    /* slider track */
    &::-webkit-slider-runnable-track {
      border-radius: 100vw;
      height: 100%;
    }
    /* slider thumb */
    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      margin-top: 0px; /* Centers thumb on the track */
      @include thumb-styles();
    }
    &:focus::-webkit-slider-thumb {
      // outline: 3px solid var(--slider-thumb-color);
      // outline-offset: 0.125rem;
    }
    /*********** Firefox styles ***********/
    /* slider track */
    &::-moz-range-track {
      border-radius: 100vw;
      height: 100%;
    }

    /* slider thumb */
    &::-moz-range-thumb {
      border: none; /*Removes extra border that FF applies*/
      @include thumb-styles();
    }
    &::-moz-range-progress {
      display: none;
    }
  }

  &__InputWrapper {
    grid-column: 1/-1;
    grid-row: 1/2;
    background-color: var(--c-blurry-light-10);
    display: block;
    border-radius: 100vw;
    padding: 0;
    height: var(--slider-thumb-size);
    width: var(--slider-track-width);
    justify-self: center;
    position: relative;
    border: var(--slider-padding) solid transparent;
    outline: var(--slider-track-outline-width) solid
      var(--slider-track-outline-color);
    outline-offset: calc(-1 * var(--slider-track-outline-width));
    box-sizing: content-box;

    &.done {
      position: relative;
      overflow: hidden;

      &::after {
        content: "🤖";
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        inset: 0;
        left: auto;
        width: var(--slider-thumb-size);
        color: var(--c-text-inverted);
        background-color: var(--slider-success-bg-color);
        border-radius: 100vw;
        animation: input-thumb-success-grow 0.5s forwards;
      }
    }
  }

  &__VisualThumb {
    background-image: url(/assets/icons/control-arrow-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--slider-thumb-color);
    background-size: 40%;
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100vw;
    pointer-events: none;

    transform: translateX(
      calc(var(--v, 0) * var(--x) * 1px - var(--v, 0) * 100%)
    );
  }
}

@keyframes input-thumb-success-grow {
  to {
    width: 100%;
  }
}
