@use "../Variables" as *;
@use "../Mixins" as *;

.FilterPage {
  --teaser-content-gap: var(--spacing-padding-small);
  background-color: var(--c-background);

  &__Header a {
    color: var(--c-primary-text);
    text-decoration: none;
  }
  .Banner,
  &__Main {
    max-width: 100vw;
  }

  .FilterReset,
  .FilterConfirm {
    position: fixed;
    top: 50%;
    width: min-content;
    transform: translateY(-50%);
    display: none;
  }
  .FilterReset {
    left: var(--s-100);
  }
  .FilterConfirm {
    right: var(--s-100);
  }
  &[data-responsive-overlay-state="open"] .FilterReset,
  &[data-responsive-overlay-state="open"] .FilterConfirm {
    display: block;
  }

  &__FilterControls {
    margin: 0 var(--grid-padding-inline);
    display: flex;
    justify-content: end;
  }

  &__FilterResults {
    @include grid(filterPage);
    @include mq($until: tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  body.EventsPage.list-view-is-active &__FilterResults,
  body.ArchivePage.list-view-is-active &__FilterResults,
  .dashboard-test-list {
    grid-template-columns: min-content repeat(4, auto);

    .Teaser__Main-Acts .Act {
      @include font-h1();
    }
  }
}

.dashboard-test-list,
.EventsPage.list-view-is-active .FilterPage__FilterResults,
.ArchivePage.list-view-is-active .FilterPage__FilterResults {
  display: flex;
  flex-direction: column;
  gap: var(--s-075);
  .Teaser__Main-Acts .Act {
    @include font-h1();
  }
  .EventsSlider {
    display: none;
  }
}

.FilterToggle {
  --icon-size: 40px;
  @include font-copy;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  padding: calc(var(--icon-size) / 2);
  line-height: var(--icon-size);

  background: var(--c-event-background);
  color: var(--c-light-60);

  .Icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    right: calc(var(--icon-size) / 2);

    &,
    & svg {
      display: block;
    }
  }
  &__Badge {
    --s: 10px;
    font-size: var(--s);
    line-height: 1.4;
    text-align: center;
    vertical-align: middle;
    width: calc(var(--s) * 1.5);
    height: calc(var(--s) * 1.5);
    border-radius: 100vw;
    background-color: var(--c-color-red);
    color: white;
    position: absolute;
    top: calc(var(--s) * 1.2);
    right: calc(var(--s) * 1.2);
  }
}
