@use "sass:math";
@use "../Variables" as *;
@use "../Mixins" as *;

.ToggleSwitch {
  --toggle-width: 10rem;
  --toggle-height: 2rem;
  --toggle-border-radius: calc(var(--toggle-height) / 2);
  @include font-small();
  width: var(--toggle-width);
  height: var(--toggle-height);
  display: grid;
  grid-template-columns: [left-start] 1fr [left-end right-start] 1fr [right-end];

  &__Track {
    display: grid;
    grid-row: 1;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
    height: var(--toggle-height);
    border-radius: var(--toggle-border-radius);
    background-color: var(--c-blurry-dark-55);
  }

  &__Pill {
    grid-area: left;
    position: relative;
    height: var(--toggle-height);
    transition: transform 0.3s ease;
    background-color: var(--c-background);
    color: var(--c-primary-text);
    height: var(--toggle-height);
    border-radius: var(--toggle-border-radius);
    -webkit-border-radius: var(--toggle-border-radius);
    box-shadow: 1.5px 1.5px 4px rgba(0 0 0 / 0.2);
    outline: 2px solid var(--c-dark-off-black);
  }

  &[data-state="right"] &__Pill {
    transform: translate(100%, 0);
  }

  &__Options {
    grid-column: 1 / -1;
    grid-row: 1;
    display: flex;
    z-index: 1;
  }

  &__OptionA {
    @include button-reset;
    background-color: transparent;
    flex: 1;
    text-align: center;
  }

  &__OptionB {
    @include button-reset;
    color: var(--c-light-60);
    background-color: transparent;
    flex: 1;
    text-align: center;
  }

  &[data-state="right"] &__Pill {
    transform: translate(100%, 0);
  }

  &[data-state="right"] &__OptionA,
  &[data-state="left"] &__OptionB {
    color: var(--c-light-60);
  }
  &[data-state="right"] &__OptionB,
  &[data-state="left"] &__OptionA {
    color: var(--c-text);
  }
}
