@use "../Variables" as *;
@use "../Mixins" as *;

body[data-responsive-overlay-state="open"],
body[data-dash-filter-overlay-state="open"] {
  .Filtertag .capsule {
    font-size: var(--fs-4);
    padding: var(--s-400) var(--s-700);
    border-radius: 100vw;
    @include mq($until: $settings-overlay-breakpoint) {
      padding: var(--s-150) var(--s-300);
    }
  }
}

.FilterPage__FilterTags,
.DashboardPage__FilterTags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--s-100) auto;

  @include mq($from: $settings-overlay-breakpoint) {
    margin: 4rem auto;
  }

  &.show-all-filters .Filtertag-Link {
    display: flex;
  }

  .FilterList {
    grid-column: tags;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-inline: var(--s-100);

    @include mq($until: $settings-overlay-breakpoint) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
    }

    .Filtertag {
      max-width: 100%;
    }

    .Filtertag-Link {
      max-width: 100%;
      flex-direction: row;

      &__Text {
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.Filtertag-Link {
  --filtertag-easing: cubic-bezier(0.53, -0.46, 0.71, 1.63);
  --filtertag-transition-time: 0.2s;
  --filtertag-icon-size: 0.75em;

  margin: 0.2rem;
  white-space: nowrap;
  text-decoration: none;
  transition: background-color 0.2s var(--filtertag-easing);

  &[data-active=""],
  &[data-promoted=""] {
    display: none;
  }

  .more &,
  &[data-active="1"],
  &[data-promoted="1"] {
    display: inline-flex;
  }

  &:hover {
    background-color: var(--c-brand-primary);
  }

  &__Close {
    --icon-size: var(--filtertag-icon-size);
    opacity: 0;
    display: inline-flex;
    align-items: center;
    width: 0;
    cursor: pointer;
    transition: opacity var(--filtertag-transition-time) var(--filtertag-easing),
      width var(--filtertag-transition-time) var(--filtertag-easing),
      margin-left var(--filtertag-transition-time) var(--filtertag-easing);
    .Icon {
      display: inline-flex;
    }
  }

  &[data-active="1"] {
    background-color: $col-Red;
  }
  &.leaving.activating {
    background-color: $col-Red;
  }

  &[data-active="1"] &__Close {
    opacity: 1;
    margin-left: var(--gap);
    width: var(--filtertag-icon-size);
  }
  &.leaving.activating &__Close {
    opacity: 1;
    margin-left: var(--gap);
    width: var(--filtertag-icon-size);
  }
  &.leaving.deactivating &__Close {
    opacity: 0;
    margin-left: 0;
    width: 0;
  }

  /************** VARIANTS **************/
  &.more,
  &.outline {
    color: var(--c-light-white);
    background-color: var(--c-background);
    outline: 2px solid var(--c-light-lightgrey);
  }

  &.projektraum {
    color: var(--c-light-white);
    @include gradient-outline($fgR: rebeccapurple);
  }

  &.archiv {
    color: var(--c-light-white);
    background-color: var(--c-blurry-dark);
  }
}
